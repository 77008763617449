import { useState, useEffect, useCallback } from 'react'
type ReturnedValueType = [string[], (v: string) => void]
/**
 *
 */
export const useValidation = (
    value: any,
    rules: ((v?: any) => string | boolean)[] = [],
    isValid?: (v) => any
): ReturnedValueType => {
    const [errors, setErrors] = useState([])
    const [valueHasChanged, setValueHasChanged] = useState(false)
    const handler = useCallback(() => {
        if (
            !valueHasChanged ||
            !rules ||
            (rules && !rules.length) ||
            !Array.isArray(rules)
        ) {
            setValueHasChanged(true)
            return
        }
        const errs = []
        for (const rule of rules) {
            const res = rule(value)
            if (res !== true) {
                errs.push(res)
            }
        }
        setErrors([...errs])
    }, [value])

    useEffect(() => {
        if (rules && rules.length) {
            const brokenRule = rules.find((rule) => {
                const res = rule(value)
                if (res !== true) return res
                return false
            })

            if (isValid) isValid(!brokenRule)
        }
    }, [])

    useEffect(() => handler(), [value])

    useEffect(() => {
        if (valueHasChanged && isValid) isValid(!errors.length)
    }, [errors])

    return [errors, setErrors]
}

export default useValidation
