import React, { useState, useContext } from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Checkbox, Alert } from 'foundations-library/components'
import { getVerbalColors } from 'helpers'
import { EsimContext } from 'providers'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import Button from './../../../components/Button'
import { details } from '../../../utils/objects'
import Device from '../../components/Device'
import User from '../../components/User'

const line = (
    <svg className='line' xmlns="http://www.w3.org/2000/svg" width="6" height="60" viewBox="0 0 6 60" fill="none">
        <path d="M3 60L5.88675 55L0.113246 55L3 60ZM2.5 -2.18557e-08L2.5 55.5L3.5 55.5L3.5 2.18557e-08L2.5 -2.18557e-08Z" fill="black" />
    </svg>
)

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        assignEsimDialog: {
            position: 'relative',
            padding: '40px',
            '& .close-icon': {
                position: 'absolute',
                top: '12px',
                right: '12px'
            }
        },
        assignEsimDialogContent: {
            padding: '30px 0 20px',
            '& .device, & .user': {
                padding: 0
            },
            '& .esim-assigned-text': {
                textAlign: 'center',
                marginBottom: '10px'
            },
            '& .instructions-text': {
                textAlign: 'center',
                padding: '0 .5rem',
                '& .email': {
                    color: verbalColors['secondary-600'],
                    wordBreak: 'break-word'
                }
            }
        },
        checkCompability: {
            display: 'flex',
            flexDirection: 'column',
            gap: '40px'
        },
        headingsContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        checksContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
        },
        checkContainer: {
            display: 'flex',
            gap: '10px'
        },
        checkTexts: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0'
        },
        assignEsimDialogDeviceContainer: {
            '& .line': {
                position: 'relative',
                left: '16px',
                bottom: '22px'
            }
        },
        assignEsimDialogUserContainer: {
            position: 'relative',
            bottom: '10px'
        },
        assignEsimDialogButtons: {
            display: 'flex',
            justifyContent: 'center',
            columnGap: '20px'
        }
    }
})

/**
 *
 */
const AssigneeDialog = () => {
    const classes = useStyles()
    const { fetchEsims, assignUser, assignUserHandler } = useContext(EsimContext)
    const { selectedUser, selectedUserData, openAssigneeDialog, handleCloseAssigneeDialog, data, setData, isSimAssigned, setIsSimAssigned, selectedPhone } = useEsimOperationsContext()
    const [assignEsimChecked, setAssignEsimChecked] = useState(false)
    const [error, setError] = useState('')
    if (!data) return null

    const handleAssign = async () => {
        setError('')
        const response = await assignUserHandler(
            data!.service_id,
            selectedUser!.extension.id,
            selectedPhone,
            selectedUserData
        )
        if (response) {
            setIsSimAssigned(true)
            setData({ ...response, user: { ...response.user, extension: selectedUser.extension } })
            fetchEsims(false, true)
        } else {
            setError('Error assigning user')
        }
    }

    const handleCloseDialog = () => {
        handleCloseAssigneeDialog()
        setAssignEsimChecked(false)
    }
    return (
        <MuiDialog
            open={openAssigneeDialog}
            onClose={handleCloseDialog}
            aria-labelledby="assignee-dialog"
            className={classes.assignEsimDialog}
        >
            <IconButton size='medium' className='close-icon' name='close' variant='icon' color='neutral' onMouseUp={handleCloseDialog} />

            <div className={classes.assignEsimDialogContent}>
                {
                    !isSimAssigned
                        ? <>
                            <Typography variant='h6' color='neutral-900'>{details.assignEsimDialog.title}</Typography>
                            {error && <Alert marginY={10} autoclose onClose={() => setError('')}>{error}</Alert>}
                            <div className={classes.assignEsimDialogDeviceContainer}>
                                <Device sim={`Plan: ${data.plan?.name}`} id={`ICCID: ${data.esim?.iccid}`} />
                                {line}
                            </div>
                            <div className={classes.assignEsimDialogUserContainer}>
                                <Typography className='assign-to-text' variant='captionHeavy' color='secondary-500'>{details.assignEsimDialog.assignedTo}</Typography>
                                <User user={selectedUser} phone={selectedPhone} />
                            </div>
                            <Checkbox checked={assignEsimChecked} color='primary' onChange={(isChecked: boolean) => setAssignEsimChecked(isChecked)}>{details.assignEsimDialog.checkMessage}</Checkbox>
                            {assignUser.error && <Alert level='error' icon={true}>{assignUser.error}</Alert>}
                        </>
                        : <>
                            <Typography variant='h6' color='neutral-900' align='center' className='esim-assigned-text'>{details.assignEsimDialog.assignedMessage}</Typography>
                            <Typography variant='body2' color='neutral-900' align='center' className='instructions-text'>{details.assignEsimDialog.emailSentMessage} <span className='email'>{selectedUser.email}</span></Typography>
                        </>
                }
            </div>
            <div className={classes.assignEsimDialogButtons}>
                {
                    !isSimAssigned
                        ? <>
                            <Button onClick={handleCloseDialog} color="neutral">
                                {details.cancelButton}
                            </Button>
                            <Button onClick={handleAssign} color="primary" disabled={!assignEsimChecked || assignUser.isLoading} isLoading={assignUser.isLoading} minWidth={'70px'}>
                                {details.assignButton}
                            </Button>
                        </>
                        : <Button onClick={handleCloseDialog} color="neutral">
                            Ok
                        </Button>
                }
            </div>
        </MuiDialog>
    )
}

export default AssigneeDialog
