
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from 'foundations-library/components'
import { useHistory, useLocation } from 'react-router-dom'
import { getVerbalColors } from 'helpers'
import { useAppContext } from './../contexts/AppContext'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            backgroundColor: verbalColors['neutral-50'],
            height: '54px',
            alignItems: 'center',
            paddingLeft: '10px',
            gap: '12px',
            '& .delimiter': {
                width: '1px',
                height: '24px',
                backgroundColor: verbalColors['neutral-100']
            }
        }

    }
})

/**
 *
 */
const GoBack = ({ title }: { title?: string }) => {
    const classes = useStyles()
    const [hashCount, setHashCount] = useState(0)
    const [currentPath, setCurrentPath] = useState('')
    const [currentHash, setCurrentHash] = useState('')
    const [goBackClicked, setGoBackClicked] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const { app } = useAppContext()

    useEffect(() => {
        if (location.hash) {
            if (location.hash === currentHash) return

            setHashCount(hashCount => hashCount + 1)
            setCurrentHash(location.hash)
        } else {
            setHashCount(0)
            setCurrentHash('')
        }

        if (goBackClicked) {
            if (location.pathname === currentPath) history.go(-1)

            setGoBackClicked(false)
        }

        setCurrentPath(location.pathname)
    }, [location])

    const handleGoBack = (count: number) => {
        history.go(-1 - count)
        setGoBackClicked(true)
    }

    return (
        <div className={classes.root}>
            <IconButton name='back' variant='icon' color='neutral' size='small' onMouseUp={handleGoBack.bind(this, hashCount)} data-testid='back-btn' />
            <div className='delimiter' />
            <Typography variant='h8' color='neutral-900'>{title || app.title}</Typography>
        </div>
    )
}

export default GoBack
