import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { Typography } from 'foundations-library/components'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import Device from './../../components/Device'
import { getStatus } from './../../../utils/index'
import { details } from './../../../utils/objects'
import { ScreenSizeContext } from 'providers'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            flexDirection: ({ screenSize }) => screenSize.mobile ? 'column' : 'row',
            gap: ({ screenSize }) => screenSize.mobile && '10px',
            justifyContent: 'space-between',
            alignItems: ({ screenSize }) => !screenSize.mobile && 'center',
            borderRadius: '10px 10px 0px 0px',
            border: `1px solid ${verbalColors['neutral-200']}`,
            padding: '16px 20px 12px 20px',
            '& .device': {
                padding: 0,
                height: '50px'
            }
        },
        statusContainer: {
            display: 'flex',
            alignItems: 'center',
            columnGap: '10px'
        },
        statusValue: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 10px',
            borderRadius: '4px',
            backgroundColor: 'rgba(211, 235, 230, 0.60)',
            height: '28px'
        }
    }
})

/**
 *
 */
const SimStatus = () => {
    const screenSize = useContext(ScreenSizeContext)
    const classes = useStyles({ screenSize })
    const { data } = useEsimOperationsContext(); if (!data) return null
    const [status] = getStatus(data)

    return (
        <div className={classes.root}>
            <Device sim={data.esim?.device_name || `eSIM ${data.service_id}`} id={`ICCID: ${data.esim?.iccid}`} />
            <div className={classes.statusContainer}>
                <Typography variant='subtitle3' color='neutral-900'>{details.status}</Typography>
                <div className={classes.statusValue}>
                    <Typography variant='subtitle3' color='secondary-700'>{status}</Typography>
                </div>
            </div>
        </div>
    )
}

export default SimStatus
