import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NavItem } from '../Navigation'
import Typography from 'typography'
import { Link } from 'react-router-dom'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { NavigationContext } from '../NavigationContext'
import NavItemIcon from '../CommonUI/NavItemIcon'
import NavItemBadge from '../CommonUI/NavItemBadge'
import SidebarV1Badge from './SidebarV1Badge'
import NavLink from 'nav-link'

const useStyles = makeStyles(theme => ({
    navLink: ({ active }: { active: boolean}) => ({
        padding: '15px 10px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: active ? '#36404740' : 'transparent',
        cursor: 'pointer',
        color: theme.palette.text.primary
    }),
    icon: {
        marginLeft: '14px'
    },
    title: {
        marginLeft: '24px',
        flexGrow: 1
    },
    subMenu: {
        marginLeft: '31px',
        borderLeft: '2px solid #36404740'
    }
}))

interface props {
    navItem: NavItem;
}

/**
 * Renders the UI for a given nav item, including all its descendent nav items.
 */
const SidebarV1NavItem = ({ navItem }: props): JSX.Element => {
    const { getActiveNavItems } = useContext(NavigationContext)

    const activeNavItems = getActiveNavItems()

    const active = activeNavItems.some(activeNavItem => activeNavItem.id === navItem.id) && !navItem.children
    const classes = useStyles({ active })

    const [expanded, setExpanded] = useState<boolean>(false)

    const onClick = event => {
        if (navItem.children?.length) {
            event.preventDefault()
            event.stopPropagation()
            setExpanded(!expanded)
        }
    }

    const renderContent = () => (
        <>
            <NavItemIcon navItem={navItem} />

            {navItem.title && (
                <Typography variant="body1" className={classes.title}>
                    {navItem.title}
                </Typography>
            )}

            <NavItemBadge navItem={navItem} badgeProps={{ designComponent: SidebarV1Badge }} />

            {navItem.children?.length && (
                <>
                    {expanded
                        ? (
                            <ExpandLess onClick={() => setExpanded(false)} />
                        )
                        : (
                            <ExpandMore onClick={() => setExpanded(true)} />
                        )}
                </>
            )}
        </>
    )

    return (
        <div className={classes.navItem} data-testid={navItem.id}>
            {navItem.children?.length
                ? (
                    <Link onClick={onClick} to={undefined} className={classes.navLink}>
                        {renderContent()}
                    </Link>
                )
                : (
                    <NavLink navItemId={navItem.id} className={classes.navLink}>
                        {renderContent()}
                    </NavLink>
                )}

            {navItem.children && expanded &&
                <div className={classes.subMenu}>
                    {navItem.children.map(subNavItem => (
                        <SidebarV1NavItem
                            key={subNavItem.id}
                            navItem={subNavItem}
                        />
                    ))}
                </div>
            }
        </div>
    )
}

export default SidebarV1NavItem
