import { VerbalColorType } from 'foundations-library/types'

/**
 *
 */
export const makeFirstLetterUppercase = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

/**
 *
 */
export const getFirstLetters = (text: string) => text.split(' ').map(word => word.charAt(0)).join('')

/**
 *
 */
export const makeURL = (endpoint: string): RegExp => new RegExp(`^https?://.*/${endpoint}$`, 'i')

/**
 *
 */
export const getStatus = (sim: Record<string, any>, assignedToMe = false) => {
    let statusText = sim?.status || ''
    let statusCaption = ''
    let statusTextColor: VerbalColorType = 'neutral-900'

    const status = sim?.status.toLowerCase()

    const setReadyOrAvailable = (sim) => {
        if (!sim.user) {
            statusText = 'Ready for assignment'
            statusCaption = 'Assign a user to activate.'
            statusTextColor = 'secondary-600'
        } else {
            statusText = 'Pending Device Activation'
            statusCaption = !assignedToMe ? 'Ready for activation.' : 'An eSIM has been assigned to you.'
        }
    }

    switch (status) {
            case 'ordered':
            case 'backordered': {
                statusText = 'In progress'
                statusCaption = 'Your eSIM order is in progress.'
                statusTextColor = 'destructive-500'
                break
            }
            case 'deleted': {
                statusText = 'Terminated'
                statusTextColor = 'neutral-300'
                break
            }
            case 'provisioned':
            case 'available': {
                setReadyOrAvailable(sim)
                break
            }
            case 'activated': {
                let formattedDate = ''
                if (sim.activated_at) {
                    const dateObj = new Date(sim.activated_at)
                    formattedDate = dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
                }
                statusText = 'Installed'
                statusCaption = `Installed ${formattedDate}.`
                break
            }
    }

    if (sim?.deletion_requested) {
        const terminateDate = new Date(sim.deletion_requested_at).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
        statusText = 'Termination Requested'
        statusCaption = `To be deleted at ${terminateDate}`
        statusTextColor = 'destructive-500'
    }

    return [statusText, statusCaption, statusTextColor]
}

/**
 *
 */
export const makePromise = <T>(arg: T) => new Promise(resolve => resolve(arg))
