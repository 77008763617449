import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'foundations-library/components'
import { getVerbalColors } from 'helpers'
import TransitionSimDialog from './TransitionSimDialog'
import TerminateSimProcess from './terminate/index'
import { details } from '../../../utils/objects'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)
    const border = `1px solid ${verbalColors['neutral-200']}`

    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 0px',
            gap: '20px',
            borderTop: border
        }
    }
})

/**
 *
 */
const TransitionSim = () => {
    const classes = useStyles()
    const [openTransitionDialog, setOpenTransitionDialog] = useState(false)
    const [openTerminateDialog, setOpenTerminateDialog] = useState(false)

    const closeDialogs = () => {
        setOpenTransitionDialog(false)
        setOpenTerminateDialog(false)
    }

    const handleOpenTerminateDialog = () => {
        setOpenTerminateDialog(true)
        setOpenTransitionDialog(false)
    }

    // const openTransferDialog = () => {
    //     setOpenTerminateDialog(false)
    //     setOpenTransitionDialog(true)
    // }

    return (
        <div className={classes.root}>
            <Button variant='text' color='destructive' size='large' onClick={handleOpenTerminateDialog}>{details.terminateBtn}</Button>
            {/* <Button variant='contained' color='primary' size='large' icon='go' iconLeft={false} onClick={openTransferDialog}>{details.transferBtn}</Button> */}
            <TransitionSimDialog isOpen={openTransitionDialog} onClose={closeDialogs} />
            <TerminateSimProcess start={openTerminateDialog} forceQuit={false} onClose={closeDialogs} />
        </div>
    )
}

export default TransitionSim
