import React, { useState } from 'react'
import VoicemailEditor from 'voicemail-editor'
import PropTypes from 'prop-types'
import SettingsWrapper from '../SettingsWrapper'

/**
 * @param {object} props
 */
const Voicemail = (props) => {
    const [voicemail, setVoicemail] = useState(null)

    const showAlone = props.routeProps?.location.pathname.split('personal-settings/voicemail')[1].substring(0, 11) === '/show-alone'
    return (
        <SettingsWrapper>
            <VoicemailEditor
                voicemail = {voicemail}
                updateVoicemail = {val => setVoicemail(val)}
                setBusy = {props.setBusy}
                smallView = {props.smallView}
                showAlone = {!!showAlone}
            />
        </SettingsWrapper>
    )
}

Voicemail.propTypes = {
    /**
     * Call it with `true` in case there is an unsaved change
     * or with `false` in case of no changes. It will prevent going away from the app
     * in case the user clicks somewhere.
     */
    setBusy: PropTypes.func,
    /**
     * Is it small / mobile view
     */
    smallView: PropTypes.bool,
    /**
     * Route props
     */
    routeProps: PropTypes.any
}

Voicemail.defaultProps = {
    smallView: false
}

export default Voicemail
