import React, { Component, createContext } from 'react'
import { Teammates } from 'models'
import { getPhoneCom } from 'phonecom'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    children: any
}
interface TeammateProviderState {
    teammates: Teammates;
    loaded: boolean;
    isLoading: boolean;
}

/***/
export const TeammateContext = createContext<any>({})

/**
 *
 */
export const TeammateConsumer = TeammateContext.Consumer

class TeammateProvider extends Component<Props, TeammateProviderState> {
    /**
     * @param props
     */
    constructor (props) {
        super(props)
        this.state = {
            teammates: new Teammates(),
            loaded: false,
            isLoading: false
        }
    }

    /**
     *
     */
    componentDidMount () {
        this.load()
    }

    list = async (filter = ''): Promise<Teammates> => {
        await this.load()
        let res = this.state.teammates
        if (filter) {
            res = res.filter(filter)
        }
        return res
    }

    load = async (): Promise<void> => {
        if (!this.state.loaded && !this.state.isLoading) {
            this.setState({ isLoading: true })
            await getPhoneCom()
            await this.state.teammates.load()
            await this.setState({ loaded: true, isLoading: false })
        }
    }

    hasExtension = (ext: number) : boolean => {
        return this.state.teammates.contains(ext)
    }

    /**
     *
     */
    render () {
        const { list, next, hasExtension } = this
        const { loaded, teammates } = this.state
        // let { displayNames } = this.state
        return (<TeammateContext.Provider value={{
            list,
            next, // todo heck where net is used
            hasExtension,
            teammates,
            loaded
        }}>
            {this.props.children}
        </TeammateContext.Provider>)
    }
}

export default TeammateProvider
