import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { Skeleton } from 'foundations-library/components'
import { ScreenSizeContext } from 'providers'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)
    const smallScreen = (v) => v.mobile || v.tablet

    return {
        simStatus: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '10px 10px 0px 0px',
            border: `1px solid ${verbalColors['neutral-200']}`,
            padding: '16px 20px 12px 20px',
            height: '80px'
        },
        selectAssignee: {
            rowGap: '1rem',
            border: `1px solid ${verbalColors['neutral-200']}`,
            borderTop: 'none',
            borderRadius: '0 0 10px 10px',
            padding: '30px 30px 52px 20px'
        },
        assigneeRoot: {
            maxWidth: '691px',
            margin: '0 auto',
            columnGap: '20px',
            display: 'flex',
            flexDirection: ({ screenSize }) => smallScreen(screenSize) ? 'column' : 'row'
        }
    }
})

/**
 *
 */
const SimAssigneeSkeleton = () => {
    const screenSize = useContext(ScreenSizeContext)
    const classes = useStyles({ screenSize })

    return (
        <>
            <div className={classes.simStatus}>
                <Skeleton variant='rect' width={'40%'} height={'100%'} />
                <Skeleton variant='rect' width={'20%'} height={'30px'} />
            </div>
            <div className={classes.selectAssignee}>
                <div className={classes.assigneeRoot}>
                    <Skeleton variant='text' width={'200px'} height={'23px'} style={{ marginBottom: '10px' }} />
                    <div style={{ width: '100%' }}>
                        <Skeleton variant='rect' width={'250px'} height={'23px'} style={{ marginBottom: '10px' }} />
                        <Skeleton variant='rect' width={'100%'} height={'45px'} style={{ marginBottom: '10px' }} />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <Skeleton variant='rect' width={'48%'} height={'45px'} />
                            <Skeleton variant='rect' width={'48%'} height={'45px'} />
                        </div>
                        <Skeleton style={{ marginBottom: '10px' }} variant='rect' width={'100%'} height={'45px'} />
                        <Skeleton style={{ marginBottom: '10px' }} variant='rect' width={'100%'} height={'45px'} />
                        <Skeleton style={{ marginBottom: '10px' }} variant='rect' width={'100%'} height={'45px'} />
                        <div style={{ width: '100%', display: 'flex', marginBottom: '10px' }}>
                            <Skeleton variant='rect' width={'130px'} height={'36px'} />
                            <Skeleton variant='rect' width={'150px'} height={'36px'} style={{ marginLeft: '10px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SimAssigneeSkeleton
