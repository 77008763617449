import React from 'react'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: '#1C9980', // TODO: possibly source from theme.palette, maybe Secondary/500
        border: '1px solid #02211B', // TODO: possibly source from theme.palette
        boxShadow: '-1.5px 1px 0px 0px #02211B' // TODO: possibly source from theme.palette
    },
    badgeText: {
        fontFamily: 'Inter, Montserrat, sans-serif !important',
        fontSize: '11px !important',
        color: '#fff',
        fontStyle: 'normal !important',
        fontWeight: '600 !important',
        lineHeight: '16px !important'
    }
}))

interface Props {
    children: React.ReactNode;
}

/**
 * Badge designed to display information (eg. a count of unread messages) for a given nav item on SidebarV2.
 * This should be used like a design component, you may need to create a wrapper component with the logic
 * that actually reads/fetches the necessary data and provides it to this design component.
 * NavItems can be configured to use badge components - see NavConfig in Navigation.tsx.
 */
const SidebarV2Badge = (props: Props) => {
    const classes = useStyles()

    if (!props.children) {
        return null
    }

    let displayValue = props.children
    if (typeof props.children === 'number') {
        if (props.children <= 0) {
            return null
        } else if (props.children > 9) {
            displayValue = '9+' // Truncated because of the limited space in the circular SidebarV2Badge
        }
    }

    return (
        <span className={`${classes.root} badge`} data-testid='badge'>
            <Typography component='span' className={classes.badgeText}>
                {displayValue}
            </Typography>
        </span>
    )
}

export default SidebarV2Badge
