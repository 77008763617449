import React, { useMemo, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'foundations-library/components'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import TerminateSimBtn from './TerminateSimBtn'
import { details } from '../../../utils/objects'
import UserForm from './UserForm'
import { getVerbalColors } from 'helpers'
import { ScreenSizeContext } from 'providers'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)
    const border = `1px solid ${verbalColors['neutral-200']}`
    const smallScreen = (v) => v.mobile || v.tablet
    return {
        selectAssignee: {
            border,
            borderTop: 'none',
            borderRadius: '0 0 10px 10px'
        },
        form: {
            padding: ({ screenSize }) => smallScreen(screenSize) ? '20px 20px 20px 20px' : '30px 70px 30px 20px',
            display: 'flex',
            flexDirection: ({ screenSize }) => smallScreen(screenSize) ? 'column' : 'row',
            maxWidth: '691px',
            margin: '0 auto',
            columnGap: '20px',
            '& .user-dropdown': {
                '& [data-testid="select-select-toggle"] .ext-number': {
                    display: 'none'
                },
                '& .select-menu': {
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '.5rem'
                }
            }
        },
        buttons: {
            padding: '20px 0',
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            borderTop: border
        }
    }
})

/**
 *
 */
const SelectAssignee = () => {
    const screenSize = useContext(ScreenSizeContext)
    const classes = useStyles({ screenSize })

    const {
        selectedUser,
        selectedPhone,
        selectedUserData,
        handleOpenAssigneeDialog
    } = useEsimOperationsContext()

    const submitButtonDisabled = useMemo(() => {
        return !selectedUser ||
            !selectedPhone ||
            !selectedUserData?.first_name ||
            !selectedUserData?.last_name ||
            !selectedUserData?.email
    }, [selectedUser, selectedPhone, selectedUserData])

    return <div className={`${classes.selectAssignee} select-assignee-form`}>
        <div className={classes.form}>
            <Typography variant='subtitle1' color='neutral-500'>
                Assignee:
            </Typography>
            <UserForm />
        </div>
        <div className={classes.buttons}>
            <TerminateSimBtn size='medium' />
            <Button
                size='medium'
                color='primary'
                variant='contained'
                icon='go'
                onClick={handleOpenAssigneeDialog}
                disabled={submitButtonDisabled}
            >{details.assignButton}</Button>
        </div>
    </div>
}

export default SelectAssignee
