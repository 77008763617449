/* eslint-disable react/prop-types */
import React, { Component } from 'react'

import 'react-toastify/dist/ReactToastify.css'
import { connect } from 'react-redux'
import { setSmallView, switchView } from '../actions/view.js'
import { addMessage, addConversation, updateMessage, deleteMessage, deleteConversations, resetMyNumbers } from '../actions/conversations.js'
import {
    updateConversations, updateConversation, switchConversation,
    switchSendNumber, updateTotalConversations, addContactsToConversations,
    removeContactFromConversations
} from '../actions/conversations'
import { updateSelectedExtensionPhoneNumbersFeatures, switchExtension } from '../actions/pdcuser'
import ResizeAware from 'react-resize-aware'
import NewConversationPanel from './content/NewConversationPanel'
import AppHeader from './AppHeader'
import MediaModal from './MediaModal'
import ErrorCatcher from 'error-catcher'
import ConversationContent from './content/ConversationContent'
import ConversationsPanel from './controls/ConversationsPanel'
import { ToastContainer } from 'react-toastify'
import api from '../util/api_v2'
import PhoneComUser from 'phone-com-user'
import '../util/modernizr-custom'
import ov from 'object.values'
import Modal from 'react-modal'
import { withStyles } from '@material-ui/core'
import LoaderFull from 'loader-full'
import { pushMessageNotification } from 'notification-pusher'
import { Switch, Route } from 'react-router-dom'
// import { addSWEventListener } from 'service-worker-utils'
import PropTypes from 'prop-types'
import { ScreenSizeContext } from 'providers'

if (!Object.values) ov.shim()

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 0,
        background: 0
    },
    overlay: {
        zIndex: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
    }
}

const styles = theme => ({
    appWrapper: {
        display: 'flex',
        height: '100%'
    },
    leftPanel: {
        boxShadow: theme.palette.primary.flatRightShadow,
        ZIndex: 2,
        background: '#ffffff',
        overflow: 'hidden',
        boxSizing: 'border-box',
        minWidth: theme.selector.width,
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        '&.small-view': {
            width: '100%'
        }
    },
    rightPanel: {
        zIndex: 1,
        height: '100%',
        width: '100%',
        position: 'relative',
        '&:not(.mobile)': {
            maxWidth: theme.selector.restWidth
        }
    },
    loadingDiv: theme.loadingDiv
})

const threshold = 768
const BUILD_VERSION = '1.0.0'

const mapStateToProps = state => {
    const conversations = state.conversations || []
    return {
        conversations,
        currentConversation: conversations.find(c => c.selected),
        smallView: state.smallView,
        currentView: state.currentView,
        sendNumber: state.selectedSendNumber,
        currentExtensionRedux: state.currentExtension
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    const fns = {
        updateConversations: convs => dispatch(updateConversations(convs)),
        updateConversation: conv => dispatch(updateConversation(conv)),
        updateTotalConversations: total => dispatch(updateTotalConversations(total)),
        setSmallView: boolVal => dispatch(setSmallView(boolVal)),
        switchConversation: (conv, scroll) => dispatch(switchConversation(conv, scroll)),
        updateSelectedExtensionPhoneNumbersFeatures: extensionPhoneNumbersFeatures => dispatch(updateSelectedExtensionPhoneNumbersFeatures(extensionPhoneNumbersFeatures)),
        switchSendNumber: num => dispatch(switchSendNumber(num)),
        switchView: view => dispatch(switchView(view)),
        addMessage: (message, conv_id, unread_messages) => dispatch(addMessage(message, conv_id, unread_messages)),
        addConversation: conversation => dispatch(addConversation(conversation)),
        updateMessage: (message, conversation_id) => dispatch(updateMessage(message, conversation_id)),
        switchExtension: extension => dispatch(switchExtension(extension)),
        addContactsToConversations: contacts => dispatch(addContactsToConversations(contacts)),
        removeContactFromConversations: contactId => dispatch(removeContactFromConversations(contactId)),
        deleteMessage: (by, flag, conv_id) => dispatch(deleteMessage(by, flag, conv_id)),
        deleteConversations: conversationIds => dispatch(deleteConversations(conversationIds)),
        resetMyNumbers: numbers => dispatch(resetMyNumbers(numbers))
    }

    const data = {}
    for (const fn of Object.keys(fns)) {
        if (typeof ownProps[fn] === 'function') {
            data[fn] = ownProps[fn]
        } else {
            data[fn] = fns[fn]
        }
    }

    return data
}
class App extends Component {
    static propTypes = {
        appData: PropTypes.object,
        resetSubscription: PropTypes.func,
        extension: PropTypes.object
    };

    constructor (props) {
        super(props)
        const hasConversationID = this.props.appData && this.props.appData.message && this.props.appData.message.conversation_id
        this.state = {
            deletedMessages: { num: 0, randomString: '' },
            deletedConversations: { num: 0, randomString: '' },
            newNotifications: 0,
            isMediaModalOpened: false,
            mediaModalContent: this.mediaModalStructure(),
            showNewUserSplash: false,
            loading: false,
            loadedOnce: false,
            appData: hasConversationID ? this.props.appData : null
        }
        Modal.setAppElement('body')

        this.props.resetSubscription(true)
        if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(props.extension.extension_id)
        }
    }

    static contextType = ScreenSizeContext

    mediaModalStructure () {
        return {
            total: 0,
            conversation_id: null,
            media: []
        }
    }

    componentDidUpdate (prevProps) {
        this.switchRouterConversation()

        if (prevProps.extension.phone_number !== this.props.extension.phone_number) {
            this.props.resetMyNumbers(this.props.extension.phone_number)
        }

        if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(this.props.extension.extension_id)
            this.updatePhoneNumbers()
            this.initialLoad(true)
        }

        if (!prevProps.focused && this.props.focused) {
            this.setState({ newNotifications: 0 })
            this.props.resetSubscription()
        }

        this.updateContactsInfoInConversations()
    }

    switchRouterConversation = async () => {
        // The router (react-router-dom) path conversation can change and in that case we should update the redux
        if (!this.state.loadedOnce || this.state.loading) return
        const routerPathnameSplit = this.props.routerProps.location.pathname.split('/')
        if (routerPathnameSplit.length <= 3) return
        let routerConversationId = routerPathnameSplit[3]
        if (routerConversationId === 'new-conversation') return
        routerConversationId = routerConversationId.substring(1)
        if (routerConversationId === 'new-conversation') return
        const currentConversationId = this.props.currentConversation ? this.props.currentConversation.id : ''
        const conversations = this.props.conversations
        if (routerConversationId === currentConversationId || !conversations || conversations.length === 0) return
        let routerConversation = conversations.find(c => c.id === routerConversationId)
        // If the conversation is loaded then go to it otherwise load it firstly and put it last and scroll to it
        if (!routerConversation) {
            // Load the conversation, add it last to the list and scroll to it
            this.setState({ loading: true })
            const response = await api.loadConversations(0, 1, { id: routerConversationId })
            const conversation = response.conversations[0]
            if (!conversation) {
                this.setState({ loading: false })
                return this.props.switchConversation(conversations[0])
            }
            this.props.addConversation(conversation)
            this.setState({ loading: false })
            routerConversation = conversation
        }
        this.props.switchConversation(routerConversation, true)
        this.updateRouterHistory(`c${routerConversationId}`)
    }

    updateContactsInfoInConversations = () => {
        if (!this.props.contactsUtil.extraContactsLoaded) return
        const numNewContacts = 0
        // const conversations = this.props.conversations
        const contactIdsToBeRemoved = []
        /* conversations.forEach(conv => {
            conv.participants.forEach(p => {
                if (conv.my_numbers && conv.my_numbers.includes(p.number)) return
                let extraContact = this.props.contactsUtil.extraContacts.find(contact => contact.numbers.find(n => n.number === p.number))
                if (extraContact && !p.voip_contact_id && !p.contact_id) {
                    numNewContacts++
                } else if (!extraContact && (p.voip_contact_id || p.contact_id)) {
                    contactIdsToBeRemoved.push(p.voip_contact_id || p.contact_id)
                }
            })
        }) */
        if (numNewContacts > 0) {
            this.props.addContactsToConversations(this.props.contactsUtil.extraContacts)
        }
        if (contactIdsToBeRemoved.length) {
            contactIdsToBeRemoved.forEach(contactId => this.props.removeContactFromConversations(contactId))
        }
    }

    componentDidMount () {
        this._ismounted = true
        this.props.subscribeForNotifications('messages', this.messageHandler, true)
        this.initialLoad()
        this.showWelcomeMessageToNewUsers()
        const appData = this.props.appData
        if (!this.props.currentConversation || (appData && appData.message && appData.message.conversation_id &&
                    appData.message.conversation_id !== this.props.currentConversation.id)) {
            setTimeout(() => this.setState({ appData }), 0)
            // this.props.switchView('new_conversation')
        } else {
            this.setState({ appData: null })
        }
        const currentConversationId = this.props.currentConversation?.id
        const urlID = window.location.pathname.split('/')[3]
        if (!urlID && currentConversationId) {
            this.updateRouterHistory(`c${currentConversationId}`)
        }
        /*
        addSWEventListener('message', event => {
            console.log('sw_receive_message', event)
            if(event.data.errorMessage) return
            if(event.data){
                if(event.data.url.includes('/messaging/list-conversations')){
                    let response ={
                        conversations: event.data.items,
                        total: event.data.total,
                        filters: event.data.filters
                    }
                    if (response === 'network-error') return
                    let conversations = response.conversations.length ? response.conversations : []
                    conversations.reverse()
                    conversations.forEach(convo => {
                        if(this.props.conversations.findIndex(c => c.id === convo.id) === -1){
                            this.props.conversations.unshift(convo)
                        }
                    })
                    console.log("conversations 1", conversations, this.props.conversations)
                    let total = this.props.conversations.length
                    this.props.updateTotalConversations(total)
                    this.props.updateConversations(this.props.conversations)
                    this.switchToConversationIfNeeded()
                    // Load the storred contacts of each conversation and store them in redux
                    this.loadExtraContacts()
                    console.log("conversations 2", this.props.conversations)
                }
                if(event.data.url.includes('/messaging/list-messages')){
                    console.log('messages cache update', event.data)
                    let response = event.data
                    let c_id = response.filters.conversation_id
                    let messages = response.items
                    let conversation = this.props.conversations.find(conversation => conversation.id === c_id)
                    console.log('messages cache update 2', messages, conversation)
                    messages = messages.reverse()
                    messages.forEach(message => {
                        let existingMessageIndex = conversation.messages ?
                            conversation.messages.findIndex(m => m.message_id === message.message_id) : -1
                        if (existingMessageIndex === -1) {
                            this.props.addMessage(message, message.conversation_id, conversation.unread_messages)
                            conversation.scrollToMessage = message.message_id
                            this.props.updateConversation(conversation)
                        } else {
                            this.props.updateMessage(message, message.conversation_id)
                        }
                    })
                    console.log('messages cache update found convo', conversation)
                }
            }
        })
        */
    }

    initialLoad = async force => {
        const extensionSwitched = Boolean(!this.props.currentExtensionRedux || this.props.extension.extension_id !== this.props.currentExtensionRedux.extension_id)
        const isStartNewRedirect = this.checkStartNewRedirect()
        if (!extensionSwitched && this.props.conversations.length && !force) return this.setState({ loadedOnce: true })
        if (extensionSwitched) this.props.switchExtension(this.props.extension)
        if (this._ismounted) this.setState({ loading: true })
        this.initStates(isStartNewRedirect)
        this.props.contactsUtil.updateExtraContacts([])
        this.props.contactsUtil.reload()
        this.updatePhoneNumbers()
        await this.getConversations()
    }

    initStates = isStartNewRedirect => {
        if (!this._ismounted) return
        this.props.resetSubscription(true)
        this.props.updateConversations([])
        this.props.updateTotalConversations(null)
        if (!isStartNewRedirect) this.props.switchView('select')
    }

    updatePhoneNumbers = () => {
        const extensionPhoneNumbers = this.props.extension.phone_number
        if (this.props.extension) this.updateFeatures(extensionPhoneNumbers)
        const phoneNumbersList = Object.keys(extensionPhoneNumbers)
        this.props.switchSendNumber(phoneNumbersList)
    }

    updateFeatures = phoneNumbers => {
        const phoneNumbersList = phoneNumbers ? Object.keys(phoneNumbers) : []
        const features = {}
        phoneNumbersList.forEach(phoneNumber => (features[phoneNumber] = phoneNumbers[phoneNumber].features))
        this.props.updateSelectedExtensionPhoneNumbersFeatures(features)
    }

    getConversations = async (cache = null) => {
        // TODO: Maybe it is better to store whole conversations' response in redux as we have in the other apps.
        if (!this._ismounted) return // console.log('Conversations App.js got unmounted')
        const limit = Math.max(parseInt(window.innerHeight / 50), 15)
        const response = cache || await api.loadConversations(0, limit)
        if (response === 'network-error') return
        if (!this._ismounted) return // console.log('Messages App.js got unmounted')
        const conversations = response.conversations.length ? response.conversations : []
        this.props.updateTotalConversations(response.total)
        this.props.updateConversations(conversations)
        this.setState({ loading: false, loadedOnce: true })
        this.switchToConversationIfNeeded()

        // Load the storred contacts of each conversation and store them in redux
        this.loadExtraContacts()
    }

    loadExtraContacts = async () => {
        this.props.addContactsToConversations(this.props.contactsUtil.extraContacts)
        const conversations = this.props.conversations
        let phoneNumbers = []
        conversations.forEach(c => {
            c.not_my_nums.forEach(n => {
                const extraContacts = this.props.contactsUtil.extraContacts
                let alreadyLoaded = false
                extraContacts.forEach(ec => {
                    ec.numbers.forEach(ecn => ecn.number === n.number ? (alreadyLoaded = true) : null)
                })
                if (!alreadyLoaded) phoneNumbers.push(n.number)
            })
        })
        if (phoneNumbers.length === 0) return
        // Remove duplicates
        phoneNumbers = Array.from(new Set(phoneNumbers))
        const filters = { keyword: phoneNumbers }
        await this.props.contactsUtil.loadExtraContacts(filters)
        this.props.addContactsToConversations(this.props.contactsUtil.extraContacts)
    }

    switchToConversationIfNeeded = () => {
        const conversations = this.props.conversations
        if (!conversations.length) {
            const pathnameSplit = window.location.pathname.split('/')
            if (pathnameSplit[3] !== 'new-conversation') this.props.switchView('select')
            this.updateRouterHistory('')
            this.props.switchConversation(null)
            return
        }
        if (!this.openUrlConversation() && !this.props.smallView && this.props.currentView !== 'new_conversation') {
            this.updateRouterHistory(`c${conversations[0].id}`)
            this.props.switchConversation(conversations[0])
        }
        const sendText = document.getElementsByClassName('send-message-text-input')[0]
        if (sendText) sendText.value = ''
    }

    // If the url path links to a conversation then open it
    openUrlConversation = () => {
        const pathname = window.location.pathname
        const pathnameSplit = pathname.split('/').filter(e => e)
        let conversationId
        if (pathnameSplit.length > 2 && pathnameSplit[1] === 'messages') {
            conversationId = pathnameSplit[2].substring(1)
        }
        if (!conversationId) return false
        const pathConversation = this.props.conversations.find(c => c.id === conversationId)
        if (!pathConversation) return false
        this.props.switchView('content')
        this.updateRouterHistory(`c${pathConversation.id}`)
        this.props.switchConversation(pathConversation)
        return true
    }

    getBasePath = () => {
        return this.props.routerProps.match.path.replace(/\/+$/g, '')
    }

    updateRouterHistory = path => {
        this.props.routerProps.history.push(`${this.getBasePath()}/${path}`)
    }

    checkStartNewRedirect = () => {
        // Check if it should redirect to 'new conversation' view
        const pathnameSplit = window.location.pathname.split('/')
        if (pathnameSplit[3] === 'new-conversation') {
            this.props.switchView('new_conversation')
            return true
        }
    }

    componentWillUnmount () {
        this._ismounted = false
        window.onfocus = () => null
        window.onblur = () => null
        window.onmouseover = () => null
    }

    showWelcomeMessageToNewUsers = () => {
        // NEW USER SCREEN
        if (this.props.standalone && window.Modernizr.localstorage && window.localStorage.getItem('build_version') !== BUILD_VERSION) {
            window.localStorage.setItem('build_version', BUILD_VERSION)
            this.showNewUserSplash()
        }
    }

    messageHandler = m => {
        if (m.type === 'read_status') return this.updateMessageReadStatus(m)
        if (m.type === 'delete') return this.deleteHandler(m)
        return this.messageUpdate(m)
    }

    deleteHandler = deleteData => {
        if (deleteData.message_ids) {
            this.deleteMessages(deleteData.message_ids)
        } else if (deleteData.conversation_ids) {
            this.deleteConversations(deleteData.conversation_ids)
        } else {
            console.warn('Unexpected data in delete handler:', deleteData)
        }
    }

    deleteMessages = messageIds => {
        if (!this.props.currentConversation) return
        messageIds = messageIds.map(id => `${id}`)
        const messageConversationMap = {}
        const conversations = this.props.conversations
        conversations.forEach(c => {
            const messages = c.messages
            if (!messages) return
            messages.forEach(message => {
                if (messageIds.includes(`${message.message_id}`)) {
                    messageConversationMap[message.message_id] = c.id
                }
            })
        })
        Object.keys(messageConversationMap).forEach(messageId => {
            this.props.deleteMessage('message_id', messageId, messageConversationMap[messageId])
        })
        const currentConversationId = this.props.currentConversation.id
        // NOTE: For n deleted messages OF THE CURRENT CONVERSATION it will load n more for that converastion
        // Maybe we need to do this for all of the conversations which:
        // 1. Are loaded;
        // 2. Have loaded messages i.e. we have clicked on the conversation and the messages got loaded for it.
        const currentConversationDeletedMessages = Object.keys(messageConversationMap).filter(id => messageConversationMap[id] === currentConversationId)
        this.setState({
            deletedMessages: {
                num: currentConversationDeletedMessages.length,
                randomString: `${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`
            }
        })
    }

    deleteConversations = conversationIds => {
        const currentConversation = this.props.currentConversation
        const currentConversationId = currentConversation ? currentConversation.id : null
        const shouldCurrentConversationBeDeleted = Boolean(currentConversationId && conversationIds.includes(currentConversationId))
        const conversations = this.props.conversations
        const loadedConversationsThatSholdBeDeleted = conversations.filter(c => conversationIds.includes(c.id) && c.id !== currentConversationId)
        if (shouldCurrentConversationBeDeleted) {
            // If the currenlty selected conversation is deleted
            // then do not remove it from the UI but remove all its messages and set its state to newlyAdded
            currentConversation.newlyAdded = true
            currentConversation.messages = []
            this.props.updateConversation(currentConversation)
        }
        let numDeleted = loadedConversationsThatSholdBeDeleted.length
        if (numDeleted) this.props.deleteConversations(loadedConversationsThatSholdBeDeleted)
        if (shouldCurrentConversationBeDeleted) numDeleted += 1
        if (!numDeleted) return
        this.setState({
            deletedConversations: {
                num: numDeleted,
                randomString: `${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`
            }
        })
    }

    updateMessageReadStatus = messageInfo => {
        const conversationId = messageInfo.conversation_id
        const messageId = messageInfo.message_id
        const readAt = messageInfo.read_at
        const eventAt = messageInfo.event_at
        const conversation = this.props.conversations.find(c => c.id === conversationId)
        if (!conversation) return
        const readStatusChangedAt = conversation.readStatusChangedAt
        if (readStatusChangedAt && eventAt < (readStatusChangedAt / 1000)) return

        if (conversation.messages) {
            const message = conversation.messages.find(m => parseInt(m.message_id) === parseInt(messageId))
            if (message) {
                if (message.read_at === readAt) return // Marked already
                message.read_at = readAt
            }
        }

        if (readAt) {
            const prevNumUnreadMessages = conversation.unread_messages
            let notMarkedRead = 0
            if (conversation.messages) { // If the conversation was not loaded then messages would be empty. Therefore the check.
                conversation.messages.forEach(message => {
                    if (message.direction !== 'in') return
                    if (message.read_at) return
                    if (message.message_id <= messageId) {
                        message.read_at = readAt
                    } else {
                        notMarkedRead++
                    }
                })
            }
            conversation.unread_messages = notMarkedRead
            this.props.changeMessageReadStatus('read', prevNumUnreadMessages - notMarkedRead)
        } else {
            conversation.unread_messages++
            if (conversation.messages) { // If the conversation was not loaded then messages would be empty. Therefore the check.
                conversation.messages.forEach(message => {
                    if (message.direction !== 'in') return
                    if (message.read_at) return
                    if (message.message_id >= messageId) {
                        message.read_at = null
                    }
                })
            }
            this.props.changeMessageReadStatus('unread', 1)
        }

        conversation.readStatusChangedAt = eventAt
        this.props.updateConversation(conversation)
    }

    messageUpdate = async m => {
        const randomTag = Math.floor(Math.random() * 1000000)
        m.randomTag = randomTag
        // We need the logs for tracking an issue of multiplicating same conversation in the selector.
        console.log('Messages -> App.js -> messageUpdate:', JSON.stringify(m, null, 4))
        const message = m.details

        let conversationIndex = this.props.conversations.findIndex(c => c.id === message.conversation_id)
        console.log(`It ${conversationIndex >= 0 ? 'found' : 'didn\'t find'} an existing convesration with index: ${conversationIndex}`)

        this.handleOutOfFocusEvents(message)
        if (conversationIndex >= 0) return this.updateConversation(conversationIndex, message)

        // Load this conversation and put it in state
        console.log(`Getting conversation with id: ${message.conversation_id}; randomTag: ${randomTag}`)
        const response = await api.loadConversations(0, 1, { id: message.conversation_id })
        if (response === 'network-error') return
        const conversation = response.conversations[0]
        console.log('This is the response:', JSON.stringify(response), `randomTag: ${randomTag}`)
        if (!conversation) return
        // Load the storred contacts of the conversation (if any) and store them in redux
        this.loadExtraContacts()
        conversationIndex = this.props.conversations.findIndex(c => c.id === message.conversation_id)
        if (conversationIndex < 0) {
            // This can happen if the conversation got deleted before we got update status on a message
            console.log(`Adding conversation with id: ${conversation.id}; randomTag: ${randomTag}`)
            this.props.addConversation(conversation)
        } else {
            // This can happen if the conversation was not present and the application got more than 1 message
            // in small interval and all of them got in this if (if (conversationIndex < 0))
            this.updateConversation(conversationIndex, message, conversation.unread_messages)
        }
    }

    handleOutOfFocusEvents = message => {
        // Out of focus events
        if (this.props.standalone && !this.props.focused) {
            if (this._ismounted) {
                this.setState({ newNotifications: this.state.newNotifications + 1 })
            }
            const extensionId = this.props.extension.extension_id
            pushMessageNotification(message, extensionId)
        }
    }

    updateConversation = (conversationIndex, message, unreadMessages) => {
        const conversationMessages = this.props.conversations[conversationIndex].messages
        const existingMessageIndex = conversationMessages
            ? conversationMessages.findIndex(m => m.message_id ? m.message_id === message.message_id : m.tag === message.tag)
            : -1
        if (existingMessageIndex === -1) {
            this.props.addMessage(message, message.conversation_id, unreadMessages)
            const currentConversation = this.props.currentConversation
            if (currentConversation) {
                currentConversation.scrollToMessage = message.message_id
                this.props.updateConversation(currentConversation)
            }
        } else {
            this.props.updateMessage(message, message.conversation_id)
        }
    }

    handleResize = size => {
        const needToExpand = this.props.smallView && ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
        const needToShrink = !this.props.smallView && ((this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
        if (needToShrink) {
            this.props.setSmallView(true)
        } else if (needToExpand) {
            this.props.setSmallView(false)
            const conversations = this.props.conversations
            if (conversations && conversations.length) {
                this.updateRouterHistory(`c${conversations[0].id}`)
                this.props.switchConversation(conversations[0])
            }
        }
        const pathnameSplit = window.location.pathname.split('/')
        if (pathnameSplit.length <= 4 || pathnameSplit[3] !== 'new-conversation') {
            if (this.props.currentConversation) this.props.switchView('content')
        }
    }

    onConversationDeleted = conversationId => {
        this.setState({
            deletedConversations: {
                num: 1,
                randomString: `${Math.floor(Math.random() * 1000000)}${(new Date()).getTime()}`
            }
        })
    }

    renderLeftPanel () {
        const { classes } = this.props
        const isActive = !this.props.smallView || this.props.currentView === 'select'
        return (
            <div
                className = {`${classes.leftPanel} ${!isActive ? 'hidden' : ''} ${this.props.smallView ? 'small-view' : ''}`}
                style = {this.state.loading ? { filter: 'blur(1px)' } : {}}
            >
                {this.state.loading
                    ? ''
                    : <ConversationsPanel
                        extension = {this.props.extension}
                        updateUnreadCounts = {this.props.updateUnreadCounts}
                        changeMessageReadStatus = {this.props.changeMessageReadStatus}
                        loadExtraContacts = {this.loadExtraContacts}
                        extraContacts = {this.props.contactsUtil.extraContacts}
                        deletedConversations = {this.state.deletedConversations}
                        updateRouterHistory = {this.updateRouterHistory}
                    />
                }
            </div>
        )
    }

    onNewConverasationRoute = () => {
        if (this.state.loading) return null
        return (
            <NewConversationPanel
                extension = {this.props.extension}
                contactsUtil = {this.props.contactsUtil}
                loadExtraContacts = {this.loadExtraContacts}
                appData = {this.state.appData}
                updateRouterHistory = {this.updateRouterHistory}
            />
        )
    }

    onConversationRoute = routeProps => {
        const renderContent = this.props.currentConversation && (!this.props.smallView || this.props.currentView === 'content')
        if (this.state.loading) return null
        if (!renderContent) return null
        return (
            <ConversationContent
                isOffline = {this.props.isOffline}
                conversation = {this.props.currentConversation}
                extension = {this.props.extension}
                openModal = {this.openMediaModal.bind(this)}
                userActive = {this.props.focused && this.props.userActive}
                onLoaded = {this.props.onLoaded}
                redirect = {this.props.redirect}
                updateUnreadCounts = {this.props.updateUnreadCounts}
                changeMessageReadStatus = {this.props.changeMessageReadStatus}
                onConversationDeleted = {this.onConversationDeleted}
                extraContacts = {this.props.contactsUtil.extraContacts}
                contactGroupTypes = {this.props.contactsUtil.groupTypes}
                updateContact = {this.props.contactsUtil.updateContact}
                deleteContact = {this.props.contactsUtil.deleteContact}
                addGroup = {this.props.contactsUtil.addGroup}
                deletedMessages = {this.state.deletedMessages}
                makeCall = {this.props.makeCall}
                updateRouterHistory = {this.updateRouterHistory}
                goTo = {this.props.goTo}
            />
        )
    }

    renderRightPanel () {
        const isActive = !this.props.smallView || ['content', 'new_conversation'].includes(this.props.currentView)
        const hiddenClass = !isActive ? 'hidden' : ''
        const mobileClass = this.props.smallView ? 'mobile' : ''
        const classNames = `${this.props.classes.rightPanel} ${hiddenClass} ${mobileClass}`
        const style = this.state.loading ? { filter: 'blur(1px)' } : {}
        const basePath = this.getBasePath()
        return (
            <div className={classNames} style={style}>
                <Switch>
                    <Route path={`${basePath}/new-conversation`} render={this.onNewConverasationRoute}/>
                    <Route path={`${basePath}/:conversationId`} render={this.onConversationRoute}/>
                </Switch>
            </div>
        )
    }

    updateTitle () {
        if (!this.props.standalone) return
        const notifs = this.state.newNotifications ? ` (${this.state.newNotifications})` : ''
        document.title = `Texts${notifs}`
    }

    setConversationDeletionApprovedValue = conversationDeletionApproved => this.setState({ conversationDeletionApproved })
    setConversationDeletionRejectedValue = conversationDeletionRejected => this.setState({ conversationDeletionRejected })

    renderLoader = () => {
        const { classes } = this.props
        if (!this.state.loading) return null
        const sidebarHidden = this.context.mobile || this.context.tablet
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull styles={{ loaderFull: { left: sidebarHidden ? '50%' : 'calc(50% + 120px)' } }} {...{ 'data-test-id': 'messages-loader' }} size='big'/>
            </div>
        )
    }

    renderMainContent = () => {
        const { classes } = this.props
        if (!this.state.loadedOnce) return null
        return (
            <div className={classes.appWrapper}>
                {this.renderLeftPanel()}
                {this.renderRightPanel()}
            </div>
        )
    }

    renderMediaModal = () => {
        return (
            <MediaModal
                isOpened = {this.state.isMediaModalOpened}
                mediaModalContent = {this.state.mediaModalContent}
                hideModal = {this.hideMediaModal.bind(this)}
                updateModal = {this.updateMediaModal.bind(this)}
            ></MediaModal>
        )
    }

    renderWelcomeModal = () => {
        return (
        /* NOTE: We should probably remove this modal and use a material-ui one */
            <Modal
                isOpen={this.state.showNewUserSplash}
                style={modalStyles}
            >
                <div className='modal-content messenger-modal'>
                    <div className='modal-X-button' onClick={this.hideNewUserSplash}/>
                    <h3>Your new inbox!</h3>
                    <p className='messenger-modal-message'>Welcome to your new Messages inbox! It will replace the SMS and Send SMS links on June 1st.</p>
                    <p className='messenger-modal-message'>Besides a brand new look, you can send pictures and group messages, as we now support MMS.</p>
                    <p>
                        <span className='button intro-message-btn' onClick={this.hideNewUserSplash}>OK!</span>
                    </p>
                </div>
            </Modal>
        )
    }

    render () {
        this.updateTitle() // Question: Should the update of the title be here? Shouldn't it be in communicator?
        return (
            <div style={{ height: '100%' }} className='App'>
                <ErrorCatcher>
                    <ToastContainer autoClose={2000}/>
                    <ResizeAware
                        className='resize-aware'
                        style={{ height: this.props.standalone ? 'calc(100% - 60px)' : '100%' }}
                        onResize={this.handleResize}
                    >
                        {this.props.standalone && <AppHeader smallView={this.props.smallView} />}
                        {this.renderLoader()}
                        {this.renderMainContent()}
                        {this.renderMediaModal()}
                    </ResizeAware>
                    {this.renderWelcomeModal()}
                </ErrorCatcher>
            </div>
        )
    }

    showNewUserSplash = () => this.setState({ showNewUserSplash: true })
    hideNewUserSplash = () => this.setState({ showNewUserSplash: false })

    openMediaModal = content => {
        this.setState({
            mediaModalContent: JSON.parse(content),
            isMediaModalOpened: true
        })
    }

    hideMediaModal = () => {
        this.setState({
            isMediaModalOpened: false,
            mediaModalContent: this.mediaModalStructure()
        })
    }

    updateMediaModal = (content, callback) => this.setState({ mediaModalContent: content }, callback)
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
