import React, { useEffect, useRef } from 'react'
import { getPhoneCom } from 'phonecom'

import { makeStyles } from '@material-ui/core/styles'

import zoid from 'zoid'
const useStyles = makeStyles(theme => ({
    root: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        minHeight: 500,
        width: '100%',
        display: 'flex',
        alignItems: 'stretch'
    }

}))

interface ZoidComponentInterface {
    tag: string,
    url: string,
    sendToken?: boolean // whether to send the current pdc access token in url

}

interface ZoidGlobalInterface {
    tag: any

}

// eslint-disable-next-line @typescript-eslint/naming-convention
const GLOBAL_ZOID_MAP: ZoidGlobalInterface = {
    tag: undefined // empty to pass ts linter
}

/**
 *
 */
const ZoidComponent = (props: ZoidComponentInterface) => {
    const containerRef = useRef(null) // Reference for the Zoid component container
    const classes = useStyles()

    useEffect(() => {
        if (!(props.tag && props.url)) {
            return
        }
        getPhoneCom().then(phoneCom => {
            // todo see if there is a more "reacty" way of making sure only 1 refernce per tag is made, zoid creates window globals for each
            const ZoidInstance = GLOBAL_ZOID_MAP[props.tag] || createZoidInstance(props.tag, props.url)
            GLOBAL_ZOID_MAP[props.tag] = ZoidInstance
            const sharedData = {}
            if (props.sendToken) {
                // eslint-disable-next-line dot-notation
                sharedData['accessToken'] = phoneCom.ac_token || phoneCom.cp_token
            }
            // Render the Zoid component into the container
            ZoidInstance(sharedData).render(containerRef.current)
                .then(() => {
                    console.log('Zoid component rendered successfully')
                })
                .catch(err => {
                    console.error('Error rendering Zoid component:', err)
                })
        })
    }, [])

    const createZoidInstance = (tag, url) => {
        return zoid.create({
            tag,
            url,

            autoResize: {
                height: false,
                width: false
            },
            dimensions: {
                height: '100%',
                width: '100%'
            },
            defaultLogLevel: 'debug'

        })
    }

    return (
        <div className={classes.root} ref={containerRef}></div>
    )
}

export default ZoidComponent
