import React, { Component } from 'react'
import { ExpandTriangle2 } from 'pdc-svg-icons'
import TextField from 'pdc-text-field'
import styles from './styles'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import { FSPrivate } from 'privacy'
import PropTypes from 'prop-types'

class ExtensionSelector extends Component {
    state = {
        isExtensionSelectorExpanded: false,
        filterExtensionsValue: ''
    }

    onExtensionHeaderClick = (event) => {
        event.stopPropagation()
        this.toggleExtensionSelector(!this.state.isExtensionSelectorExpanded)
    }

    toggleExtensionSelector = isExtensionSelectorExpanded => this.setState({ isExtensionSelectorExpanded })

    getExtensionName = extension => {
        const isBJ = Boolean(window.V5PHONECOM.user_id)
        const extensionName = extension.extension_name
        const extensionNumber = extension.extension
        if (isBJ) return extension.is_company ? 'Company inbox' : 'My inbox'
        else return `Ext ${extensionNumber} - ${extensionName}`
    }

    onExtensionSelect = extension => {
        this.setState({ isExtensionSelectorExpanded: false })
        if (extension.extension_id === this.props.userInfo.extension.extension_id) return
        this.props.onExtensionSelect(extension)
    }

    renderUnreadCount = extensionId => {
        if (!window.V5PHONECOM.user_id) return null // Don't show it for legacy accounts for now
        const { inboxesData, classes } = this.props
        if (!(extensionId in inboxesData)) return null
        const unread = inboxesData[extensionId].unread
        if (unread === 0) return null
        const unreadInfoText = unread > 99 ? '99+' : unread
        return (
            <div className={classes.unreadInfo}>
                <span title={unread}>{unreadInfoText}</span>
            </div>
        )
    }

    hasAnyUnread = () => {
        if (!window.V5PHONECOM.user_id) return null
        const { userInfo, inboxesData } = this.props
        const hasUnread = userInfo.extensions.find(e => {
            if (!(e.extension_id in inboxesData) || e.extension_id === userInfo.extension.extension_id) return false
            return inboxesData[e.extension_id].unread > 0
        })
        return hasUnread
    }

    renderExtensionSelector = () => {
        if (this.props.hideInboxSelector) return null
        const { userInfo } = this.props
        const mainText = this.getExtensionName(userInfo.extension)
        const { isExtensionSelectorExpanded, filterExtensionsValue } = this.state
        return (
            <ClickAwayListener onClickAway={() => this.toggleExtensionSelector(false)}>
                <div className={`extension-selector-section ${isExtensionSelectorExpanded ? 'expanded' : ''}`}>
                    <div
                        data-test-id = 'extension_selector-header'
                        className = 'extension-selector-header'
                        onClick = {this.onExtensionHeaderClick}
                    >
                        <span>{mainText}</span>
                        <ExpandTriangle2/>
                        {this.hasAnyUnread() ? <div className='general-unread-dot'/> : null}
                    </div>
                    {/* We need 2 wrappers over the extension items because we need some margin on the right of the scrollbar */}
                    <div className='extension-selector-wrapper'>
                        <div className='extension-selector-items-wrapper'>
                            {userInfo.extensions.length > 5
                                ? <FSPrivate>
                                    <TextField
                                        data-test-id = {'extension_selector-search-box'}
                                        label = 'Search extensions'
                                        content = {filterExtensionsValue}
                                        onInputChange = {filterExtensionsValue => this.setState({ filterExtensionsValue })}
                                        onClick={e => e.stopPropagation()}
                                    />
                                </FSPrivate>
                                : null}
                            {userInfo.extensions.map(e => {
                                const extensionItemName = this.getExtensionName(e)
                                const isSelected = e.extension_id === userInfo.extension.extension_id
                                const filter = filterExtensionsValue
                                if (filter && !extensionItemName.toLowerCase().includes(filter.toLowerCase())) {
                                    return null
                                }
                                const unreadCountElement = this.renderUnreadCount(e.extension_id)
                                const classNames = `extension-selector-item ${unreadCountElement ? 'has-unread' : ''} ${isSelected ? 'selected' : ''}`
                                return (
                                    <FSPrivate key={e.extension_id}>
                                        <div
                                            data-test-id = {`extension_selector-item ${extensionItemName}`}
                                            className = {classNames}
                                            onClick = {() => this.onExtensionSelect(e)}
                                            title = {extensionItemName}
                                            // {...aProps}
                                        >{extensionItemName}{unreadCountElement}</div>
                                    </FSPrivate>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <div className={classes.identityWrapper}>
                {this.renderExtensionSelector()}
            </div>
        )
    }
}

ExtensionSelector.propTypes = {
    classes: PropTypes.object,
    screenViewType: PropTypes.object,
    userInfo: PropTypes.object,
    onExtensionSelect: PropTypes.func,
    inboxesData: PropTypes.object,
    hideInboxSelector: PropTypes.bool,
    extension: PropTypes.object
}

export default withStyles(styles)(ExtensionSelector)
