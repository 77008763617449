import React from 'react'
import { Skeleton } from 'foundations-library/components'

/**
 *
 */
const BillingLoader = (): JSX.Element => {
    return <div data-testid='billing-loader'>
        <Skeleton variant='rect' height='30px' width='100px' style={{ marginBottom: '20px' }}/>
        <Skeleton variant='rect' height='30px' width='100px' style={{ marginBottom: '20px' }}/>
        <Skeleton variant='rect' height='30px' width='150px' style={{ marginBottom: '20px' }}/>
        <div style={{ width: '100%', borderBottom: '1px solid #D9D8D8' }}></div>
        <Skeleton variant='rect' height='30px' width='150px' style={{ marginBottom: '20px', marginTop: '20px' }}/>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <div style={{ width: '40%' }}>
                <Skeleton variant='rect' height='56px' width='200px'/>
            </div>
            <div style={{ display: 'flex', width: '40%', justifyContent: 'end', alignItems: 'center' }}>
                <Skeleton variant='rect' height='26px' width='65px'/>
                <Skeleton variant='rect' height='32px' width='90px' style={{ marginLeft: '12px' }}/>
            </div>
        </div>
        <div style={{ marginTop: '30px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Skeleton variant='rect' height='36px' width='90px' style={{ marginRight: '20px' }}/>
            <Skeleton variant='rect' height='36px' width='240px' style={{ marginRight: '20px' }}/>
        </div>
    </div>
}

export default BillingLoader
