import React from 'react'

/**
 *
 */
export type Account = {
    account_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    branding_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    company: string;
    name: string;
    partner_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    type: 'nxt' | 'classic';
    void_cluster_id: 9; // eslint-disable-line @typescript-eslint/naming-convention
}

/**
 *
 */
export type Extension = {
    caller_id: string; // eslint-disable-line @typescript-eslint/naming-convention
    extension: number;
    extension_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    extension_name: string; // eslint-disable-line @typescript-eslint/naming-convention
    is_default: 1; // eslint-disable-line @typescript-eslint/naming-convention
    is_virtual: 0; // eslint-disable-line @typescript-eslint/naming-convention
    phone_number: { // eslint-disable-line @typescript-eslint/naming-convention
        [number: string]: {
            features: string[];
            name: string;
        }
    }
}

/**
 *
 */
export type UserInfo = {
    account: Account;
    account_id: string; // eslint-disable-line @typescript-eslint/naming-convention
    avatar_url: string; // eslint-disable-line @typescript-eslint/naming-convention
    company: string;
    cp_session_token: null | string; // eslint-disable-line @typescript-eslint/naming-convention
    email: string;
    extension: Extension;
    extension_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    extensions: Extension[];
    features: string[];
    first_name: string; // eslint-disable-line @typescript-eslint/naming-convention
    has_active_ports: boolean; // eslint-disable-line @typescript-eslint/naming-convention
    last_name: string; // eslint-disable-line @typescript-eslint/naming-convention
    phone_numbers: string[]; // eslint-disable-line @typescript-eslint/naming-convention
    role: 'account' | 'extension';
    true_account_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    user_account_type: string; // eslint-disable-line @typescript-eslint/naming-convention
    user_default_extension_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    user_id: number; // eslint-disable-line @typescript-eslint/naming-convention
    user_tiered: boolean; // eslint-disable-line @typescript-eslint/naming-convention
    voip_cluster_id: number; // eslint-disable-line @typescript-eslint/naming-convention
}

interface props {
    userInfo: UserInfo;
    children: React.ReactNode;
}

/**
 *
 */
export const UserContext = React.createContext<UserInfo>(null)

/**
 *
 */
const UserProvider = (props: props) => {
    // TODO: currently the data originates from the parent component of this (the Communicator component)
    // we may want that data to originate / be fetched within this component.

    return (
        <UserContext.Provider
            value={props.userInfo}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider
