import React, { useState, useContext, useRef } from 'react'
import SidebarV2Link from './SidebarV2Link'
import { HelpIcon } from 'svg-icons'
import HelpPopup from '../../../../apps/communicator/src/nav/HelpPopup'
import { UserContext } from 'providers'

/**
 * Help Button SidebarV2Link.
 */
const SidebarV2HelpLink = () => {
    const userInfo = useContext(UserContext)

    const [helpPopupOpen, setHelpPopupOpen] = useState<boolean>(false)

    const helpLinkRef = useRef<HTMLDivElement | null>(null)

    return (
        <div ref={helpLinkRef}>
            <SidebarV2Link
                active={helpPopupOpen}
                icon={<HelpIcon />}
                title={'Help'}
                onClick={() => setHelpPopupOpen(!helpPopupOpen)}
            />
            <HelpPopup
                onClose = {() => setHelpPopupOpen(false)}
                display = {helpPopupOpen}
                anchorRef = {helpLinkRef}
                extension = {userInfo.extension}
            />
        </div>
    )
}

export default SidebarV2HelpLink
