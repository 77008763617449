import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Icon } from 'foundations-library/components'
import { IconNameType } from 'foundations-library/types'

const useStyles = makeStyles(theme => ({
    articleContainer: {
        '& .article': {
            columnGap: '15px',
            padding: '15px',
            borderRadius: '10px',
            border: `1px solid ${theme['foundations-library'].colors.neutral[100]}`,
            height: '108px'
        }
    },
    title: {
        height: '46px',
        maxWidth: '255px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    text: {

        display: 'flex',
        textAlign: 'left',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    }
}))

/**
 *
 */
export type ItemType = {
    title: string
    icon?: IconNameType,
    onRedirect?(): any,
    'data-testid'?: string
}

const isValidLink = (link) => {
    try {
        const url = new URL(link)
        return url.protocol === 'http:' || url.protocol === 'https:'
    } catch (error) {
        return false
    }
}

const IconComponent = ({ icon, 'data-testid': dataTestId }: { icon: IconNameType, 'data-testid'?: string }) => {
    const linkRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/i
    if (linkRegex.test(icon) && isValidLink(icon)) {
        return <img src={icon} height={36} width={36} data-testid={dataTestId}/>
    }
    return <Icon name={icon} size={36} color='neutral-900' data-testid={dataTestId} />
}

/**
 *
 */
const Article = ({
    title = '',
    onRedirect,
    icon = 'cellular',
    'data-testid': dataTestId
}: ItemType): JSX.Element => {
    const styles = useStyles()

    const handleClick = () => onRedirect && onRedirect()

    return (
        <Grid key={title} item xs={12} sm={6} md={4} container direction='row' wrap='nowrap' className={styles.articleContainer} data-testid={dataTestId}>
            <Grid container wrap='nowrap' className='article'>
                <IconComponent icon={icon} data-testid={`${dataTestId}-icon`}/>
                <div className={styles.text}>
                    <Typography variant='subtitle1' className={styles.title} data-testid={`${dataTestId}-title`}>{title}</Typography>
                    <Button color='primary' variant='text' iconLeft={false} icon='open-in-new-alt' onClick={handleClick} data-testid={`${dataTestId}-more-btn`}>Learn more</Button>
                </div>
                {/* <Grid direction='column' className={styles.text}>
                </Grid> */}
            </Grid>
        </Grid>
    )
}

export default Article
