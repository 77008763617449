import React from 'react'
// import { makeStyles } from '@material-ui/core'
import { Switch, Route, Redirect } from 'react-router-dom'
import List from './List'
import Show from './Show'
// import Section from './Section'

// const useStyles = makeStyles(() => ({
// }))

interface PropsInterface {
    routeProps: any;
}

/**
 *
 */
const App = ({
    routeProps
}: PropsInterface): JSX.Element => {
    // const styles = useStyles()
    const basePath = routeProps?.match?.path

    return <div data-testid="widgets-app">
        <Switch>
            {/* eslint-disable-next-line react/prop-types */}
            <Route path={`${basePath}/:sectionSlug/:articleSlug`} render={() => <Show />} />
            <Route path={`${basePath}/:sectionSlug`} render={() => <List />} />
            <Route path={`${basePath}`} render={() => <List />} />
            <Route path='' render={() => <Redirect to={basePath} />} />
        </Switch>
    </div>
}

export default App
