import React, { useContext } from 'react'
import NavLink from 'nav-link'
import { NavigationContext } from '../NavigationContext.ts'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    defaultPageNotFound: {
        textAlign: 'center',
        padding: '1em'
    }
}))

/**
 * This component is shown in the content area when the user visits
 * a URL path that does not match any nav item, nor the base path,
 * by default (when not overridden by NavConfig settings)
 */
const DefaultPageNotFoundComponent = (): JSX.Element => {
    const classes = useStyles()
    const { getInitialNavItem } = useContext(NavigationContext)

    const initialNavItem = getInitialNavItem()

    return (
        <div className={classes.defaultPageNotFound}>
            <h4> 404 | Page Not Found </h4>
            <p>Check if the link you entered is spelled correctly.</p>

            <NavLink navItemId={initialNavItem.id}>
                Return home
            </NavLink>
        </div>
    )
}

export default DefaultPageNotFoundComponent
