/* eslint-disable react/prop-types */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PDCMarkdown from 'pdc-markdown'
import { TextField } from 'foundations-library/components'

const useStyles = makeStyles(theme => ({
    searchContainer: {
        margin: 'auto'
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem'
    }
}))

function searchDetails () {
    const searchTerm = document.querySelector('input').value.toLowerCase()
    const details = document.querySelectorAll('details')

    details.forEach(detail => {
        const summary = detail.querySelector('summary').textContent.toLowerCase()
        if (summary.includes(searchTerm)) {
            detail.style.display = '' // Show matching FAQ
        } else {
            detail.style.display = 'none' // Hide non-matching FAQ
        }
    })
}
// commenting this out I until I get it work better
// const searchFAQs2 = () => {
//   const searchTerm = document.querySelector('input').value.toLowerCase();

//   // Select all elements that may have text content to be searched
//   const elements = document.querySelectorAll('h1, h2, h3, h4, h5, h6, p, li, summary, details, td, th, tr');

//   // Loop through all elements and check if they match the search term
//   elements.forEach(element => {
//     const textContent = element?.textContent.toLowerCase();
//     const matchesSearchTerm = textContent.includes(searchTerm);

//     // If the element matches the search term, show it and its parent if necessary
//     if (matchesSearchTerm) {
//       element.style.display = ''; // Show matching element
//       const parentDetails = element.closest('details');
//       if (parentDetails) {
//         parentDetails.style.display = ''; // Ensure parent <details> is shown if a child matches
//       }
//     } else {
//       // Hide the element only if it doesn't match and it's not within a parent that matches
//       let parentVisible = false;

//       // Check if the element is within any parent that matches the search term
//       const parents = element.closest('details, div, section, article, header, footer, tr, td'); // Update with more generic parent containers
//       if (parents) {
//         // Check if any parent matches
//         parentVisible = Array.from(parents.children).some(child => {
//           return child.textContent.toLowerCase().includes(searchTerm);
//         });
//       }

//       // If the element and its parents do not match, hide it
//       if (!matchesSearchTerm && !parentVisible) {
//         element.style.display = 'none'; // Hide non-matching element
//       }
//     }
//   });
// }

/**
 *
 */
const TabMarkdown = (props) => {
    const classes = useStyles()

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={8} lg={6} className={classes.searchContainer}>
                {props.searchable && <TextField leadingIcon='search' placeholder='Type your question here...' className='search-input' onChange={searchDetails} />}
            </Grid>
            <Grid item xs={12} className={`${classes.topContainer}`}>
                <PDCMarkdown styleOverrides={props.styleOverrides}>
                    {props.children}
                </PDCMarkdown>
            </Grid>
        </Grid>
    )
}

export default TabMarkdown
