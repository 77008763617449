import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    settingsWrapper: {
        padding: '25px',
        flexGrow: 1
    }
}))

interface Props {
    children: React.ReactNode;
}

/**
 *
 */
const SettingsWrapper = (props: Props) => {
    const classes = useStyles()

    return (
        <div className={classes.settingsWrapper}>
            {props.children}
        </div>
    )
}

export default SettingsWrapper
