import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory, Redirect } from 'react-router-dom'
import { NavItem } from '../Navigation'
import { NavigationContext } from '../NavigationContext'
import DefaultPageNotFoundComponent from './DefaultPageNotFoundComponent'
import SupportCenter from 'widgets'

/**
 * Renders the necessary react-router switch and routes for a given NavConfig.
 * This should be used by the the Navigation UI components where the current tab should be displayed.
 */
const NavRoutes = (): JSX.Element => {
    const history = useHistory()
    const {
        basePath,
        getInitialNavItem,
        isOnBasePath,
        getNavItems,
        getPathToNavItem,
        getNavItemComponentType,
        getInvalidPathNavItem
    } = useContext(NavigationContext)

    const renderNavItemRoute = (navItem: NavItem): JSX.Element | null => {
        const ComponentType = getNavItemComponentType(navItem)

        if (ComponentType) { // Ultimately if there is a component we need a route to reach it
            const currentItemPath = getPathToNavItem(navItem.id)
            if (currentItemPath) {
                const componentProps = navItem.componentProps || {}
                return (
                    <Route
                        key={navItem.id}
                        path={currentItemPath}

                        /* TODO: dont pass routeProps/routerProps, use either nav context helpers or something like useRouteMatch() in the child components */
                        render={props => <ComponentType routerProps={{ ...props }} {...componentProps} />}
                    />
                )
            }
        } else {
            return null
        }
    }

    const renderRoutesRecursive = (routes: JSX.Element[], navItem) => {
        const route = renderNavItemRoute(navItem)
        if (route) {
            routes.push(route)
        }
        (navItem.children || []).forEach(subNavItem => renderRoutesRecursive(routes, subNavItem))
    }

    useEffect(() => {
        if (isOnBasePath() || window.location.pathname === '/') {
            const initialNavItem = getInitialNavItem()
            const initialPath = initialNavItem ? getPathToNavItem(initialNavItem.id) : null
            if (initialPath) {
                history.push(initialPath)
            }
        }
    }, [])

    // Create one flat array of all react router routes (for the entire nav hierarchy)
    const routes: JSX.Element[] = []
    const topLevelNavItems = getNavItems()
    topLevelNavItems.forEach(navItem => renderRoutesRecursive(routes, navItem))

    return (
        <Switch>
            {routes}

            {/** TODO: come up with a more general approach of defining the direct routes (routes without extension/base url) */}
            <Route
                key="bill-and-pay-direct-route"
                path="/bill-and-pay"
                render={() => <Redirect to={`${basePath}/bill-and-pay`} />}
            />

            <Route
                key="support-center"
                path="/support-center"
                render={props => <SupportCenter routeProps={{ ...props }} />}
            />

            <Route
                key="support-center-with-extension"
                path={`${basePath}/support-center`}
                render={props => <SupportCenter routeProps={{ ...props }} />}
            />

            <Route
                path="*"
                render={() => {
                    console.error(`NavRoutes: user has navigated to an invalid path: ${window.location.pathname}`)
                    const invalidPathNavItem = getInvalidPathNavItem()
                    if (invalidPathNavItem) {
                        const path = getPathToNavItem(invalidPathNavItem.id)
                        console.error(`NavRoutes: redirecting to the configured invalid path nav item: ${path}`)
                        return <Redirect to={path} />
                    } else {
                        console.error('NavRoutes: no invalid path nav item configured so showing DefaultPageNotFoundComponent instead.')
                        return <DefaultPageNotFoundComponent />
                    }
                }}
            />
        </Switch>
    )
}

export default NavRoutes
