<template>
  <div class="mp3-recorder">
    <div v-if="!audioUrl" class="d-flex align-items-center">
      <span @click="toggleRecord" class="cursor-pointer">
        <v-icon size="40">{{ recordingIcon }}</v-icon>
      </span>
      <div v-if="isRecording" class="ms-2 recording-text font-weight-bold">{{ recordingText }}</div>
    </div>
    <div v-else class="d-flex align-items-center">
      <AudioPlayer :src="audioUrl"/>
      <span @click="discardAudio" class="cursor-pointer">
        <v-icon color="error" size="24">$vuetify.icons.trash_circled</v-icon>
      </span>
    </div>
  </div>
</template>

<script>
import l from '../../libs/lang';
import AudioPlayer from './AudioPlayer.vue';
import MicRecorder from 'mic-recorder-to-mp3';

export default {
  components: {
    AudioPlayer
  },
  props: ['src'],
  data() {
    return {
      audioUrl: this.$props.src,
      isRecording: false,
      interval: null,
      dots: 1,
      Mp3Recorder: new MicRecorder({ bitRate: 128 })
    };
  },
  computed: {
    recordingText() {
      return l.t('app.recording', 'Recording') + '.'.repeat(this.dots);
    },
    recordingIcon() {
      return this.$data.isRecording ? '$vuetify.icons.stop_circle' : '$vuetify.icons.microphone';
    }
  },
  methods: {
    async toggleRecord() {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (err) {
        return this.$emit('close');
      }
      if (!this.isRecording) {
        await this.startRecording();
      } else {
        await this.stopRecording();
      }
    },
    async startRecording() {
      try {
        await this.Mp3Recorder.start();
        this.$data.isRecording = true;
        this.$data.interval = setInterval(this.updateDots, 300);
      } catch (err) {
        console.error(err);
      }
    },
    async stopRecording() {
      try {
        this.$data.isRecording = false;
        clearInterval(this.interval);
        const [buffer, blob] = await this.Mp3Recorder.stop().getMp3();
        this.$data.audioUrl = URL.createObjectURL(blob);
        const file = new File(buffer, `recording_${Date.now()}.mp3`, {
          type: blob.type,
          lastModified: Date.now()
        });
        this.$emit('update', file);
      } catch (err) {
        console.error(err);
      }
    },
    updateDots() {
      this.$data.dots = (this.dots % 3) + 1;
    },
    discardAudio() {
      this.$data.audioUrl = null;
      this.$emit('update', null);
    }
  },
  beforeDestroy() {
    if (this.$data.isRecording) this.stopRecording();
  }
};
</script>

<style scoped lang="scss">
.mp3-recorder {
  width: 100%;
  max-width: 250px;
  margin-top: 6px;
  > div {
    gap: 20px;
  }
}
.recording-text {
  color: #db5706;
}
</style>
