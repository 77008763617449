import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton } from 'foundations-library/components'
import { EsimContext } from 'providers'
import Title from './../Title'
import { TypographyVariantType } from 'foundations-library/types'
import { formatDate, formatPrice, formatBillingPeriod } from 'formatters'

const useStyles = makeStyles((theme) => {
    const { colors, typography } = theme['foundations-library']
    return {
        table: {
            '& td, & th': {
                padding: '0 10px'
            },
            '& thead tr': {
                height: '34px',
                background: colors.secondary[50],
                '& th': {
                    color: colors.secondary[700],
                    ...typography.body3
                },
                '&:first-child': {
                    '& th:first-child': {
                        borderRadius: '6px 0 0 6px'
                    },
                    '& th:last-child': {
                        borderRadius: '0 6px 6px 0'
                    }
                }
            },
            '& tbody tr': {
                height: '60px',
                '& td': {
                    ...typography.body3
                }
            },
            '& th:last-child': {
                textAlign: 'right'
            },
            '& td:last-child': {
                textAlign: 'right'
            },
            '& .subtotal': {
                boxShadow: `0px 1px 0px 0px ${colors.neutral[200]} inset`
            }
        },
        root: {
            display: 'flex',
            flexDirection: 'column'
        },
        device: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        }
    }
})

const rowTitle = (title, subtitle, variant: TypographyVariantType = 'subtitle3') => {
    return <>
        {title && <Typography variant={variant}>{title}</Typography>}
        {subtitle && <Typography variant='body4' color='neutral-600'>{subtitle}</Typography>}
    </>
}

const groupByCode = (items, codeKey = 'code') => items.reduce((a, c) => {
    if (!Array.isArray(a[c[codeKey]])) a[c[codeKey]] = []
    a[c[codeKey]].push(c)

    return a
}, {})

const getSumOfAmounts = (items) => items.reduce((a, c) => {
    a += c.amount
    return a
}, 0)

type RowType = {
    item: string | JSX.Element,
    qty?: string | number,
    className?: string,
    charges: string | JSX.Element
}

/**
 *
 */
const Index = () => {
    const classes = useStyles()
    const [itemsRows, setItemsRows] = useState<RowType[]>([])
    const [taxTitle, setTaxTitle] = useState<RowType>()
    const [taxesRows, setTaxesRows] = useState<RowType[]>([])
    const [openTaxes, setOpenTaxes] = useState<boolean>(false)
    const [total, setTotal] = useState<RowType>()

    const { orderEsims } = useContext(EsimContext)

    const toggleOpenTaxes = () => {
        setOpenTaxes((v) => !v)
    }
    useEffect(() => {
        const order = orderEsims.data.billing
        if (order) {
            const data: RowType[] = []

            const itemsGroupedByCode = groupByCode(order.items)
            const discountsGroupedByCode = groupByCode(order.discounts, 'billing_code')

            const itemsCodes = Object.keys(itemsGroupedByCode)
            for (let i = 0; i < itemsCodes.length; i++) {
                const code = itemsCodes[i]
                const itemsRow = {
                    item: rowTitle(itemsGroupedByCode[code][i].description, `${formatBillingPeriod(order.billing_period)} recurring charge`, 'subtitle3'),
                    qty: itemsGroupedByCode[code].length,
                    charges: formatPrice(getSumOfAmounts(itemsGroupedByCode[code]))
                }
                data.push(itemsRow)

                if (discountsGroupedByCode[code] && itemsGroupedByCode[code].length) {
                    const discountsRow = {
                        item: rowTitle(
                            'Discount',
                            `Account free trial period ${formatDate(discountsGroupedByCode[code][0].expires, { day: '2-digit', month: '2-digit' })}`,
                            'body3'
                        ),
                        charges: formatPrice(getSumOfAmounts(discountsGroupedByCode[code]) * itemsGroupedByCode[code].length)
                    }
                    data.push(discountsRow)
                }
            }
            const subtotal = {
                item: <Typography variant='body3heavy'>Subtotal</Typography>,
                className: 'subtotal',
                charges: <Typography variant='body3heavy'>{formatPrice(order.subtotal)}</Typography>
            }

            data.push(subtotal)
            setItemsRows(data)

            setTaxTitle({
                item: <Typography variant='body3'>Taxes & fees</Typography>,
                charges: formatPrice(getSumOfAmounts(orderEsims.data.billing.taxes) || 0)
            })
            const taxRows: RowType[] = []
            for (const tax of order.taxes) {
                taxRows.push({
                    item: <Typography variant='body3' key={tax.description}>{tax.description}</Typography>,
                    charges: formatPrice(tax.amount)
                })
            }
            setTaxesRows(taxRows)

            setTotal({
                // item: rowTitle('Total', `**Prorated billing begins after ${formatDate(order.billing_exp, { day: '2-digit', month: '2-digit' })}`, 'body3heavy'),
                item: <Typography variant='body3heavy'>Total</Typography>,
                charges: formatPrice(getSumOfAmounts(order.taxes) + order.subtotal)
            })
        }
    }, [orderEsims.data])

    return (
        <div className={classes.root}>
            <Title title='Review the following details before placing your order' />
            <table className={classes.table} data-testid='table'>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th style={{ width: '100%' }}></th>
                        <th>Qty</th>
                        <th>Charges</th>
                    </tr>
                </thead>
                <tbody>
                    {itemsRows.map((row, i) => (<tr key={`row-${i}`} className={row.className}>
                        <td colSpan={2}>{row.item}</td>
                        <td>{row.qty}</td>
                        <td>{row.charges}</td>
                    </tr>))}
                    {taxTitle && <tr>
                        <td colSpan={2}>{taxTitle.item}</td>
                        <td></td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                            {taxesRows.length > 0 && <IconButton key={`icon-${openTaxes}`} color='neutral' variant='icon' onClick={toggleOpenTaxes} name={openTaxes ? 'up-chevron' : 'down-chevron'} />}&nbsp;
                            {taxTitle.charges}
                        </td>
                    </tr>}

                    {taxesRows.length > 1 && openTaxes && <>
                        {taxesRows.map((row, i) => (<tr key={`tax-row-${i}`} className={row.className}>
                            <td></td>
                            <td>{row.item}</td>
                            <td>{row.qty}</td>
                            <td>{row.charges}</td>
                        </tr>))}
                    </>}
                    {total && <tr>
                        <td colSpan={2}>{total.item}</td>
                        <td>{total.qty}</td>
                        <td>{total.charges}</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
}

export default Index
