import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    badge: {
        backgroundColor: theme.palette.error.main,
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

/**
 * Design component for displaying any form of badge along SidebarV1.
 */
const SidebarV1Badge = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
    const classes = useStyles()

    if (!children) {
        return null
    }

    let displayValue = children
    if (typeof children === 'number') {
        if (children <= 0) {
            return null
        } else if (children > 99) {
            displayValue = '99+' // Because SideBarV1Badge can only fit about 3 digits
        }
    }

    return (
        <span className={classes.badge}>
            <small>{displayValue}</small>
        </span>
    )
}

export default SidebarV1Badge
