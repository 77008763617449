import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { Typography, Icon, Alert } from 'foundations-library/components'
import { EsimContext } from 'providers'
import { details } from './../../../utils/objects'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '0px 0px 10px 10px'
        },
        manualInstallationText: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '14px 20px',
            cursor: 'pointer',
            '& .menu-expanded path': {
                fill: verbalColors['neutral-900']
            }
        },
        manualInstallationPoints: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            padding: '10px 20px 20px'
        },
        manualInstallationPointContainer: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px'
        },
        manualInstallationPoint: {
            display: 'flex',
            columnGap: '4px',
            position: 'relative',
            '& .copy': {
                position: 'absolute',
                top: 0,
                left: 0,
                cursor: 'pointer'
            },
            '& .copy path': {
                stroke: verbalColors['neutral-900']
            }
        },
        manualInstallationPointText: {
            wordBreak: 'break-word'
        },
        manualInstallationPointTextContainer: {
            paddingLeft: '32px'
        }
    }
})

/**
 *
 */
const ManualInstallation = ({ data }: { data: Record<string, any> }) => {
    const classes = useStyles()
    const [hasTextCopied, setHasTextCopied] = useState(false)
    const { refreshQRCode } = useContext(EsimContext)
    const { installation: { manual: manaualInstallation } } = details

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setHasTextCopied(true)
        }, () => {
            console.error('Error: Content failed to copy to clipboard')
        })
    }

    return (
        <div className={classes.root}>
            {/* <div className={classes.manualInstallationText} onClick={() => setOpenManual(openManual => !openManual)}>
                <Typography variant='subtitle1' color='neutral-900'>{manaualInstallation.title}</Typography>
                <IconButton size='medium' className='menu-expanded' name='menu-expanded' variant='icon' color='neutral' onMouseUp={() => setOpenManual(openManual => !openManual)} />
            </div> */}
            <div className={classes.manualInstallationPoints}>
                <Typography variant='body3' color='neutral-700'>{manaualInstallation.subtitle}</Typography>
                {hasTextCopied && <Alert level='success' onClose={() => setHasTextCopied(false)} autocloseAfter={2000}>{details.copied}</Alert>}
                <div className={classes.manualInstallationPointContainer}>
                    {
                        manaualInstallation.points.map(({ point, info }, index) => {
                            const esim = refreshQRCode.data?.esim || data.esim
                            let text = esim?.activation_code
                            const activationCodeSplit = esim?.activation_code?.split('$')
                            if (index !== 0) { // show the full code for the first point
                                text = activationCodeSplit[index]
                            }
                            // const text = index === 2 ? (refreshQRCode.data?.esim?.activation_code || (!refreshQRCode.isLoading ? data.esim.activation_code : details.installation.manual.generating)) : info
                            return (
                                <div key={point} className={classes.manualInstallationPoint} data-testid='installationPoint'>
                                    <Icon name='copy' size='medium' color='neutral' className='copy' onMouseUp={copyText.bind(this, text)} />
                                    <div className={classes.manualInstallationPointTextContainer}>
                                        <Typography className={classes.manualInstallationPointText} variant='subtitle2' color='neutral-900'>{point}</Typography>
                                        <Typography className={classes.manualInstallationPointText} variant='subtitle2' color='primary-500'>{text}</Typography>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ManualInstallation
