import React, { useState, useEffect, useContext, useMemo } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Popover from '@material-ui/core/Popover'
import Typography from 'typography'
import { readableAppVersion } from 'phonecom'
import api from 'users-app/src/util/api_v5'
import { makeStyles } from '@material-ui/core'
import { formatPhoneNumber } from 'phone-numbers'
import { UserContext } from 'providers'
import { logout } from 'phonecom'
import { NavigationContext } from '../../NavigationContext'
import NavLink from 'nav-link'
import {
    CaretIcon,
    SettingsIcon,
    HelpIcon,
    KeypadIcon,
    SignOutIcon
} from './TopbarIcons'
import { get_phoenix_session } from 'phoenix-session-helpers'

type ContactInfoType = {
    number: string,
    name: string,
    email: string
}

const useStyles = makeStyles(theme => ({
    userProfileContainer: {
        padding: '30px 40px 20px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '22px',
        alignSelf: 'stretch',
        backgroundColor: '#F2F1F1',
        '& $manageProfileText': {
            color: '#30991C !important',
            padding: '5px 0'
        }
    },
    userInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        '& $userNameText, & $smallText': {
            color: '#1A1A1A !important'
        },
        '& $smallGreyText': {
            color: '#8C8C8C !important'
        }
    },
    userNameText: {
        fontFamily: 'Inter !important',
        fontSize: '16px !important',
        fontStyle: 'normal !important',
        fontWeight: '500 !important',
        lineHeight: '23px !important',
        letterSpacing: '-0.2px !important'
    },
    smallText: {
        fontFamily: 'Inter !important',
        fontSize: '12px !important',
        fontStyle: 'normal !important',
        fontWeight: '500 !important',
        lineHeight: '20px !important',
        letterSpacing: '-0.1px !important'
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px',
        cursor: 'pointer',
        '& .caretIcon path': {
            fill: '#D4C3BE'
        },
        '&:hover .caretIcon path': {
            fill: '#fff'
        }
    },
    avatarSize: {
        width: ({ size }: { size: number }) => `${size}px`,
        height: ({ size }: { size: number }) => `${size}px`,
        fontSize: ({ size }: { size: number }) => `${size / 40}em !important`,
        letterSpacing: ({ size }: { size: number }) => `${size / 40 * -2}px !important`
    },
    avatarTextSmallVariant: {
        fontFamily: 'Inter !important',
        fontSize: '17px!important',
        fontStyle: 'normal!important',
        fontWeight: '500 !important',
        lineHeight: '17px !important',
        letterSpacing: '-2px !important',
        textTransform: 'uppercase !important'
    },
    userProfilePopover: {
        paddingBottom: '10px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        backgroundColor: '#FFF',
        boxShadow: '0px 3px 20px 0px rgba(0, 0, 0, 0.25)'
    },
    userProfileAvatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px'
    },
    popoverItemsContainer: {},
    popoverItem: {
        display: 'flex',
        padding: '14px 12px 14px 40px',
        alignItems: 'center',
        gap: '12px',
        borderBottom: '1px solid rgba(133, 146, 154, 0.25)',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,.02)'
        },
        '& $popoverItemVariant': {
            color: '#404040 !important'
        }
    },
    popoverItemVariant: {
        fontFamily: 'Inter !important',
        fontSize: '14px!important',
        fontStyle: 'normal!important',
        fontWeight: '500 !important',
        lineHeight: '22px !important',
        letterSpacing: '-0.2px !important'
    },
    versionContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '46px',
        '& $versionVariant': {
            color: '#8C8C8C'
        }
    },
    versionVariant: {
        fontFamily: 'Inter !important',
        fontSize: '10px!important',
        fontStyle: 'normal!important',
        fontWeight: '500 !important',
        lineHeight: '17px !important',
        letterSpacing: '-0.1px !important'
    }
}))

/**
 * Avatar showing logged in user and it's popover.
 */
const UserProfile = (): JSX.Element => {
    const userInfo = useContext(UserContext)

    const { goToNavItem } = useContext(NavigationContext)

    const classes = useStyles({ size: 40 })

    const [profilePopoverAnchorEl, setProfilePopoverAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [expressCode, setExpressCode] = React.useState('')

    useEffect(() => {
        api.getExpressServiceCode().then(setExpressCode)
    }, [])

    const userIDs = { 'VoIP ID': window.V5PHONECOM?.voip_id, 'Account ID': userInfo.true_account_id, 'Express service code': expressCode }

    const UserAvatar = ({ info: { url, letters }, size }: { info: { url: string, letters: string }, size: number }) => {
        const classes = useStyles({ size })

        const avatarProps = url
            ? {
                alt: 'User Profile Pic',
                src: url,
                className: classes.avatarSize
            }
            : {
                src: '',
                className: `avatar-letters ${classes.avatarSize} ${classes.avatarTextSmallVariant}`
            }

        return (
            <Avatar {...avatarProps}>
                {letters}
            </Avatar>
        )
    }

    const popoverMenuItems = [
        {
            text: 'My settings',
            icon: <SettingsIcon />,
            onClick: () => goToNavItem('profile')
        },
        {
            text: 'Help center',
            icon: <HelpIcon />,
            onClick: () => window.open('https://www.phone.com/support/', '_blank')
        },
        {
            text: 'Get the mobile app',
            icon: <KeypadIcon />,
            onClick: () => window.open('https://www.phone.com/mobile/', '_blank')
        },
        {
            text: 'Sign out',
            icon: <SignOutIcon />,
            onClick: logout
        }
    ]

    const handleOpenProfilePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProfilePopoverAnchorEl(event.currentTarget)
    }

    const handleCloseProfilePopover = () => {
        setProfilePopoverAnchorEl(null)
    }

    const openProfilePopover = Boolean(profilePopoverAnchorEl)
    const id = openProfilePopover ? 'profile-popover' : undefined

    const avatarLetters = userInfo.account.type === 'classic' ? userInfo.extension.extension_name.charAt(0) : `${userInfo.first_name?.charAt(0).toUpperCase()} ${userInfo.last_name?.charAt(0).toUpperCase()}`

    const contactInfo = useMemo((): ContactInfoType => {
        const type = userInfo.account.type
        const isUser = Boolean(userInfo.user_id)
        const extension = userInfo.extensions.find(ext => ext.extension === userInfo.extension)
        const info: ContactInfoType = {
            number: isUser ? formatPhoneNumber(userInfo.direct_number) : (extension ? `Ext. ${extension.extension}` : ''),
            email: '',
            name: ''
        }
        if (type === 'classic') {
            const sessionUser = (get_phoenix_session()).user
            info.name = userInfo.company || userInfo.account?.company || sessionUser?.account?.contact?.company || '—'
            info.email = sessionUser?.account?.contact?.primary_email || sessionUser?.account?.contact?.alternate_email || userInfo.email || '—'
            info.number = info.number || formatPhoneNumber(sessionUser?.account?.contact?.phone) || '—'
        } else {
            info.name = [userInfo.first_name, userInfo.last_name].filter((x) => x).join(' ')
            info.email = userInfo.email
        }

        return info
    }, [userInfo])

    const initials = useMemo(() => {
        if (userInfo.account.type === 'classic') {
            return userInfo.extension.extension_name.charAt(0)
        }
        return `${userInfo.first_name?.charAt(0).toUpperCase()} ${userInfo.last_name?.charAt(0).toUpperCase()}`
    }, [userInfo])

    return (
        <>
            <div data-testid="user-avatar-container" className={classes.avatarContainer} onClick={handleOpenProfilePopover}>
                <UserAvatar
                    info={{
                        url: userInfo.avatar_url,
                        letters: avatarLetters
                    }}
                    size={40}
                />

                <CaretIcon />
            </div>
            <Popover
                id={id}
                open={openProfilePopover}
                anchorEl={profilePopoverAnchorEl}
                onClose={handleCloseProfilePopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div className={classes.userProfilePopover}>
                    <div className={classes.userProfileContainer}>
                        <div className={classes.userProfileAvatarContainer} onClick={handleCloseProfilePopover}>
                            <NavLink navItemId='profile'>
                                <UserAvatar
                                    info={{
                                        url: userInfo.avatar_url,
                                        letters: `${initials}`
                                    }}
                                    size={80}
                                />
                            </NavLink>

                            {userInfo.account.type === 'nxt' &&
                                <NavLink navItemId='profile'>
                                    Manage profile
                                </NavLink>
                            }
                        </div>
                        {contactInfo && <div className={classes.userInfoContainer}>
                            <div>
                                <Typography className={classes.userNameText}>
                                    {contactInfo.name}
                                </Typography>
                                <Typography className={classes.smallText}>
                                    {contactInfo.email}
                                </Typography>
                                <Typography className={classes.smallText}>
                                    {contactInfo.number}
                                </Typography>
                            </div>
                            <div>
                                {
                                    Object.keys(userIDs).map(param => userIDs[param] && <Typography key={param} className={classes.smallGreyText}>{param}: {userIDs[param]}</Typography>)
                                }
                            </div>
                        </div>}
                    </div>
                    <div className={classes.popoverItemsContainer} onClick={handleCloseProfilePopover}>
                        {popoverMenuItems.map(popoverMenuItem => (
                            <div key={popoverMenuItem.text} className={classes.popoverItem} onClick={popoverMenuItem.onClick}>
                                {popoverMenuItem.icon}
                                <Typography className={`${classes.popoverItemVariant}`}>
                                    {popoverMenuItem.text}
                                </Typography>
                            </div>
                        ))}
                    </div>
                    <div className={classes.versionContainer}>
                        <Typography className={classes.versionVariant}>Version: {readableAppVersion()}</Typography>
                    </div>
                </div>
            </Popover>
        </>
    )
}

export default UserProfile
