import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import IdentityPopup from './IdentityPopup'
import Typography from 'typography'
import { FeatureEventsContext, ThemeContext, UserContext, UserInfo, ScreenSizeContext } from 'providers'
import { formatPhoneNumber } from 'phone-numbers'

const useStyles = makeStyles(styles)

/**
 * Displays information about the logged in user.
 */
const IdentityArea = (): JSX.Element => {
    const userInfo = useContext<UserInfo>(UserContext)

    const [showPopup, setShowPopup] = React.useState(false)
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const screenSize = useContext(ScreenSizeContext)
    const theme = useContext(ThemeContext)

    const isUser = Boolean(userInfo.user_id)
    const number = isUser ? formatPhoneNumber(userInfo.direct_number) : (userInfo.extension ? `Ext. ${userInfo.extension.extension}` : '')

    return (
        <div className={classes.identityAreaWrapper}>
            <div
                className = 'identity'
                onClick = {() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-identity-area-click' })
                    setShowPopup(true)
                }}
                data-testid = 'identity-element'
            >
                <div className={classes.brandLogo}>
                    <img src={theme?.icons?.identityAreaLogo} alt='brand logo' style={{ width: '100%', height: '100%' }}/>
                </div>

                {!screenSize.mobile &&
                    <div style={{ overflow: 'hidden', paddingRight: '5px' }}>
                        <Typography variant='identityText' className={classes.identityText} color='secondary' data-test-id='identity-name'>
                            {userInfo.account.name}
                        </Typography>

                        <Typography variant='identityText' color='secondary'>
                            {userInfo.company}
                        </Typography>

                        {userInfo.phone_numbers.length > 0 &&
                            <Typography variant='identityText' color='secondary'>
                                {formatPhoneNumber(number)}
                            </Typography>
                        }
                    </div>
                }
            </div>
            {showPopup
                ? <IdentityPopup
                    open
                    onClose = {() => setShowPopup(false)}
                    userInfo = {userInfo}
                />
                : null
            }
        </div>
    )
}

export default IdentityArea
