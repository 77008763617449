/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable jsdoc/require-description */
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { Teammate } from 'models'
import { formatPhoneNumber } from 'phone-numbers'
import { ControlledError } from 'errors'

const REACT_APP_USER_API_URL = process.env.REACT_APP_USER_API_URL || 'http://default-url-for-testing'
const PAGE_LIMIT = 100

/**
 *
 */
export class Teammates {
    items: Map<number, Teammate> = new Map()
    hasMore = true
    apiFilter = ''

    /**
     *
     */
    get offset () {
        return this.items?.size || 0
    }

    /**
     *
     */
    async load (): Promise<Map<number, Teammate>> {
        if (!this.hasMore) return this.items

        const isNxt = PhoneComUser.isUserTiered()
        try {
            const items = isNxt ? await this.loadNxt() : await this.loadClassic()
            if (Array.isArray(items)) {
                items.forEach(t => {
                    t = isNxt ? Teammate.fromJson(t) : Teammate.fromJsonClassic(t)
                    this.items.set(t.id, t)
                })
                this.hasMore = items.length === PAGE_LIMIT
                if (this.hasMore) this.load() // on purpose sync call
            }
        } catch (err) {
            throw new ControlledError('Error while loading your teammates')
        }
        return this.items
    }

    /**
     *
     */
    async loadNxt (): Promise<Record<string, any>> {
        const account_id: number = PhoneComUser.getAPIAccountId()

        const api_base = REACT_APP_USER_API_URL || ''
        if (this.apiFilter.length > 0) {
            this.apiFilter = this.apiFilter.replace(' ', '%20')
        }
        let requestUrl = `${api_base}/voip/${account_id}/users?page_size=${PAGE_LIMIT}&offset=${this.offset}&order_by=status_asc_first_name_asc.asc&include=extension,plan,devices,numbers`
        if (this.apiFilter.length > 0) requestUrl += `&name=${this.apiFilter}`
        const res = await ajax.get(requestUrl)
        if (res.message === 'Network Error') {
            console.log('teammates error: ', res)

            return []
        }

        return res?.data?.data
    }

    /**
     *
     */
    async loadClassic (): Promise<Record<string, any>> {
        const account_id: number = PhoneComUser.getAPIAccountId()
        const baseUrl = PhoneComUser.getv4ApiRoot()
        const url = `${baseUrl}/accounts/${account_id}/extensions?limit=${PAGE_LIMIT}&offset=${this.offset}`

        const res = await ajax.get(url)
        if (res.message === 'Network Error') {
            console.log('teammates error: ', res)

            return []
        }

        return res?.data?.items
    }

    /**
     * @param t
     */
    add (t: Teammate): void {
        this.items.set(t.id, t)
    }

    /**
     * @param {number} t_id
     */
    remove (t_id: number): void {
        this.items.delete(t_id)
    }

    /**
     * @param {string} value
     */
    filter (value: string): Teammates {
        const filtered = new Teammates()
        this.items.forEach((t, id) => {
            t.displayName()
            value = value.toString()
            const name: string = t.displayName().toLowerCase()
            const lowerCaseValue: string = value.toLowerCase()
            if (t.extension.number.toString().includes(value) ||
                t.numbers.has(formatPhoneNumber(value)) ||
                name.includes(lowerCaseValue)) { filtered.add(t) }
        })
        return filtered
    }

    /**
     *
     */
    contains (ext: number): boolean {
        let found = false
        this.items.forEach(t => {
            if (t.extension.extension_number === ext) found = true
        })
        return found
    }

    /**
     *
     */
    find (extId: number): (Teammate | undefined) {
        let item
        this.items.forEach(t => {
            if (t.extension.id === extId) item = t
        })
        return item
    }
}
