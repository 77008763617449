/* eslint-disable @typescript-eslint/naming-convention */
import l from 'lang'
// https://laravel.com/docs/9.x/validation#available-validation-rules

const generateErrorMessage = (defaultMessage, message, field, fieldMessage) => {
    if (message) return message
    if (field && fieldMessage) return fieldMessage
    return defaultMessage
}

// const genericErrorMessage = (field, message) => generateErrorMessage(
//     l.t('app.validation-generic-error-message', 'This field is invalid'),
//     message,
//     field,
//     l.t('app.validation-generic-error-message-named-rule', '{} field is invalid', [field])
// )

/**
 * required
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const required = (field = '', message = '') => (v) => {
    if (!v) {
        return generateErrorMessage(
            l.t('app.field-is-required', 'This field is required'),
            message,
            field,
            l.t('app.named-field-is-required-rule', '{} field is required', [field])
        )
    }

    return true
}

/**
 * checks if value is string
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const string = (field, message) => (v) => {
    if (v && typeof v !== 'string') {
        return generateErrorMessage(
            l.t('app.string-rule', 'This field must be a string'),
            message,
            field,
            l.t('app.named-field-string-rule', '{} field must be a string', [field])
        )
    }

    return true
}

/**
 * checks if value is boolean
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */

/**
 *
 */
const boolean = (field, message) => (v) => {
    if (typeof v !== 'boolean') {
        return generateErrorMessage(
            l.t('app.boolean-rule', 'This field must be a boolean'),
            message,
            field,
            l.t('app.named-field-boolean-rule', '{} field must be a boolean', [field])
        )
    }

    return true
}

/**
 * checks if value is area code
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const area_code = (field, message) => (v) => {
    if (!v) return true
    if (!isNaN(parseFloat(v)) && v >= 200 && v <= 999) return true
    return generateErrorMessage(
        l.t('app.boolean-rule', 'This field must be a 3 digit number greater than 199'),
        message,
        field,
        l.t('app.named-field-boolean-rule', '{} field must be a 3 digit number greater than 199', [field])
    )
}
const areaCode = area_code

/**
 * checks if value is a number
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const number = (field, message) => (v) => {
    if (typeof v !== 'number') {
        return generateErrorMessage(
            l.t('app.number-rule', 'This field must be a number'),
            message,
            field,
            l.t('app.named-field-number-rule', '{} field must be a number', [field])
        )
    }

    return true
}

/**
 * checks if value is an array
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const array = (field, message) => (v) => {
    if (!Array.isArray(v)) {
        return generateErrorMessage(
            l.t('app.array-rule', 'This value must be an array'),
            message,
            field,
            l.t('app.named-array-rule', '{} field value must be an array', [field])
        )
    }

    return true
}

/**
 * checks if value is an object
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */

/**
 *
 */
const object = (field, message) => (v) => {
    const errorMessage = generateErrorMessage(
        l.t('app.object-rule', 'This value must be an object'),
        message,
        field,
        l.t('app.named-object-rule', '{} field value must be an object', [field])
    )
    if (v && typeof v !== 'object') {
        return errorMessage
    }
    if (v === null) {
        return errorMessage
    }
    if (Array.isArray(v)) {
        return errorMessage
    }

    return true
}

/**
 * checks if value is numeric
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const numeric = (field, message) => (v) => {
    const errorMessage = generateErrorMessage(
        l.t('app.numeric-rule', 'This field must have numeric value'),
        message,
        field,
        l.t('app.named-numeric-rule', '{} field musthave numeric value', [field])
    )
    if (isNaN(v)) {
        return errorMessage
    }
    if (v === null) return errorMessage
    if (Array.isArray(v)) return errorMessage

    return true
}

/**
 * checks if value sis less then max
 * @param {number} num The max value.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const max = (num, field, message) => (v) => {
    if (v > num) {
        return generateErrorMessage(
            l.t('app.max-field-rule', 'This field must not be greater than {}', [num]),
            message,
            field,
            l.t('app.named-field-max-rule', 'The {} field must not be greater than {}', [field, num])
        )
    }

    return true
}

/**
 * @param {number} num The min value.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const min = (num, field, message) => (v) => {
    if (v < num) {
        return generateErrorMessage(
            l.t('app.min-field-rule', 'This field must be at least {}', [num]),
            message,
            field,
            l.t('app.named-field-min-rule', 'The {} field must be at least {}', [field, num])
        )
    }

    return true
}
/**
 * @param {number} num The minimal length value.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const minlength = (num, field, message) => (v) => {
    if (v && v.length < num) {
        if (Array.isArray(v)) {
            return generateErrorMessage(
                l.t('app.minlength-array-rule', 'This field must contain at least {} elements', [num]),
                message,
                field,
                l.t('app.named-field-min-length-array-rule', '{} must contain at least {} elements', [field, num])
            )
        }
        return generateErrorMessage(
            l.t('app.minlength-rule', 'This field must contain at least {} characters', [num]),
            message,
            field,
            l.t('app.named-field-min-length-rule', '{} must contain at least {} characters', [field, num])
        )
    }

    return true
}
/**
 * @param {number} num The maximal length value.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const maxlength = (num, field = '', message = '') => (v) => {
    if (v && v.length >= num) {
        if (Array.isArray(v)) {
            return generateErrorMessage(
                l.t('app.max-length-array-rule', 'This field must not contain more than {} element', [num]),
                message,
                field,
                l.t('app.named-field-max-length-array-rule', '{} must not contain more than {} element', [field, num])
            )
        }
        return generateErrorMessage(
            l.t('app.max-length-rule', 'This field must not contain more than {} characters', [num]),
            message,
            field,
            l.t('app.named-field-max-length-rule', '{} must not contain more than {} characters', [field, num])
        )
    }

    return true
}
/**
 * email validation.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const email = (field = '', message = '') => (v) => {
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (v && (!emailRegEx.test(v))) {
        return generateErrorMessage(
            l.t('app.email-rule', 'Invalid email format'),
            message,
            field,
            l.t('app.named-field-email-rule', '{} field must be in correct email format', [field])
        )
    }

    return true
}
/**
 * regex validation
 * @param {number} regex Regex.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const regex = (regex, field, message) => (v) => {
    if (!regex.test(v)) {
        return generateErrorMessage(
            l.t('app.regex-rule', 'This field has invalid pattern'),
            message,
            field,
            l.t('app.named-field-regex-rule', '{} field has invalid pattern', [field])
        )
    }

    return true
}

/**
 * checks if value is in array
 * @param {Array} arr The main array.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const elementOf = (arr, field, message) => (v) => {
    if (!arr.includes(v)) {
        return generateErrorMessage(
            l.t('app.in-rule', 'This value must be in: {}', [arr.join(', ')]),
            message,
            field,
            l.t('app.named-in-rule', '{} field value must be in: {}', [field, arr.join(', ')])
        )
    }

    return true
}

/**
 *
 */
const included_in = (arr, field, message) => (v) => { // in is built in js word
    if (!arr.includes(v)) {
        return generateErrorMessage(
            l.t('app.in-rule', 'This value must be in: {}', [arr.join(', ')]),
            message,
            field,
            l.t('app.named-in-rule', '{} field value must be in: {}', [field, arr.join(', ')])
        )
    }

    return true
}

const includedIn = included_in

/**
 * @param {Array} arr The main array.
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @param {boolean} case_sensitive To check for case senstive.
 * @returns {string | boolean} returned value.
 */
const not_included_in = (arr, field, message, case_sensitive) => (v) => {
    const value = case_sensitive ? v.toLowerCase() : v
    const test_arr = case_sensitive ? arr.map((x) => x.toLowerCase()) : arr
    if (test_arr.includes(value)) {
        return generateErrorMessage(
            l.t('app.not-in-rule', 'This value must not be in: {}', [arr.join(', ')]),
            message,
            field,
            l.t('app.named-not-in-rule', '{} field value must not be in: {}', [field, arr.join(', ')])
        )
    }

    return true
}
const notIncludedIn = not_included_in

/**
 * checks if the value is valid phone number
 * @param {string} field The gotten field.
 * @param {string} message The error message.
 * @returns {string | boolean} returned value.
 */
const phone_number = (field, message) => (v) => {
    if (v) {
        const errorMessage = generateErrorMessage(
            l.t('app.phone-number-rule', 'This field has invalid value'),
            message,
            field,
            l.t('app.named-phone-number-rule', '{} field has invalid value', [field])
        )
        if (typeof v !== 'string') {
            return errorMessage
        }
        const phoneNumRegex = new RegExp('^((\\+[2-9][\\d]{8,15})|(\\+1[\\d]{10}))?$')
        if (!phoneNumRegex.test(v.replace(/[- )(]/g, ''))) {
            return errorMessage
        }
    }

    return true
}

const phoneNumber = phone_number

/**
 *
 */
export {
    array,
    areaCode,
    area_code,
    boolean,
    elementOf,
    email,
    includedIn,
    included_in,
    notIncludedIn,
    not_included_in,
    number,
    numeric,
    max,
    maxlength,
    min,
    minlength,
    object,
    phoneNumber,
    phone_number,
    regex,
    required,
    string
}
