import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SidebarV2 from './SidebarV2/SidebarV2'
import ContentArea from '../CommonUI/ContentArea'
import SidebarV2SubMenu from './SidebarV2/SidebarV2SubMenu'
import TopbarV2 from './TopBarV2/TopbarV2'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        minHeight: 0
    },
    page: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'stretch',
        minHeight: 0
    },
    subMenuContainer: {
        width: 'calc(100% - 78px)',
        flexGrow: 1,
        display: 'flex',
        position: 'relative'
    },
    contentArea: {
        flexGrow: 1,
        overflow: 'auto'
    },
    cornerRoundingVector: {
        flexGrow: 1,
        left: 0,
        top: 0,
        zIndex: 1111,
        position: 'absolute',
        width: 16,
        height: 17
    }
}))

/**
 *
 */
const NavUIV2 = () => {
    const classes = useStyles()

    return (
        <div data-testid="nav-ui-v2" className={classes.root}>
            <TopbarV2 />

            <div className={classes.page}>
                <SidebarV2 />

                <div className={classes.subMenuContainer}>
                    {/* Below SVG rounds the upper left corner improving the visuals of how SidebarV2 joins with TopbarV2 */}
                    <svg
                        className={classes.cornerRoundingVector}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 17"
                        fill="none"
                    >
                        <path d="M8.86915e-05 0H15.5C7.75007 0 0 8 0 17L8.86915e-05 0Z" fill="#021F19"></path>
                    </svg>

                    <SidebarV2SubMenu />

                    <div className={classes.contentArea}>
                        <ContentArea />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavUIV2
