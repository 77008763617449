<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mb-6"
        />
        <SectionTitle v-if="simple_form" data-test-id="media-just-media-title">
          {{ l.t('media-loader.fill-the-form-to-update-all-selected-media', 'Fill the form to update all selected media') }}
        </SectionTitle>
        <w-form @submit="submitted" @changed="may_submit = true" data-discard="true">
            <template v-if="!simple_form">
              <FormInput :label="l.t('app.name', 'Name')">
                <w-text-field v-model="media.name" hide-details='auto' :placeholder="l.t('media.new-greeting-or-music-name', 'Name your new greeting or music')" :rules="[$validation.required()]" data-test-id="media-name-input"/>
              </FormInput>
              <FormInput v-if="$route.name === 'media.create'" :label="l.t('media.type', 'Media type')">
                <w-radio-group
                  v-model="media.type"
                  @change="handleMediaTypeChange"
                  row
                  hide-details='auto'
                >
                  <v-radio
                    :label="l.t('app.greeting', 'Greeting')"
                    value="greeting"
                    data-test-id="media-greeting-radio"
                  ></v-radio>
                  <v-radio
                    :disabled="!!force_type"
                    :label="l.t('app.music-on-hold', 'Music on hold')"
                    value="hold_music"
                    data-test-id="media-hm-radio"
                  ></v-radio>
                </w-radio-group>
              </FormInput>
            </template>
            <FormInput
              v-if="tts_origin_should_be_visible()"
              :label="l.t('media.origin', 'Origin')"
            >
              <w-radio-group
                v-model="media.origin"
                row
                hide-details='auto'
              >
                <v-radio
                  :label="l.t('media.tts', 'Text to Speech')"
                  value="tts"
                  data-test-id="media-tts-radio"
                ></v-radio>
                <v-radio
                 :label="l.t('app.upload-a-file', 'Upload a file')"
                  value="file"
                  data-test-id="media-file-radio"
                ></v-radio>
                <v-radio
                 :label="l.t('app.recorder', 'Recorder')"
                  value="recording"
                  data-test-id="media-recording-radio"
                ></v-radio>
              </w-radio-group>
            </FormInput>
            <template v-if="withPlayer && !simple_form">
              <FormInput :label="l.t('media.existing-recording', 'Existing recording')">
                <Player
                    :url="`/media/${media['id']}/download`"
                    :playing="playing"
                    @status="playing = $event.playing"
                    data-test-id="media-player"
                />
              </FormInput>
            </template>
            <template v-if="media.origin === 'file'">
              <FormInput :label="l.t('app.upload-file', 'Upload file')">
                <w-file-input
                  @change="fileUploaded"
                  :label="l.t('media.media-file', 'Media file')"
                  data-test-id="media-file-input"
                  hide-details="auto"
                ></w-file-input>
              </FormInput>
            </template>
            <template v-else-if="media.origin === 'tts'">
              <FormInput :label="l.t('media.voice', 'Voice')">
                <MediaLanguageSelector
                  v-model="media.tts_voice"
                  hide-details="auto"
                  data-test-id="media-voice-selector"
                />
              </FormInput>
              <FormInput :label="l.t('app.text', 'Text')">
                <w-textarea
                  v-model="media.tts_text"
                  :placeholder="l.t('media.new-greeting-or-music-text', 'What will your greeting say?')"
                  :rules="[$validation.required()]"
                  auto-grow
                  rows="1"
                  row-height="15"
                  hide-details="auto"
                  data-test-id="media-text-input"
                ></w-textarea>
              </FormInput>
            </template>
            <template v-else-if="media.origin === 'recording'">
              <FormInput :label="l.t('media.record-a-message', 'Record a message')">
                <MP3Recorder @update="fileUploaded"/>
              </FormInput>
            </template>
            <template v-if="!simple_form">
              <FormInput v-if="random_position_should_be_visible()" :label="l.t('media.random-start', 'Random start position')">
                <w-switch
                  v-model="media.randomized"
                  :label="media.randomized ? l.t('app.enabled-on', 'On') : l.t('app.enabled-off', 'Off')"
                  hide-details='auto'
                  data-test-id="media-random-start-switch"
                />
              </FormInput>
              <FormInput v-if="!media.id" :label="l.t('media.restrict-to-extension', 'Restrict to extension')">
                <APIAutocomplete
                  :value="media.extension && typeof media.extension === 'object' ? media.extension.id : media.extension"
                  mode="extensions"
                  @input="(v) => media.extension = v"
                  :before_list="[{text: l.t('app.all-extensions', 'All extensions'), value: null}]"
                  data-test-id="media-restict-to-ext-selector"
                />
              </FormInput>
              <FormInput :label="l.t('app.notes', 'Notes')">
                <w-textarea
                  v-model="media.notes"
                  :placeholder="l.t('media.new-greeting-or-music-note', 'Add any notes you think may be helpful…')"
                  auto-grow
                  rows="3"
                  row-height="15"
                  hide-details="auto"
                  data-test-id="media-notes-input"
                ></w-textarea>
              </FormInput>
              <FormInput>
                <w-btn :disabled="!may_submit" type="submit" class="primary mt-5 mx-auto d-flex" data-test-id="media-submit-btn">{{ l.t("app.save", "Save") }}</w-btn>
              </FormInput>
            </template>
            <div v-if="simple_form" class="mt-3">
              <w-btn @click="$emit('back')" type="button" class="info" data-test-id="media-back-btn">{{ l.t('app.back', 'Back') }}</w-btn>
              <w-btn :disabled="!may_submit" type="submit" color="error" class="ml-3" data-test-id="media-continue-btn">{{ l.t('app.continue', 'Continue') }}</w-btn>
            </div>
          </w-form>
    </div>
</template>

<script>
  import {vueComponent, formFileToBase64} from 'helpers';
  import l from '../../libs/lang';
  import Media from '../../models/Media';
  import Player from '../elements/Player.vue';
  import MP3Recorder from '../elements/MP3Recorder.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import SectionTitle from '../elements/SectionTitle.vue';
  import MediaLanguageSelector from '../elements/form/MediaLanguageSelector.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';

  export default {
    props: ['id', '_media', 'withPlayer', 'simple_form', 'force_type'],
    components: {
      Player,
      FormInput,
      MP3Recorder,
      SectionTitle,
      APIAutocomplete,
      MediaLanguageSelector,
    },
    data() {
      return {
        l,
        loading: false,
        alert: null,
        media: null,
        playing: null,
        may_submit: false,
      };
    },
    async created() {
      if (this.$props._media) {
        this.$data.media = this.$props._media;
      } else if (this.$props.id) {
          this.loading = true;
          const media = new Media(this.$session, this.type, vueComponent(this));
          try {
            await media.getItem(this.$props.id);
            this.$data.media = media.item;
          } catch (err) {
            this.alert = media.alert;
          }
          this.loading = false;
      }
      if (this.$props.force_type) this.$data.media.type = this.$props.force_type;
      if (!this.$data.media.id) {
        this.$data.media.tts_voice = 'Kimberly / English (US)';
      }
      if (this.$data.media.extension) {
        if (typeof this.$data.media.extension === 'object') {
          this.$data.media.extension = this.$data.media.extension.id;
        }
      } else {
        this.$data.media.extension = null;
      }
    },
    methods: {
      handleMediaTypeChange(e) {
        if (e === 'hold_music') {
          this.media.origin = 'file';
        }
        this.$forceUpdate();
      },
      async submitted(e) {
        if (e) e.preventDefault();
        const data = this.prepareData();
        if (data.origin === 'tts' && !data.tts_voice) {
          this.$data.alert = {
            level: 'error',
            message: l.t('media.please-choose-tts-voice', 'Please choose a Text To Speech voice.'),
          };
          setTimeout(() => {
            this.$data.alert = null
          }, 5000)
        } if (data.origin === 'file' && !this.$data.media.file && this.$route.name !== 'media.show') {
          this.$data.alert = {
            level: 'error',
            message: l.t('media.please-upload-a-file', 'Please upload a media file.'),
          };
          return setTimeout(() => {
            this.$data.alert = null
          }, 5000)
        }
        if (data.origin === 'recording' && !this.$data.media.file) {
          this.$data.alert = {
            level: 'error',
            message: l.t('media.please-record-a-message', 'Please record a message.'),
          };
          return setTimeout(() => {
            this.$data.alert = null;
          }, 5000)
        }
        if (data) this.$emit('submitted', data);

        return true;
      },
      prepareData() {
        const data = JSON.parse(JSON.stringify(this.$data.media));
        if (data.origin === 'tts') {
          data.filename = null;
          data.file = null;
        } else {
          data.tts_text = null;
          data.tts_voice = null;
        }
        return data;
      },
      async fileUploaded(file) {
        if (file) {
          this.$data.media.filename = file.name;
          this.$data.media.file = await formFileToBase64(file);
        } else {
          this.$data.media.file = null;
        }
      },
      tts_origin_should_be_visible() {
        if (
          this.$data.media.type === 'greeting'
          || this.$props.simple_form
          || (
            this.$data.media.type === 'hold_music'
            && this.$data.media.origin === 'tts'
            && this.$data.media.id
          )
        ) {
          return true;
        }
        return false;
      },
      random_position_should_be_visible() {
        if (
          this.$data.media.type === 'greeting'
          && this.$data.media.origin === 'tts'
        ) {
          return false;
        }

        return true;
      }
    },
  };
</script>
