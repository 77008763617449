import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Loader, Typography } from 'foundations-library/components'

const jss = () => ({
    loader: {
        marginTop: '80px',
        marginBottom: '40px'
    },
    loadingText: {
        marginBottom: '96px',
        textAlign: 'center'
    },
    title: {
        marginBottom: '10px'
    }
})

const useStyles = makeStyles(jss)

type PropsType = {
    title?: string,
    subtitle?: string,
    'data-testid'?: string
}

/**
 *
 */
const DialogLoader = ({ title, subtitle, 'data-testid': dataTestId = 'dialog-loader' }: PropsType): JSX.Element => {
    const styles = useStyles()
    return <div>
        <Loader size={80} className={styles.loader} data-testid={dataTestId} inlineBlock/>
        {
            (title || subtitle) && (
                <div className={styles.loadingText}>
                    <Typography variant='h6' color='neutral-900' className={styles.title} data-testid={`${dataTestId}-title`}>
                        {title}
                    </Typography>
                    <Typography variant='body1' color='neutral-900' data-testid={`${dataTestId}-subtitle`}>{subtitle}</Typography>
                </div>
            )
        }
    </div>
}

export default DialogLoader
