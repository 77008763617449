import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Alert } from 'foundations-library/components'
import { getVerbalColors } from 'helpers'
import { EsimContext } from 'providers'
import Button from './../Button'
import { useEsimStepperContext } from '../../../../../../contexts/EsimStepperContext'
import { useEsimOperationsContext } from 'esim/pages/contexts/EsimOperationsContext'
import EsimOperationsProvider from 'esim/pages/contexts/EsimOperationsContext'
import TeammateProvider from 'providers/src/MyPhoneContact/TeammateProvider'

const useStyles = makeStyles((theme) => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 40px',
            gap: '15px',
            flexWrap: 'wrap',
            borderRadius: '20px',
            background: '#FFF',
            boxShadow: '0px 10px 14px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        eSIMCountContainer: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px'
        },
        selectEsimCount: {
            display: 'flex',
            height: '36px',
            padding: '0px 10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            borderRadius: '25px',
            border: `1px solid ${verbalColors['neutral-200']}`,
            '& .count': {
                width: '40px',
                textAlign: 'center'
            },
            '& .divider': {
                width: '1px',
                height: '100%',
                backgroundColor: verbalColors['neutral-200']
            }
        },
        priceContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '6px',
            flex: '1 0 0'
        },
        price: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: '2px'
        },
        validationError: {
            position: 'absolute',
            bottom: '-35px',
            textAlign: 'center'
        }
    })
})

const MAX_ITEMS_IN_ORDER = 15
let errorTimeout
/**
 *
 */
const SelectEsimCount = (): JSX.Element => {
    const classes = useStyles()
    const { availableUsers } = useEsimOperationsContext()
    const { processOrderEsims, orderEsims } = useContext(EsimContext)
    const { selectedPlan, card, count, setCount, goToNextStep } = useEsimStepperContext()
    const [error, setError] = useState<string>()

    const isPlanSelected = Boolean(selectedPlan.title)

    const handleNextStep = async () => {
        const order = { esimPlanId: selectedPlan.id, count, paymentCard: card! }
        const orderedEsims = await processOrderEsims({ ...order, preview: true })
        if (orderedEsims) goToNextStep()
    }

    useEffect(() => {
        if (error) {
            if (errorTimeout) clearTimeout(errorTimeout)
            errorTimeout = setTimeout(() => setTimeout(() => setError(null), 2000))
        }
    }, [error])

    const onAdd = () => {
        if ((count + 1) > MAX_ITEMS_IN_ORDER) {
            return setError(`You can order max ${MAX_ITEMS_IN_ORDER} items per order`)
        }
        if (!isPlanSelected) {
            return setError('You didn\'t select a plan')
        }

        if (orderEsims.isLoading) {
            return setError('Wait until we process the order...')
        }

        if (count >= availableUsers.length) {
            return setError('You don\'t have users available')
        }

        if ((count + 1) === MAX_ITEMS_IN_ORDER) {
            setError(`You can order max ${MAX_ITEMS_IN_ORDER} items per order`)
        }

        setCount(count => count + 1)
    }

    const onSubstract = () => {
        if (count <= 0) return null

        if (!isPlanSelected) {
            return setError('You didn\'t select a plan')
        }

        if (orderEsims.isLoading) {
            return setError('Wait until we process the order...')
        }

        setCount(count => count - 1)
    }

    return (
        <>
            {orderEsims.error && <Alert level='error' icon={true}>{orderEsims.error}</Alert>}
            <div className={classes.root}>
                <div className={classes.eSIMCountContainer}>
                    <Typography variant='subtitle2' color='neutral-900'>Number of eSIM</Typography>
                    <div className={classes.selectEsimCount}>
                        <IconButton
                            data-testid='substract'
                            name='substract'
                            size='small'
                            variant='icon'
                            color={isPlanSelected ? 'primary' : 'neutral'}
                            disabled={!(count > 0 && isPlanSelected && !orderEsims.isLoading)}
                            onClick={onSubstract}
                        />
                        <div className='divider' />
                        <Typography data-testid='esim-count' variant='body1' color='neutral-200' className='count'>{String(count)}</Typography>
                        <div className='divider' />
                        <IconButton
                            data-testid='add'
                            name='add'
                            size='small'
                            variant='icon'
                            color={isPlanSelected ? 'primary' : 'neutral'}
                            disabled={!(isPlanSelected && !orderEsims.isLoading) || (count >= MAX_ITEMS_IN_ORDER) || (count > availableUsers.length)}
                            onClick={onAdd}
                        />
                    </div>
                    {error && <Typography variant='helperText' color='destructive-500' className={classes.validationError} data-testid='validation-errors'>
                        {error}
                    </Typography>}
                </div>
                <div className={classes.priceContainer}>
                    <div className={classes.price}>
                        <Typography variant='h4' color='neutral-900'>$</Typography>
                        <Typography variant='h2' color='neutral-900'>{((Number(selectedPlan.price) || 0) * count).toFixed(2)}</Typography>
                    </div>
                    <Typography variant='subtitle2' color='neutral-900'>
                        Per month, plus applicable fees
                    </Typography>
                    <Typography variant='subtitle2' color='neutral-500'>
                        Excluded all taxes*
                    </Typography>
                </div>
                <Button size='large' variant='contained' color='primary' iconLeft={false} icon={!orderEsims.isLoading ? 'go' : undefined} disabled={!isPlanSelected || !count || orderEsims.isLoading} isLoading={orderEsims.isLoading} minWidth={'120px'} onClick={handleNextStep}>Continue</Button>
            </div>
        </>
    )
}

/**
 *
 */
const WrappedSelectEsimCount = (props) => <TeammateProvider>
    <EsimOperationsProvider>
        <SelectEsimCount {...props} />
    </EsimOperationsProvider>
</TeammateProvider>

export default WrappedSelectEsimCount
