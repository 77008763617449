import React, { useContext } from 'react'
import { NavItem } from '../Navigation'
import { NavigationContext } from '../NavigationContext'

interface props {
    navItem: NavItem;
    badgeProps?: unknown;
}

/**
 * Renders the badge for a given nav item
 */
const NavItemBadge = (props: props): JSX.Element | null => {
    const { getBadgeComponentType } = useContext(NavigationContext)

    if (!props.navItem.badgeComponentName) {
        return null
    }

    const BadgeComponentType = getBadgeComponentType(props.navItem.badgeComponentName)
    return <BadgeComponentType {...(props.badgeProps || {})} />
}

export default NavItemBadge
