import PhoneComUser from 'phone-com-user'

/**
 *
 */
const processLink = (link: string, history: Record<string, any>, nestedLink = false) => {
    if (!link.startsWith('/')) return window.open(link, '_blank')
    let newLink = link
    // if (nestedLink) {
    //     const path = history.location.pathname.replace(/\/$/, '')
    //     newLink = `${path}${newLink}`
    // } else {
    const extensionId = PhoneComUser.getExtensionId()
    console.log(extensionId)
    if (extensionId) {
        const hrefExtension = `/e${extensionId}`
        if (!newLink.startsWith(hrefExtension) && window.location.href.includes(`${hrefExtension}/`)) {
            newLink = `${hrefExtension}${newLink}`
        }
    }
    // }
    history.push(newLink)
}

/**
 *
 */
export {
    processLink
}
