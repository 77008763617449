import React, { useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Alert } from 'foundations-library/components'
import { EsimContext } from 'providers'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import TerminateSimBtn from './TerminateSimBtn'
import UserForm from './UserForm'
import { getStatus } from './../../../utils/index'
import Button from './../../../components/Button'
import { details } from '../../../utils/objects'
import { ScreenSizeContext } from 'providers'

const useStyles = makeStyles(theme => {
    const smallScreen = (v) => v.mobile || v.tablet

    return {
        root: {
            padding: ({ screenSize }) => smallScreen(screenSize) ? '20px 20px 20px 20px' : '30px 70px 30px 20px',
            display: 'flex',
            flexDirection: ({ screenSize }) => smallScreen(screenSize) ? 'column' : 'row',
            maxWidth: '691px',
            margin: '0 auto',
            columnGap: '20px'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            minWidth: 0,
            flexGrow: 1,
            '& .btn-container': {
                display: 'flex',
                columnGap: '1rem',
                margin: '20 auto'
            },
            '& [data-testid="select-select-toggle"] .ext-number': {
                display: 'none'
            }
        }
    }
})

/**
 *
 */
const AssignedForm = () => {
    const screenSize = useContext(ScreenSizeContext)
    const classes = useStyles({ screenSize })
    const { fetchEsims, updatedEsim, updateEsimHandler } = useContext(EsimContext)
    const { data, setData, selectedUser, selectedPhone, selectedUserData, currentUser } = useEsimOperationsContext()

    const [statusText] = getStatus(data)

    const handleEsimUpdate = async () => {
        const payload = Object.keys(modifiedFields).reduce((acc, key) => {
            if (modifiedFields[key].hasChanged) {
                acc[key] = modifiedFields[key].value
            }
            return acc
        }, {})
        const updatedEsim = await updateEsimHandler(data.service_id, payload)
        if (updatedEsim) {
            setData(updatedEsim)
            fetchEsims(false, true)
        }
    }

    const modifiedFields = useMemo(() => {
        return {
            extension_id: {
                hasChanged: (selectedUser?.extension?.id || selectedUser?.voip_phone_id) !== (data?.user?.extension?.id || data?.user?.voip_phone_id),
                value: selectedUser?.extension?.id || selectedUser?.voip_phone_id
            },
            did: {
                hasChanged: selectedPhone !== data?.user?.did,
                value: selectedPhone
            },
            first_name: {
                hasChanged: selectedUserData?.first_name !== data?.user?.first_name,
                value: selectedUserData?.first_name
            },
            last_name: {
                hasChanged: selectedUserData?.last_name !== data?.user?.last_name,
                value: selectedUserData?.last_name
            },
            email: {
                hasChanged: selectedUserData?.email !== data?.user?.email,
                value: selectedUserData?.email
            }
        }
    }, [data, selectedUser, selectedPhone, selectedUserData])

    const submitButtonDisabled = useMemo(() => Object.keys(modifiedFields).every(key => !modifiedFields[key].hasChanged), [modifiedFields])

    const user = useMemo(() => {
        const isNxtUser = currentUser.user_tiered as boolean

        const user = data?.user
        let userName = `${user?.first_name} ${user?.last_name}`
        if (isNxtUser && user?.extension?.number) {
            userName = user?.extension?.name
            return `Ext ${user.extension.number}: ${userName}`
        }
        return userName
    }, [data])

    return (
        <div className={classes.root}>
            <Typography variant='subtitle1' color='neutral-500'>Assigned to:</Typography>
            <div className={classes.formContainer}>
                <Typography variant='subtitle1' color='neutral-900' data-testid='assigned-user-name'>{user}</Typography>
                <UserForm />
                {(statusText === 'Pending Device Activation' || statusText === 'Termination Requested') && <div className='btn-container'>
                    <Button variant='contained' color='primary' size='large' onClick={handleEsimUpdate} className='update-btn' disabled={updatedEsim.isLoading || !selectedPhone || submitButtonDisabled} minWidth={125} isLoading={updatedEsim.isLoading}>{details.updateBtn}</Button>
                    <TerminateSimBtn size='large' />
                </div>}
                {updatedEsim.error && <Alert level='error' icon={true}>{updatedEsim.error}</Alert>}
                {!updatedEsim.error && Boolean(Object.keys(updatedEsim.esim).length) && <Alert level='success' icon={true}>{details.updateSuccessMessage}</Alert>}
            </div>
        </div>
    )
}

export default AssignedForm
