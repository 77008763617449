import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'

const useStyles = makeStyles(theme => ({
    sidebarV2Link: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8px 0',
        rowGap: '4px',
        textAlign: 'center',
        position: 'relative',
        '& .badge': {
            position: 'absolute',
            top: '5px',
            right: '10px'
        },
        '& svg path': {
            fill: '#A6A6A6 !important'
        },
        '&:hover:not(.active)': {
            '& svg path': {
                fill: '#fff !important'
            },
            '& $title': {
                color: '#fff'
            }
        },
        '&.active': {
            '& svg path': {
                fill: `${theme.palette.primary['+300']} !important`
            },
            '& $title': {
                color: theme.palette.primary['+300']
            }
        }
    },
    title: {
        fontFamily: 'Inter, Montserrat, sans-serif !important',
        fontSize: '11px !important',
        color: theme.palette.grey[300],
        fontStyle: 'normal !important',
        fontWeight: '600 !important',
        lineHeight: '16px !important'
    }
}))

interface props {
    active?: boolean;
    icon: React.ReactNode;
    title?: string;
    badge?: React.ReactNode;
    onClick?: () => void;
}

/**
 * This defines the style and structure common to ALL items along SidebarV2.
 */
const SidebarV2Link = ({ active, icon, title, badge, onClick }: props) => {
    const classes = useStyles()

    return (
        <div className={`${classes.sidebarV2Link} ${active ? 'active' : ''}`} onClick={onClick}>
            {icon}

            {title &&
                <Typography className={classes.title}>
                    {title}
                </Typography>
            }

            {badge}
        </div>
    )
}

export default SidebarV2Link
