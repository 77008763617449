
/**
 *
 */
export const listing = {
    admin: ['Devices', 'Assigned to', 'Number', 'Status', ''],
    user: ['My eSIMs', 'Plan', 'Number', 'Status', ''],
    addButton: 'Add a new eSIM'
}

/**
 *
 */
export const details = {
    status: 'Status',
    assignee: 'Assignee',
    selectUser: 'Select user',
    selectPhone: 'Select a phone number',
    assignButton: 'Assign',
    cancelButton: 'Cancel',
    copied: 'Text Copied!',
    terminateBtn: 'Terminate eSIM',
    cancelTerminateBtn: 'Cancel Termination',
    transferBtn: 'Transfer eSIM',
    updateBtn: 'Update eSIM',
    updateSuccessMessage: 'Esim updated successfully!',
    phoneInfoTip: 'Only phone numbers that are registered for SMS, and enabled in your User’s Call Handling Rules can be selected.',
    assignEsimDialog: {
        title: 'Are you sure you want to assign eSIM?',
        assignedTo: 'Assigning to:',
        checkMessage: 'You can’t reassign the same eSIM to another user.',
        assignedMessage: 'eSIM assigned successfully!',
        emailSentMessage: 'An email with instructions to activate eSIM has been sent to',
        checkCompability: {
            title: 'Check eSIM compatibility for your phone.',
            subTitle: 'Enter your phone’s IMEI number to confirm if it supports eSIM. Locate the IMEI in <strong>Settings > General > About</strong> or by dialing *#06#.',
            IMEItext: 'Enter IMEI number',
            ignoreBtn: 'Ignore',
            checkBtn: 'Check',
            ignore: {
                title: 'Are you sure you want to Ignore?',
                subTitle: 'To avoid any issues with activating your eSIM, please verify that your device is compatible and unlocked for network use.',
                goBackBtn: 'Go back',
                ignoreBtn: 'Ignore'
            },
            check: {
                compatiblityCheck: {
                    title: 'Checked your eSIM compatibility',
                    subTitle: 'Your phone is compatible with eSIM',
                    error: 'Your phone is not compatible with eSIM'
                },
                networkCheck: {
                    title: 'Checked your phone network eSIM',
                    subTitle: 'Your phone network is compatible with eSIM',
                    error: 'Your phone is locked.'
                },
                cancelBtn: 'Cancel',
                continueBtn: 'Continue',
                learnMore: 'Learn More'
            }
        }
    },
    installation: {
        title: 'Installation instructions',
        subtitle: 'Follow the given steps below carefully to install your eSIM.',
        QRCode: {
            title: 'QR code installation',
            subtitle: 'Your eSIM will be ready to use after following these steps.',
            refresh: 'Refresh QR Code',
            refreshing: 'Refreshing QR Code...',
            refreshSuccessMessage: 'QR Code refreshed successfully!',
            points: [
                {
                    point: 'Step 1',
                    info: 'Scan QR code'
                },
                {
                    point: 'Step 2',
                    info: 'Activate eSIM'
                },
                {
                    point: 'Step 3',
                    info: 'Switch the line to Phone.com in your dialer'
                }
            ]
        },
        manual: {
            title: 'Manual installation',
            subtitle: 'Please refer to your phone’s help for specific instructions on how to enter the eSIM activation code manually',
            generating: 'Generating...',
            points: [
                {
                    point: 'Copy and paste the full eSIM activation',
                    info: 'LPA:1lergih.validereachdpplus.comNLSKELO4ME3JP1297MCBN4LCUOHOUBOJNWSKD8SNAH51LH4WRM51DE'
                },
                {
                    point: 'Copy and paste SM-DP+ server address',
                    info: 'mobileuc.validereachdpplus.com'
                },
                {
                    point: 'Copy and paste the activation code',
                    info: 'NLSKELO4ME3JP1297MCB-N4LCU-OHOUBOJNWSKD8SNAH51LH4WRM51DETSE5X-J'
                }
            ]
        }
    },
    help: {
        title: 'Need assistance? Try these helpful articles',
        linkText: 'View all how-to-articles',
        articles: [{ title: 'Activating your cellular service on iPhone' }, { title: 'Activating your cellular service on Android' }, { title: 'Terminating your cellular service' }]
    },
    NoEsimAssigned: {
        title: 'Stay Connected with a Cellular Plan!',
        admin: 'Get a Cellular Plan now',
        nonAdmin: 'You have not been assigned any eSIMs. Contact your admin to get assigned an eSIM.'
    }
}

/**
 *
 */
export const users: Record<string, any>[] = [
    {
        id: 1,
        first_name: 'John',
        last_name: 'Doe',
        email: 'johndoe@phone.com',
        relationships: {
            extension: {
                data: {
                    id: 2040964,
                    extension_number: 352,
                    extension_name: 'Adam B',
                    unlimited: false
                }
            },
            plan: { data: {} },
            devices: { data: [] },
            numbers: { data: [] }
        }
    },
    {
        id: 2,
        first_name: 'Michael',
        last_name: 'Keaton',
        email: 'michaelkeaton@phone.com',
        relationships: {
            extension: {
                data: {
                    id: 2040965,
                    extension_number: 353,
                    extension_name: 'Michael K',
                    unlimited: false
                }
            },
            plan: { data: {} },
            devices: { data: [] },
            numbers: { data: [] }
        }
    }
]

/**
 *
 */
export const eSIMs = [
    {
        service_id: '0qqJ67y8',
        voip_id: 2462274,
        account_type: 'employee',
        order_ref: '15',
        billing_code: 1,
        status: 'provisioned',
        created_at: '2025-01-20T06:30:48.000Z',
        updated_at: '2025-01-20T06:31:13.000Z',
        provisioned_at: null,
        esim: {
            iccid: '8944530000000089030',
            activation_code: 'LPA:1$mobileuc.validereachdpplus.com$KJIDG8FIRGPJTWJOS-B89QWJTE0NXUU5QVK611MKW8ANFQUS9QF27V-DJDTIW80R',
            activation_qr_code: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAA'
        },
        user: null
    },
    {
        service_id: 'CtNvgoSs',
        voip_id: 2462274,
        account_type: 'employee',
        order_ref: '14',
        billing_code: 2,
        status: 'provisioned',
        created_at: '2025-01-17T17:53:47.000Z',
        updated_at: '2025-01-17T17:55:07.000Z',
        provisioned_at: null,
        esim: {
            iccid: '8944530000000087985',
            activation_code: 'LPA:1$mobileuc.validereachdpplus.com$Q9AMX1VRT7FDA-DDX91BKLMX22MVY71SPSHPFV7BNMAAS48JVJQ3-R9KUJKD7L44',
            activation_qr_code: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAA'
        },
        user: {
            voip_phone_id: 2042236,
            did: '+19809224526',
            first_name: 'Ian',
            last_name: 'Honcharuk',
            email: 'missing@email.com',
            sip_user: '242781'
        }
    },
    {
        service_id: 'XFpTDAyc',
        voip_id: 2462274,
        account_type: 'employee',
        order_ref: '12',
        billing_code: 1,
        status: 'activated',
        created_at: '2025-01-17T15:09:33.000Z',
        updated_at: '2025-01-17T15:11:30.000Z',
        provisioned_at: null,
        esim: {
            iccid: '8944530000000087980',
            activation_code: 'LPA:1$mobileuc.validereachdpplus.com$O3WBRYEGFRHJJQ0PQWKWODFPT55K5RA7WOKO7JR3TBFXXRFZ0ZQI28BIKJ-CAZGI',
            activation_qr_code: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAA',
            device_name: 'iPhone',
            device_id: '123456'
        },
        user: {
            voip_phone_id: 2040964,
            did: '+19726453555',
            first_name: 'Adam',
            last_name: 'B',
            email: 'jkombucha@phone.com',
            sip_user: '242195'
        }
    }
]

/**
 *
 */
export const phones = [
    {
        number: '+15732558149',
        assigned: 2040964
    },
    {
        number: '+16363834913',
        assigned: 2040964
    }
]

/**
 *
 */
export const plans = [{ name: 'Unlimited Voice and Text', description: 'Mobile-X US Enterprise subscriber bundle base', price: 120, billing_code: 1 }, { name: 'PAYU Bundle Per 250 SMS', description: 'Mobile-X US Enterprise subscriber bundle base', price: 60, billing_code: 2 }]

/**
 *
 */
export const User = {
    user_id: 1,
    first_name: 'Pro',
    last_name: 'User',
    email: 'proUser@phone.com',
    role: 'account',
    user_account_type: 'pro',
    stage: 'PROD',
    v4ApiRoot: 'https://api.phone.com/v4',
    v5ApiRoot: 'https://preprod.my.phone.com/api/services',
    v5ToolsRoot: 'https://preprod.my.phone.com/api/tools',
    cp_token: 'abcdef',
    ac_token: 'abcdefgh',
    company: 'United People',
    extension: 601,
    features: new Set([]),
    extensions: [],
    true_account_id: 1857254,
    user_default_extension_id: 4027332,
    voip_id: '5464474',
    voip_phone_id: 2040964,
    user_tiered: true
}
