import React, { useState, useContext, useEffect, useCallback } from 'react'
import DropdownMenu from 'dropdown-menu'
import Typography from 'typography'
import { ClassNameMap } from '@material-ui/styles/withStyles'
import { Extension, LeaveContext, LeaveLevel, UserContext } from 'providers'
import { makeStyles } from '@material-ui/core'
import {
    InboxIcon,
    CaretIcon,
    CheckIcon
} from './TopbarIcons'

const useStyles = makeStyles(() => ({
    inboxDropdownButton: {
        display: 'flex',
        width: '200px',
        padding: '8px 15px',
        alignItems: 'center',
        gap: '5px',
        borderRadius: '25px',
        border: '1px solid #8C8C8C',
        cursor: 'pointer',
        '& $body2Variant': {
            color: '#BFBEBE !important'
        },
        '&:hover': {
            '& svg path': {
                fill: '#fff'
            },
            '& $body2Variant': {
                color: '#fff !important'
            }
        }
    },
    inboxSelector: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& $body2Variant': {
            color: '#595959 !important',
            '&.active': {
                fontWeight: '600 !important'
            }
        }
    },
    body2Variant: {
        fontFamily: 'Inter !important',
        fontSize: '13px !important',
        fontStyle: 'normal !important',
        fontWeight: '400 !important',
        lineHeight: '21px !important',
        letterSpacing: '-0.1px !important'
    }
}))

const myInbox = 'My Inbox'
const companyInbox = 'Company Inbox'

/**
 *
 */
const InboxDropdown = () => {
    const classes = useStyles()
    const userInfo = useContext(UserContext)
    const leaveContext = useContext(LeaveContext)

    const getInboxNameForExtension = (ext: Extension) => {
        if (userInfo.user_account_type === 'classic') {
            return `Ext ${ext.extension} - ${ext.extension_name}`
        }

        return ext.is_company ? companyInbox : myInbox
    }

    const { extension, extensions } = userInfo

    // This is controversial:  the actual inbox we are on is derived from the current extension (and the .is_company field on that)
    // But we would like a separate piece of state to change the dropdown (immediately), then start the asyncronoush extension switching process.
    const [inbox, setInbox] = useState<string>(getInboxNameForExtension(extension))

    const switchExtension = useCallback(async (newExt: Extension) => {
        const approveLeave = await leaveContext.leave(LeaveLevel.USER)
        if (approveLeave) {
            setInbox(getInboxNameForExtension(newExt))
            leaveContext.leaveCurrentExtension(newExt) // asynchronous, but no need to wait on it
        }
    }, [])

    // On changing extension, we need should sync the "inbox" state above to match the new extension
    useEffect(() => {
        if (extension) {
            const associatedInboxName = getInboxNameForExtension(extension)
            if (associatedInboxName !== inbox) {
                setInbox(associatedInboxName)
            }
        }
    }, [extension])

    const InboxComponent = ({ inbox, isActive, classes }: { inbox: 'My Inbox' | 'Company Inbox', isActive: boolean, classes: ClassNameMap }) => (
        <div className={classes.inboxSelector}>
            <Typography className={`${classes.body2Variant} ${isActive ? 'active' : ''}`}>
                {inbox}
            </Typography>
            <div>
                {isActive &&
                    <CheckIcon />
                }
            </div>
        </div>
    )

    const DropdownButtonComponent = buttonProps => (
        <div data-testid="inbox-dropdown-button" {...buttonProps} className={classes.inboxDropdownButton} >
            <InboxIcon />

            <Typography className={classes.body2Variant}>
                {inbox}
            </Typography>

            <CaretIcon />
        </div>
    )

    const menuItems = extensions.map(ext => ({
        text: (
            <InboxComponent
                inbox={getInboxNameForExtension(ext)}
                classes={classes}
                isActive={ext.extension_id === extension.extension_id}
            />
        ),
        onClick: () => switchExtension(ext)
    }))

    return (
        <DropdownMenu
            data-testid='inbox-dropdown'
            dropdownStyle = 'configure'
            button={DropdownButtonComponent}
            showFilter={extensions.length > 5}
            filterLabel="Search extensions"
            filterFunction={(menuItem, filterValue) => {
                if (!filterValue) return true
                const index = menuItems.indexOf(menuItem)
                const ext = extensions[index]
                const inboxName = getInboxNameForExtension(ext)
                return inboxName.toLowerCase().includes(filterValue.toLowerCase())
            }}
            menuItems={menuItems}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
        />
    )
}

export default InboxDropdown
