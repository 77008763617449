import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Select } from 'foundations-library/components'
import ListItems from './ListItems'
import { processLink } from '../helpers'
import { useWidgetContext } from 'providers'
import GoBack from '../../../../apps/apps-marketplace/src/components/GoBack'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '1050px',
        margin: 'auto',
        marginTop: '40px'
    },
    filter: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '30px'
    },
    select: {
        marginLeft: '10px',
        width: '300px'
    }
}))

interface Option {
    text: string
    value: string
    slug: string
}

/**
 *
 */
const App = () => {
    const styles = useStyles()
    const [allArticles, setAllArticles] = useState([])
    const { sectionSlug } = useParams()
    const [selectedSection, setSelectedSection] = useState('')
    const [options, setOptions] = useState<Option[]>([])
    const history = useHistory()

    const { getArticlesBySection, remoteValue } = useWidgetContext()

    const capitalizeFirstLetter = (str) => {
        if (!str) return ''
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    useEffect(() => {
        const formatOptions = remoteValue.sections.map(x => ({
            text: x.sectionName,
            value: x.sectionName,
            slug: x.slug
        }))
        setOptions(formatOptions)
        if (sectionSlug) setSelectedSection(capitalizeFirstLetter(sectionSlug))
        else setSelectedSection(formatOptions[0].text)
    }, [])

    useEffect(() => {
        if (selectedSection) {
            getArticles(selectedSection)
        }
    }, [selectedSection])

    const changeSection = (value: string) => {
        if (value) {
            setSelectedSection(value)
        }
    }
    const redirect = (val: string) => {
        const section = options.find(x => x.text === selectedSection)

        if (!section) {
            console.error('Section not found for selectedSection:', selectedSection)
            return
        }
        const sectionSlug = section.slug
        processLink(`/support-center/${sectionSlug}/${val}`, history)
    }

    const getArticles = (val: string) => {
        const newVal = getArticlesBySection(val)
        if (newVal) {
            setAllArticles(newVal.articles)
        }
    }

    const goBack = <GoBack title={'How to articles'} />

    return (
        <>
            {goBack}
            <div className={styles.root}>
                {selectedSection && (
                    <div className={styles.filter} data-testid="list">
                        <Typography variant='subtitle2'>Filter by type:</Typography>
                        <div className={styles.select}>
                            <Select options={options} onChange={changeSection} value={selectedSection} required={true} data-testid='filter-select'/>
                        </div>
                    </div>
                )}
                {allArticles?.length > 0 && (
                    <ListItems items={allArticles} onRedirect={(articleSlug) => redirect(articleSlug)} />
                )}
            </div>
        </>
    )
}

export default App
