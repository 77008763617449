import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography, Icon } from 'foundations-library/components'

const jss = () => ({
    userContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left'
    },
    userNameAndNumber: {
        marginLeft: '10px'
    },
    userName: {
        marginBottom: '6px'
    }
})

const useStyles = makeStyles(jss)
interface PropsInterface { className?: string, name?: string, number?: string }
/**
 *
 */
const UserNameAndNumber = ({ className = '', name, number }: PropsInterface): JSX.Element => {
    const styles = useStyles()
    if (!name && !number) return <></>

    return <div className={`${styles.userContainer} ${className}`} data-testid="user-name-and-number">
        <Icon name='cellular' size={34} color='neutral-900' data-testid="user-name-and-number-icon" />
        <div className={styles.userNameAndNumber}>
            {name && <Typography
                variant='subtitle2'
                color='neutral-900'
                className={styles.userName}
                data-testid="user-name-and-number-name"
            >
                {name}
            </Typography>}
            {number && <Typography
                variant='serial'
                color='neutral-500'
                data-testid="user-name-and-number-number"
            >
                {number}
            </Typography>}
        </div>
    </div>
}

export default UserNameAndNumber
