import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { Skeleton } from 'foundations-library/components'

/**
 *
 */
export const columns = 4
/**
 *
 */
export const rows = 3

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        tableContainer: {
            margin: '50px 10px 20px',
            overflowX: 'auto',
            '& tr': { height: '80px' },
            '& tr:first-child': {
                height: '34px',
                borderRadius: '5px',
                backgroundColor: 'hsl(0, 0%, 75%)',
                '& th': {
                    width: '24%',
                    paddingLeft: '6px',
                    '&:first-child': {
                        borderRadius: '5px 0 0 5px'
                    },
                    '&:last-child': {
                        width: '4%',
                        borderRadius: '0 5px 5px 0'
                    }
                }
            },
            '& tr:not(:first-child)': {
                borderBottom: `1px solid ${verbalColors['neutral-100']}`,
                '& td': {
                    paddingLeft: '6px'
                }
            }
        },
        addNewButtonContainer: {
            margin: '0 10px'
        }
    }
})

/**
 *
 */
const ListSkeleton = () => {
    const classes = useStyles()

    return (
        <>
            <div className={classes.tableContainer}>
                <table>
                    <tr>
                        {
                            Array.from(new Array(columns)).map((column, index) => (
                                <th key={index}>
                                    <Skeleton variant='text' width={'100px'} />
                                </th>
                            ))
                        }
                        <th></th>
                    </tr>
                    {
                        Array.from(new Array(rows)).map((sim, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <Skeleton variant='rect' width={'200px'} height={'50px'} />
                                    </td>
                                    <td>
                                        {/* <Skeleton variant='text' width={'100px'} margin={'0 0 3px'} /> */}
                                        <Skeleton variant='text' width={'200px'} />
                                    </td>
                                    <td>
                                        <Skeleton variant='text' width={'150px'}/>
                                        {/* <Skeleton variant='text' width={'50px'} /> */}
                                    </td>
                                    <td>
                                        <Skeleton variant='text' width={'60px'} margin={'0 0 3px'} />
                                        <Skeleton variant='text' width={'150px'} />
                                    </td>
                                    <td>
                                        <Skeleton variant='circle' width={'30px'} height={'30px'} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
            {/* <div className={classes.addNewButtonContainer}>
                <Skeleton variant='rect' width={'140px'} height={'30px'} />
            </div> */}
        </>
    )
}

export default ListSkeleton
