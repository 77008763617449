import React, { useContext, useEffect, useRef } from 'react'
import { AppBar, makeStyles, Toolbar } from '@material-ui/core'
import styles from './styles'
import IdentityArea from '../../../identity-area/IdentityArea'
import { formatPhoneNumber } from 'phone-numbers'
import { Dialpad9Icon, DoNotDisturbAllIcon, MenuIcon, SearchIcon } from 'svg-icons'
import Typography from 'typography'
import SearchInbox from 'search-inbox'
import { FeatureEventsContext, ScreenSizeContext, UserContext } from 'providers'
import { getFeatureEnabled } from 'feature-flag'
import { DefaultArrowTooltip, DefaultTooltip } from 'tooltips'
import ActiveCall from './ActiveCall'
import { SoftphoneContext } from 'softphone'
import RemoteConfigValue from 'remote-config-value'
import { PdcCallContext } from 'pdc-calls'
import { useHistory } from 'react-router-dom'
import IconButton from 'icon-button-mui'

const useStyles = makeStyles(styles)

interface Props {
    extension: {
        extension_id: number // eslint-disable-line
        phone_number: any // eslint-disable-line
        extension: number
        extension_name: string // eslint-disable-line
        is_virtual: 1 | 0 // eslint-disable-line
        is_default: 1 | 0 // eslint-disable-line
        caller_id: string // eslint-disable-line
        userId: number
    }
    companyName: string | null
    csr: boolean
    redirect: (path: string) => void
    navigationShow: boolean
}

/***/
export interface UserInfo {
    name: string
    number: string
    company: string
    accountId: number
    csr: boolean
}

const getUserInfo = (props: Props): UserInfo => {
    const V5PHONECOM = window.V5PHONECOM
    if (!V5PHONECOM) return { name: '', company: '', number: '', accountId: 0, csr: false }
    const isBJ = Boolean(V5PHONECOM.user_id)
    const name = isBJ ? `${V5PHONECOM.first_name} ${V5PHONECOM.last_name}` : (props.extension?.extension_name || '')
    const company = (props.companyName == null) ? V5PHONECOM.company : props.companyName
    const number = isBJ ? formatPhoneNumber(V5PHONECOM.direct_number) : (props.extension ? `Ext. ${props.extension.extension}` : '')
    const accountId = V5PHONECOM.true_account_id
    return { name, number, company, accountId, csr: !!props.csr }
}

const ToggleNav = ({ sideBarOpen, setSideBarOpen }: { sideBarOpen: boolean; setSideBarOpen: (open: boolean) => void }): JSX.Element | null => {
    const featureEventsContext = useContext(FeatureEventsContext)

    return (
        <div
            className = 'menu-button'
            onClick = {() => {
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-toggle-show-navigation-click', value: !sideBarOpen })
                setSideBarOpen(!sideBarOpen)
            }}
            data-test-id = 'toggle-nav-hamburger-button'
        >
            <MenuIcon/>
        </div>
    )
}

const Search = (props: Props): JSX.Element | null => {
    const [showModal, setShowModal] = React.useState(false)
    const [enabled, setEnabled] = React.useState(false)
    const screenSizeContext = useContext(ScreenSizeContext)
    const searchFieldRef = useRef<HTMLDivElement | null>(null)
    const featureEventsContext = useContext(FeatureEventsContext)
    useEffect(() => {
        getFeatureEnabled('search').then(f => setEnabled(!!(f || !process.env.REACT_APP_IS_SEARCH_DISABLED)))
    }, [])
    useEffect(() => {
        const NAV_WIDTH = 224 // eslint-disable-line
        const newSearchWidth = Math.max(200, (screenSizeContext.width - NAV_WIDTH) * 30 / 100 - 22)
        if (searchFieldRef.current) searchFieldRef.current.style.width = `${newSearchWidth}px`
    }, [screenSizeContext.width, searchFieldRef.current])
    const isMobile = screenSizeContext.mobile
    const isTablet = screenSizeContext.tablet
    if (!enabled) return null
    const goToConversation = inbox => {
        setShowModal(false)
        const conversationId = inbox.conversation_id
        const extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        const redirectPath = `/e${extensionId}/messages/c${conversationId}`
        props.redirect(redirectPath)
    }
    return (
        <>
            <div
                className = {`search-button ${isTablet ? 'sm' : ''} ${isMobile ? 'mobile' : ''}`}
                onClick = {() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-search-click' })
                    setShowModal(true)
                }}
                data-test-id = 'top-bar-search-field'
                ref = {searchFieldRef}
            >
                <SearchIcon/>

                {!isMobile &&
                    <Typography variant='body2'>Search messages</Typography>
                }
            </div>
            <SearchInbox
                isOpen = {showModal}
                onClose = {() => setShowModal(false)}
                goToConversation = {goToConversation}
                location = 'navigation'
                isMobile = {isMobile}
                smallView = {isMobile}
            />
        </>
    )
}

const DialpadSection = (props: Props & { softphoneContext }): JSX.Element => {
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const isVirtualExtension = !!props.extension?.is_virtual
    const button = (
        <IconButton
            classes = {{ root: 'dialpad-button disable-dragging' }}
            disabled = {isVirtualExtension}
            onClick = {() => {
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-dialpad-button-click', value: !isVirtualExtension })
                props.softphoneContext.ToggleSoftphone()
            }}
        >
            <Dialpad9Icon/>
        </IconButton>
    )
    const ButtonWrapper = isVirtualExtension ? 'div' : React.Fragment
    return (
        <DefaultTooltip
            classes = {{ popper: classes.tooltipPopper, tooltip: classes.tooltip }}
            title = {isVirtualExtension ? <Typography variant='tooltip' remoteConfigID='disabled_call_buttons_tooltip_text_virtual_extension'/> : ''}
            placement = 'bottom'
        >
            <ButtonWrapper>
                {button}
            </ButtonWrapper>
        </DefaultTooltip>
    )
}

const DoNotDisturb = (props: Props): JSX.Element => {
    const [doNotDisturb, setDoNotDisturb] = React.useState(localStorage.icdnd === 'true')
    const featureEventsContext = useContext(FeatureEventsContext)
    const callContext: any = useContext(PdcCallContext)

    const toggleDoNotDisturb = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-do-not-disturb-click', value: !doNotDisturb })
        localStorage.icdnd = !doNotDisturb
        setDoNotDisturb(!doNotDisturb)
    }
    useEffect(() => { callContext.toggleDoNotDisturb(doNotDisturb) }, [doNotDisturb])
    const tooltipTextRemoteConfigId = doNotDisturb ? 'top_bar_do_not_disturb_enabled_tooltip_text' : 'top_bar_do_not_disturb_disabled_tooltip_text'
    return (
        <DefaultArrowTooltip title={<RemoteConfigValue valueId={tooltipTextRemoteConfigId}/>}>
            <div className={`do-not-disturb-wrapper ${doNotDisturb ? 'on' : 'off'}`} onClick={toggleDoNotDisturb}>
                <DoNotDisturbAllIcon/>
            </div>
        </DefaultArrowTooltip>
    )
}

interface TopBarProps {
    isSmallScreen: boolean;
    sideBarOpen: boolean;
    setSideBarOpen: (open: boolean) => void;
}

/***/
export const TopBar = ({ isSmallScreen, sideBarOpen, setSideBarOpen }: TopBarProps): JSX.Element => {
    const classes = useStyles()

    const userInfo = useContext(UserContext)
    const companyName = userInfo.extensions.find(ext => !!ext.company_name)?.company_name

    const history = useHistory()

    const props: Props = {
        extension: userInfo.extension,
        companyName,
        csr: userInfo.csr,
        redirect: (path: string) => history.push(path)
    }

    const softphoneContext = useContext(SoftphoneContext)
    const screenSizeContext = useContext(ScreenSizeContext)
    const isDesktopView = !screenSizeContext.mobile && !screenSizeContext.tablet
    return (
        <>
            {!isDesktopView ? <ActiveCall softphoneContext={softphoneContext} {...props}/> : null}
            <AppBar
                color = 'default'
                classes = {{ root: classes.appBar }}
                position = 'static'
            >
                <Toolbar classes={{ root: classes.toolbar }}>
                    <div className='top-bar-left'>
                        {isSmallScreen &&
                            <ToggleNav
                                sideBarOpen={sideBarOpen}
                                setSideBarOpen={setSideBarOpen}
                            />
                        }
                        <IdentityArea {...props} userInfo={getUserInfo(props)} />
                        <Search {...props}/>
                    </div>
                    <div className='top-bar-right'>
                        {isDesktopView ? <ActiveCall softphoneContext={softphoneContext} {...props} /> : null}
                        <DialpadSection softphoneContext={softphoneContext} {...props}/>
                        <div className='delimiter'/>
                        <DoNotDisturb {...props} />
                    </div>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default TopBar
