import React, { useEffect, useState } from 'react'
import PDCMarkdown from 'pdc-markdown'
import { useWidgetContext, WidgetArticleType } from 'providers'
import { useParams } from 'react-router-dom'
import { Typography } from 'foundations-library/components'
import GoBack from '../../../../apps/apps-marketplace/src/components/GoBack'

/**
 *
 */
const Show = (): JSX.Element => {
    const [article, setArticle] = useState<WidgetArticleType | null>(null)
    const { findArticleBySlugs } = useWidgetContext()
    const { sectionSlug, articleSlug } = useParams<Record<string, string>>()

    useEffect(() => {
        setArticle(findArticleBySlugs(sectionSlug, articleSlug))
        window.scrollTo({ top: 0 }) // if we open a long article, it would be opened in the middle
    }, [])

    if (!article) return <Typography variant='subtitle1'>Not found</Typography>
    const goBack = <GoBack title={article.title} />

    return <>
        {goBack}
        <div style={{ padding: '40px 10%' }} data-testid="markdown-wrapper">
            <PDCMarkdown styleOverrides={article.styleOverrides || {}}>
                {article.content}
            </PDCMarkdown>
        </div>
    </>
}

export default Show
