import React, { useContext } from 'react'
import NavLink from 'nav-link'
import { NavItem } from '../../Navigation'
import NavItemIcon from '../../CommonUI/NavItemIcon'
import NavItemBadge from '../../CommonUI/NavItemBadge'
import SidebarV2Link from './SidebarV2Link'
import { NavigationContext } from '../../NavigationContext'
import SidebarV2Badge from './SidebarV2Badge'

interface props {
    navItem: NavItem
}

/**
 * Represents a top-level nav item, along the Sidebar (V2).
 */
const SidebarV2NavItem = ({ navItem }: props) => {
    const targetNavItem = navItem.children?.length ? navItem.children[0] : navItem

    const { getActiveNavItems } = useContext(NavigationContext)

    const activeNavItems = getActiveNavItems()
    const active = activeNavItems[0]?.id === navItem.id

    return (
        <NavLink navItemId={targetNavItem.id}>
            <SidebarV2Link
                active={active}
                icon={<NavItemIcon navItem={navItem} />}
                title={navItem.title}
                badge={<NavItemBadge navItem={navItem} badgeProps={{ designComponent: SidebarV2Badge }} />}
            />
        </NavLink>
    )
}

export default SidebarV2NavItem
