import React, { useEffect, useContext, useRef } from 'react'
import SearchInbox from 'search-inbox'
import { getFeatureEnabled } from 'feature-flag'
import { makeStyles } from '@material-ui/core'
import { FeatureEventsContext, ScreenSizeContext } from 'providers'
import Typography from 'typography'
import { useHistory } from 'react-router-dom'
import { SearchIcon } from './TopbarIcons'

const useStyles = makeStyles(theme => ({
    searchButton: {
        padding: '9px 20px',
        backgroundColor: 'rgba(0, 0, 0, 0.30)',
        borderRadius: 50,
        color: theme.palette.text.secondary,
        display: 'flex',
        alignItems: 'center',
        columnGap: 4,
        flex: '1 0 0',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.secondary.background
        },
        searchIcon: {
            fontSize: 20
        },
        '& $body2Variant': {
            color: 'rgba(255, 255, 255, 0.60)'
        }
    }
}))

/**
 *
 */
const SearchBar = (): JSX.Element | null => {
    const classes = useStyles()
    const [showModal, setShowModal] = React.useState(false)
    const [enabled, setEnabled] = React.useState(false)
    const screenSizeContext = useContext(ScreenSizeContext)
    const searchFieldRef = useRef<HTMLDivElement | null>(null)
    const featureEventsContext = useContext(FeatureEventsContext)

    const history = useHistory()

    useEffect(() => {
        getFeatureEnabled('search').then(f => setEnabled(!!(f || !process.env.REACT_APP_IS_SEARCH_DISABLED)))
    }, [])

    useEffect(() => {
        const NAV_WIDTH = 224 // eslint-disable-line
        const newSearchWidth = Math.max(200, (screenSizeContext.width - NAV_WIDTH) * 30 / 100 - 22)
        if (searchFieldRef.current) searchFieldRef.current.style.width = `${newSearchWidth}px`
    }, [screenSizeContext.width, searchFieldRef.current])

    const isMobile = screenSizeContext.mobile

    if (!enabled || isMobile) return null

    const goToConversation = inbox => {
        setShowModal(false)
        const conversationId = inbox.conversation_id
        const extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        const redirectPath = `/e${extensionId}/messages/c${conversationId}`
        history.push(redirectPath)
    }

    return (
        <>
            <div
                data-testid='top-bar-search-field'
                ref={searchFieldRef}
                className={classes.searchButton}
                onClick={() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-search-click' })
                    setShowModal(true)
                }}
            >
                <SearchIcon className={classes.searchIcon} />

                <Typography className={classes.body2Variant} variant='body2' remoteConfigID='topbar_v2_search_bar_placeholder' />
            </div>
            <SearchInbox
                isOpen = {showModal}
                onClose = {() => setShowModal(false)}
                goToConversation = {goToConversation}
                location = 'navigation'
                isMobile = {isMobile}
                smallView = {isMobile}
            />
        </>
    )
}

export default SearchBar
