import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { ScreenSizeContext } from 'providers'

// Child components:
import BrandLogo from './BrandLogo'
import InboxDropdown from './InboxDropdown'
import SearchBar from './SearchBar'
import DialpadButton from './DialpadButton'
import UserProfile from './UserProfile'
import DoNotDisturbSwitch from './DoNotDisturbSwitch'

// Intentionally re-using ActiveCall component from old UI, this may be improved upon later:
import ActiveCall from '../../NavUIV1/TopBarV1/ActiveCall'

const useStyles = makeStyles(theme => ({
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 20px',
        background: 'linear-gradient(90deg, #021F19 5.59%, #00614E 56.29%)',
        minHeight: '68px'
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        gap: 40
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center',
        gap: 20
    },
    divider: {
        width: 1,
        height: 40,
        backgroundColor: 'rgba(255, 255, 255, 0.20)'
    }
}))

/**
 * Bar shown across the top of the screen.
 * Designed to match Nav UI V2 related components like SidebarV2.
 */
export const TopBarV2 = (): JSX.Element => {
    const classes = useStyles()

    const screenSizeContext = useContext(ScreenSizeContext)
    const isDesktopView = !screenSizeContext.mobile && !screenSizeContext.tablet

    return (
        <div className={classes.topBar}>
            {!isDesktopView &&
                <ActiveCall />
            }

            <div className={classes.section}>
                <BrandLogo />
                <InboxDropdown />
            </div>

            <div className={classes.section}>
                <SearchBar />
            </div>

            <div className={classes.rightSection}>
                {isDesktopView &&
                    <ActiveCall />
                }

                <DialpadButton />

                <DoNotDisturbSwitch />

                <div className={classes.divider} />

                <UserProfile />
            </div>
        </div>
    )
}

export default TopBarV2
