import { makeStyles } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styles from './styles'
import api from '../../util/api_v5'
import LoaderFull from 'loader-full'
import { ScreenSizeContext } from 'providers'
import { isPushingNotificationsGranted } from 'notification-pusher'
import { DefaultArrowTooltip } from 'tooltips'
import Switch from 'switch'
import Checkbox, { Variant as CheckboxVariant } from 'checkbox-mui'
import gtmDataPush from 'gtm-events'
// import { useForceUpdate } from 'hooks'
import AutoReply, { AutoReplyHandle } from './AutoReply'
// import FeatureFlag from 'feature-flag'
import Button from 'button-mui'
import Typography from 'typography'
import { getValue } from 'remote-config-value'
import SettingsWrapper from '../SettingsWrapper'

const useStyles = makeStyles(styles)

const GTM_APP_NAME = 'personal-settings;notifications' // eslint-disable-line
const GTM_MAP = { TOGGLE_ON: 1, TOGGLE_OFF: 0, ALREADY_SAVING: 0, SAVE: 1 } // eslint-disable-line

const gtmPush = (label: string, value: string | number) => {
    // eslint-disable-next-line
    gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: label, PDC_Value: value })
}

interface Props {
    setBusy: (isBusy: boolean) => void
    origin?: string
}

const localStorageMap = {
    calls: 'dninccls',
    voicemails: 'dnmsclsvms',
    messages: 'dnmsgs',
    faxes: 'dnfxs'
}

const getDesktopNotificationsStatuses = () => {
    const main = isPushingNotificationsGranted()
    const calls = main && localStorage[localStorageMap.calls] !== 'false'
    const voicemails = main && localStorage[localStorageMap.voicemails] !== 'false'
    const messages = main && localStorage[localStorageMap.messages] !== 'false'
    const faxes = main && localStorage[localStorageMap.faxes] !== 'false'
    return { main, calls, voicemails, messages, faxes }
}

interface NotificationItemProps {
    toggle?: boolean
    checkbox?: boolean
    checked: boolean
    remoteConfigId: string
    onClick: () => void
    disabledMessage?: string
    indent?: boolean
}

const NotificationItem = ({ toggle, checkbox, checked, remoteConfigId, onClick, disabledMessage, indent }: NotificationItemProps) => {
    const classes = useStyles()
    if (!toggle && !checkbox) return null
    const toggleProps = {
        label: getValue(remoteConfigId),
        checked,
        onChange: onClick,
        name: `notification-item-${remoteConfigId}`,
        value: checked ? 'on' : 'off',
        disabled: Boolean(disabledMessage)
    }
    const toggleElement = toggle
        ? <Switch {...toggleProps}/>
        : <Checkbox variant={CheckboxVariant.PRIMARY} {...toggleProps}/>
    return (
        <div className={`${classes.notificationItem} ${indent ? 'indent' : ''}`}>
            <DefaultArrowTooltip title={disabledMessage} placement='top'>
                <div>{toggleElement}</div>
            </DefaultArrowTooltip>
        </div>
    )
}

const DesktopNotifications = (props: Props): JSX.Element => {
    if (props.origin === 'configure-app') return null
    const classes = useStyles()

    const [mainGranted, setMainGranted] = useState(false)
    const [callsGranted, setCallsGranted] = useState(false)
    const [messagesGranted, setMessagesGranted] = useState(false)
    const [voicemailsGranted, setVoicemailsGranted] = useState(false)
    const [faxesGranted, setFaxesGranted] = useState(false)

    // On mount read the current status of the notifications from local storage
    // this may result in the UI being out of sync with the actual status of the notifications
    // if the local storage is changed by another tab or area of code.
    useEffect(() => {
        const statuses = getDesktopNotificationsStatuses()
        setMainGranted(statuses.main)
        setCallsGranted(statuses.calls)
        setMessagesGranted(statuses.messages)
        setVoicemailsGranted(statuses.voicemails)
        setFaxesGranted(statuses.faxes)
    }, [])

    const setPermission = (type:string, granted: boolean) => {
        switch (type) {
                case 'calls':
                    setCallsGranted(granted)
                    break
                case 'messages':
                    setMessagesGranted(granted)
                    break
                case 'voicemails':
                    setVoicemailsGranted(granted)
                    break
                case 'faxes':
                    setFaxesGranted(granted)
                    break
                default:
                    return
        }
        gtmPush(`toggle-desktop-notification;${type}`, granted ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF) // eslint-disable-line
        console.log(`statues setting notification ${type} to ${granted}`)
        localStorage[localStorageMap[type]] = `${granted}`
    }

    let mainDisabledMessage = ''
    if (window?.Notification?.permission === 'granted') mainDisabledMessage = getValue('user_settings_notifications_desktop_disable_info_text')
    if (window?.Notification?.permission === 'denied') mainDisabledMessage = getValue('user_settings_notifications_desktop_enable_info_text')
    const othersDisabledMessage = mainGranted ? '' : getValue('user_settings_notifications_desktop_enable_info_text_2')

    const toggleDesktopNotifications = async type => {
        console.log('statues hit')
        if (type === 'main') {
            if (window?.Notification?.permission === 'default') {
                const response = await Notification?.requestPermission()
                const granted = response === 'granted'
                Object.values(localStorageMap).forEach(localStorageKey => { localStorage[localStorageKey] = granted })
                setMainGranted(granted)
            }
            return
        }
        const currentState = localStorage[localStorageMap[type]] !== 'false'
        setPermission(type, !currentState)
    }

    const screenSizeContext = useContext(ScreenSizeContext)
    const titleTypography = screenSizeContext.mobile ? 'h6' : 'body1'
    return (
        <div className={classes.section} data-test-id='desktop-section'>
            <div className={classes.sectionTitle}><Typography variant={titleTypography}>Desktop notifications</Typography></div>
            <NotificationItem toggle checked={mainGranted} remoteConfigId='user_settings_notifications_desktop_main_text' onClick={() => toggleDesktopNotifications('main')} disabledMessage={mainDisabledMessage}/>
            <div/>
            <NotificationItem toggle indent checked={callsGranted} remoteConfigId='user_settings_notifications_desktop_calls_text' onClick={() => toggleDesktopNotifications('calls')} disabledMessage={othersDisabledMessage}/>
            <NotificationItem toggle indent checked={voicemailsGranted} remoteConfigId='user_settings_notifications_desktop_voicemails_text' onClick={() => toggleDesktopNotifications('voicemails')} disabledMessage={othersDisabledMessage}/>
            <NotificationItem toggle indent checked={messagesGranted} remoteConfigId='user_settings_notifications_desktop_messages_text' onClick={() => toggleDesktopNotifications('messages')} disabledMessage={othersDisabledMessage}/>
            <NotificationItem toggle indent checked={faxesGranted} remoteConfigId='user_settings_notifications_desktop_faxes_text' onClick={() => toggleDesktopNotifications('faxes')} disabledMessage={othersDisabledMessage}/>
        </div>
    )
}

const EmailNotifications = (props: Props & { settings, update }): JSX.Element => {
    const classes = useStyles()
    const { settings, update } = props
    const getValue = (type, app) => settings[app][type]
    const toggleEmailForCalls = () => {
        settings.calls.send_email = !settings.calls.send_email // eslint-disable-line
        update(settings)
        gtmPush('toggle-email-notification;calls', settings.calls.send_email ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF) // eslint-disable-line
    }
    const toggleEmailForMessagesFaxesAndVoicemails = () => {
        const newState = !settings.messages.email
        settings.messages.email = newState
        settings.faxes.email = newState
        settings.voicemails.email = newState
        update(settings)
        gtmPush('toggle-email-notification;messages-faxes-voicemails', newState ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF) // eslint-disable-line
    }
    const toggleVoicemailAttachmentType = () => {
        settings.voicemails.attachment_type = settings.voicemails.attachment_type === 'wav' ? 'none' : 'wav' // eslint-disable-line
        update(settings)
        gtmPush('toggle-email-notification;voicemail-attachment', settings.voicemails.attachment_type === 'wav' ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF) // eslint-disable-line
    }
    const toggleAttachFax = () => {
        settings.faxes.attach_fax = !settings.faxes.attach_fax // eslint-disable-line
        update(settings)
        gtmPush('toggle-email-notification;attach-fax', settings.faxes.attach_fax ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF) // eslint-disable-line
    }

    const screenSizeContext = useContext(ScreenSizeContext)
    const titleTypography = screenSizeContext.mobile ? 'h6' : 'body1'

    return (
        <div className={classes.section} data-test-id='email-section'>
            <div className={classes.sectionTitle}><Typography variant={titleTypography}>Email notifications</Typography></div>
            <NotificationItem toggle checked={getValue('send_email', 'calls')} remoteConfigId='user_settings_notifications_email_calls_text' onClick={toggleEmailForCalls}/>
            <NotificationItem toggle checked={getValue('email', 'messages')} remoteConfigId='user_settings_notifications_email_messages_faxes_voicemail_text' onClick={toggleEmailForMessagesFaxesAndVoicemails}/>
            <NotificationItem checkbox indent checked={getValue('attachment_type', 'voicemails') === 'wav'} remoteConfigId='user_settings_notifications_email_voicemail_audio_transcription_text' onClick={toggleVoicemailAttachmentType}/>
            <NotificationItem checkbox indent checked={getValue('attach_fax', 'faxes')} remoteConfigId='user_settings_notifications_email_attach_fax_text' onClick={toggleAttachFax}/>
        </div>
    )
}

interface NotificationSettings {
    calls: { status: boolean, send_email: boolean, send_sms: boolean, send_to: string } // eslint-disable-line
    messages: { email: boolean, mobile: boolean }
    voicemails: { email: boolean, mobile: boolean, attachment_type: string } // eslint-disable-line
    faxes: { email: boolean, mobile: boolean, attach_fax: boolean } // eslint-disable-line
}

const Loader = () => {
    const classes = useStyles()
    const screenSizeContext = useContext(ScreenSizeContext)
    const sidebarHidden = screenSizeContext.mobile || screenSizeContext.tablet
    return (
        <div className={classes.loadingDiv}>
            <LoaderFull size='big' styles={{ loaderFull: { left: sidebarHidden ? '50%' : 'calc(50% + 120px)' } }}/>
        </div>
    )
}

/***/
/**
 *
 */
const Notifications = (props: Props): JSX.Element => {
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [autoReplyLoaded, setAutoReplyLoaded] = useState(!process.env.REACT_APP_ENABLE_AUTO_REPLY)
    const [autoReplyChange, setAutoReplyChange] = useState(false)
    const [notificationsSettings, setNotificationsSettings] = useState<NotificationSettings>(null)
    const [notificationsSettingsTemp, setNotificationsSettingsTemp] = useState<NotificationSettings>(null)
    const hasChangeRef = useRef<boolean>(false)

    const autoReplyRef = useRef<AutoReplyHandle>()

    const classes = useStyles()
    const isLoading = !loaded || !autoReplyLoaded

    useEffect(() => {
        const init = async () => {
            const notificationsSettings = await api.getNotificationSettings()
            setNotificationsSettings(notificationsSettings)
            setNotificationsSettingsTemp(JSON.parse(JSON.stringify(notificationsSettings)))
            setLoaded(true)
        }

        init()
    }, [])

    const getChanges = useCallback(() => {
        const areObjectsSame = (o1, o2) => {
            return !Object.keys(o1).some(key => o1[key] !== o2[key])
        }

        const changes = []
        if (!loaded || !autoReplyLoaded) return changes
        // NOTE: Currently messages, faxes and voicemails are same so we can check only for one of them
        const callsChange = !areObjectsSame(notificationsSettings.calls, notificationsSettingsTemp.calls)
        const faxesChange = !areObjectsSame(notificationsSettings.faxes, notificationsSettingsTemp.faxes)
        const voicemailsChange = !areObjectsSame(notificationsSettings.voicemails, notificationsSettingsTemp.voicemails)
        if (callsChange || faxesChange || voicemailsChange) changes.push('notifications')
        if (autoReplyChange) changes.push('autoReply')
        return changes
    }, [notificationsSettings, notificationsSettingsTemp, autoReplyChange])

    useEffect(() => {
        if (!loaded || !autoReplyLoaded) return
        const hasChange = !!getChanges().length
        if (hasChangeRef.current !== hasChange) {
            hasChangeRef.current = hasChange
            props.setBusy(hasChange)
        }
    })

    const onSaveClick = async () => {
        const callsString = `${notificationsSettings.calls.send_email}->${notificationsSettingsTemp.calls.send_email}`
        const messagesFaxesVoicemailsString = `${notificationsSettings.messages.email}->${notificationsSettingsTemp.messages.email}`
        const attachFaxString = `${notificationsSettings.faxes.attach_fax}->${notificationsSettingsTemp.faxes.attach_fax}`
        const voicemailAttachmentTypeString = `${notificationsSettings.voicemails.attachment_type}->${notificationsSettingsTemp.voicemails.attachment_type}`
        if (saving) {
            gtmPush(`save;calls:${callsString};messages-faxes-voicemail:${messagesFaxesVoicemailsString};attachFax:${attachFaxString};voicemailsAttachment:${voicemailAttachmentTypeString}`, GTM_MAP.ALREADY_SAVING) // eslint-disable-line
            return
        }

        setSaving(true)

        const saveNotificationChanges = async () => {
            if (notificationsSettingsTemp.calls) {
                if (!notificationsSettingsTemp.calls.send_to) {
                    notificationsSettingsTemp.calls.send_to = 'custom' // eslint-disable-line
                }
                notificationsSettingsTemp.calls.status = notificationsSettingsTemp.calls.send_email || notificationsSettingsTemp.calls.send_sms
            }
            const data = {
                messages_voicemails_faxes: notificationsSettingsTemp.messages, // eslint-disable-line
                voicemail_attachment_type: notificationsSettingsTemp.voicemails.attachment_type, // eslint-disable-line
                email_attach_fax: notificationsSettingsTemp.faxes.attach_fax, // eslint-disable-line
                calls: notificationsSettingsTemp.calls,
                email: window.V5PHONECOM.email
            }
            await api.setNotificationSettings(data)
            const updatedNotificationSettings = JSON.parse(JSON.stringify(notificationsSettingsTemp))
            setNotificationsSettings(updatedNotificationSettings)
            gtmPush(`save;calls:${callsString};messages-faxes-voicemail:${messagesFaxesVoicemailsString};attachFax:${attachFaxString};voicemailsAttachment:${voicemailAttachmentTypeString}`, GTM_MAP.SAVE) // eslint-disable-line
        }

        const changes = getChanges()
        const savePromises = []
        if (changes.includes('notifications')) savePromises.push(saveNotificationChanges())
        if (changes.includes('autoReply')) savePromises.push(autoReplyRef.current?.save())
        await Promise.all(savePromises)
        setSaving(false)
    }

    return (
        <SettingsWrapper>
            {(isLoading || saving) && <Loader/>}
            {!isLoading && <DesktopNotifications {...props}/>}
            {!isLoading &&
                <EmailNotifications
                    {...props}
                    settings = {notificationsSettingsTemp}
                    update = {settings => setNotificationsSettingsTemp({ ...settings })}
                />
            }
            {/* <FeatureFlag flag={'REACT_APP_ENABLE_AUTO_REPLY'}> */}
            {process.env.REACT_APP_ENABLE_AUTO_REPLY &&
                <AutoReply
                    ref = {autoReplyRef}
                    setHasChange = {setAutoReplyChange}
                    onLoaded = {() => setAutoReplyLoaded(true)}
                />
            }
            {/* </FeatureFlag> */}
            {!isLoading && (
                <>
                    <div style={{ height: 1, marginBottom: -1 }}/>
                    <Button
                        disabled = {!getChanges().length || saving}
                        onClick = {onSaveClick}
                        classes = {{ root: classes.saveButton }}
                        data-test-id = 'save-notifications-button'
                    >Save</Button>
                </>
            )}
        </SettingsWrapper>
    )
}

export default Notifications
