/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Dialog, FormButtons } from 'functional-foundations'
import { DialogType } from 'functional-foundations/types'
import { getValue } from 'firebase-utils'
import UserNameAndNumber from './UserNameAndNumber'
import DialogLoader from './DialogLoader'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import { formatPhoneNumber } from 'phone-numbers'

const useStyles = makeStyles((t) => {
    const { typography } = t['foundations-library']
    return {
        userNameAndNumber: {
            marginTop: '20px',
            marginBottom: '50px'
        },
        text: {
            ...typography.body2,
            marginTop: '-10px'
        }
    }
})

/**
 *
 */
const TransitionSimDialog = ({
    onClose,
    ...props
}: DialogType) => {
    const texts = JSON.parse(getValue('transfer_dialog'))
    const styles = useStyles()
    const [loading, setLoading] = useState(false)
    // const { fetchEsims, terminateEsim, terminateEsimHandler } = useContext(EsimContext)
    // const { esimId } = useParams<{ esimId: string }>()
    const history = useHistory()

    const handleTransition = async () => {
        setLoading(true)
        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(3000)
        setLoading(false)
        onClose && onClose()
        history.goBack()
    }
    const { data: esimData } = useEsimOperationsContext()

    const { name, number } = useMemo(() => {
        let name
        if (esimData?.user?.extension && Object.keys(esimData.user.extension).length) {
            name = `Ext ${esimData.user.extension.number}: ${esimData.user.extension.name}`
        } else if (esimData?.user) {
            name = [esimData.user?.first_name, esimData.user?.last_name].filter((x) => x).join(' ')
        }

        return {
            name,
            number: formatPhoneNumber(esimData?.user?.did)
        }
    }, [esimData])

    return (
        <Dialog {...props} title={!loading && texts.title} closeOnClickOutside={!loading} width={520}>
            {loading && <DialogLoader title={texts.loading_title} subtitle={texts.loading_subtitle} data-testid={`${props['data-testid']}-loader`}/>}
            {!loading && <div data-testid={`${props['data-testid']}-content`}>
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: texts.text }} data-testid={`${props['data-testid']}-text`}/>
                <UserNameAndNumber
                    name={name}
                    number={number}
                    className={styles.userNameAndNumber}
                    data-testid={`${props['data-testid']}-usernameandnumber`}
                />
                <FormButtons
                    onConfirm={handleTransition}
                    onCancel={onClose}
                    confirmText={texts.confirm_button_text}
                    cancelText={texts.cancel_button_text}
                    confirmProps={{
                        color: 'destructive'
                    }}
                />
            </div>
            }
        </Dialog>
    )
}

export default TransitionSimDialog
