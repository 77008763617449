import React from 'react'
import DOMPurify from 'dompurify'
import { NavItem } from '../Navigation'

interface props {
    navItem: NavItem;
    propsToPass?: React.HTMLProps<HTMLSpanElement>
}

/**
 *
 */
const NavItemIcon = (props: props): JSX.Element | null => {
    if (!props.navItem.iconHTML) {
        return null
    }

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.navItem.iconHTML) // eslint-disable-line @typescript-eslint/naming-convention
            }}
            {...(props.propsToPass || {})}
        />
    )
}

export default NavItemIcon
