import React from 'react'
import { NavItem } from './Navigation'

interface NavItemLinkProps {
    href: string;
    target?: string;
}

interface NavigationContextType {
    basePath: string;
    goToNavItem: (navItemID: string) => void;
    getInitialNavItem: () => NavItem | null;
    isOnBasePath: () => boolean;
    getNavItems: () => NavItem[];
    getPathToNavItem: (navItemId: string) => string | null;
    getNavItemLinkProps: (navItemId: string) => NavItemLinkProps | null;
    getNavItemsByPath: (path: string, startingNavItem: (NavItem | null)) => NavItem[];
    getActiveNavItems: () => NavItem[];
    getInvalidPathNavItem: () => NavItem | null;
    getNavItemComponentType: (navItem: NavItem) => React.ComponentType | null;
    getBadgeComponentType: (badgeComponentName: string) => React.ComponentType | null;
}

/**
 * Context to be provided to everything nested in a navigation-poc functional component.
 * This provides access to information about nav items and how to link to them.
 */
export const NavigationContext = React.createContext<NavigationContextType>(null)
