import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useEsimOperationsContext } from './../../contexts/EsimOperationsContext'
import Button from './../../../components/Button'
// import TransitionSimDialog from './TransitionSimDialog'
import { details } from '../../../utils/objects'
import TerminateSimProcess from './terminate/index'
import { ButtonType } from 'foundations-library/types'

const useStyles = makeStyles({
    root: {

    }
})

/**
 *
 */
const TerminateSimBtn = (props: ButtonType): JSX.Element => {
    const classes = useStyles()
    const [openDialog, setOpenDialog] = useState(false)
    const { data } = useEsimOperationsContext()

    const handleOpenDialog = () => setOpenDialog(true)
    const handleCloseDialog = () => setOpenDialog(false)

    return (
        <div className={classes.root}>
            <Button {...props} variant='outlined' color='destructive' onClick={handleOpenDialog}>{!data?.deletion_requested ? details.terminateBtn : details.cancelTerminateBtn}</Button>
            <TerminateSimProcess start={openDialog} forceQuit={false} onClose={handleCloseDialog} />
        </div>
    )
}

export default TerminateSimBtn
