/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useContext, createContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { EsimContext, TeammateContext } from 'providers'
import api from 'api'
import { getPhoneCom } from 'phonecom'

/**
 *
 */
export type UserDataType = { // api puts them as optional
    first_name?: string,
    last_name?: string,
    email?: string
}
interface EsimOperationsContextInterface {
    isLoading: boolean
    data: Record<string, any> | null
    setData: React.Dispatch<React.SetStateAction<Record<string, any> | null>>
    selectedUser: Record<string, any> | null
    setSelectedUser: React.Dispatch<React.SetStateAction<Record<string, any> | null>>
    selectedPhone: string
    setSelectedPhone: React.Dispatch<React.SetStateAction<string>>
    selectedUserData: UserDataType,
    setSelectedUserData: (v: UserDataType) => any
    isSimAssigned: boolean
    setIsSimAssigned: React.Dispatch<boolean>
    openCompabilityDialog: boolean
    openAssigneeDialog: boolean
    handleOpenCompabilityDialog: () => void
    handleCloseCompabilityDialog: () => void
    handleOpenAssigneeDialog: () => void
    handleCloseAssigneeDialog: () => void
    phones: Record<string, any>[]
    setPhones: React.Dispatch<React.SetStateAction<Record<string, any>[]>>
    teammates: any,
    availableUsers: any,
    myEsims: boolean
    currentUser: Record<string, any>
}

/**
 *
 */
export const EsimOperationsContext = createContext<EsimOperationsContextInterface | Record<string, any>>({})
/**
 *
 */
export const useEsimOperationsContext = () => useContext(EsimOperationsContext)

/**
 *
 */
export type checkCompatibilityStatusType = 'pending' | 'in progress' | 'completed'

/**
 *
 */
const EsimOperationsProvider = ({ myEsims = false, children }: { myEsims?: boolean, children: JSX.Element }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState<Record<string, any> | null>(null)
    const [selectedUser, setSelectedUser] = useState<Record<string, any> | null>(null)
    const [selectedUserData, setSelectedUserData] = useState<UserDataType | null>(null)
    const [selectedPhone, setSelectedPhone] = useState('')
    const [isSimAssigned, setIsSimAssigned] = useState(false)
    const [openCompabilityDialog, setOpenCompabilityDialog] = useState(false)
    const [openAssigneeDialog, setOpenAssigneeDialog] = useState(false)
    const [phones, setPhones] = useState<Record<string, any>[]>([])
    const [currentUser, setCurrentUser] = useState<Record<string, any>>({})
    const { teammates } = useContext(TeammateContext)
    const { fetchPlans, fetchEsims, reset, getEsimUser } = useContext(EsimContext)
    const { esimId } = useParams<{ esimId: string }>()

    const handleOpenCompabilityDialog = () => setOpenCompabilityDialog(true)
    const handleCloseCompabilityDialog = () => setOpenCompabilityDialog(false)
    const handleOpenAssigneeDialog = () => setOpenAssigneeDialog(true)
    const handleCloseAssigneeDialog = () => {
        setOpenAssigneeDialog(false)
        reset('ASSIGN_USER')
    }
    const setUserInfo = (phoneCom, user) => {
        const isNxtUser = phoneCom.user_tiered as boolean

        let first_name = user.first_name
        let last_name = user.last_name
        let email = user.email

        if (isNxtUser && user.extension?.number) {
            first_name = user.extension?.first_name
            last_name = user.extension?.last_name
            email = user.extension?.email
        }

        setSelectedUserData({ first_name, last_name, email })
    }

    const availableUsers = useMemo(() => {
        if (!teammates?.items?.size) return []
        return Array.from(teammates.items, ([key, value]) => ({ ...value }))
            .filter(user => {
                if (data?.user && ((data.user.extension?.id || data.user.voip_phone_id) === user?.extension?.id)) return true
                return !getEsimUser(user.extension.id)
            })
    }, [teammates.items.size])

    useEffect(() => {
        (async () => {
            const [fetchedEsims, fetchedPlans, phoneCom] = await Promise.all([fetchEsims(myEsims), fetchPlans(), getPhoneCom()])
            const esim = fetchedEsims?.find(eSIM => eSIM.service_id === esimId)
            const plan = fetchedPlans?.find(plan => plan.billing_code === esim?.billing_code)

            if (esim) {
                setData({ ...esim, plan })
                if (esim.user) {
                    const user = esim.user
                    setSelectedUser(user)
                    setSelectedPhone(user.did)
                    setIsSimAssigned(true)
                    setUserInfo(phoneCom, user)

                    const { esim: phoneNumbers } = await api.getUserPhoneNumbersForEsim(user.voip_phone_id)
                    setPhones(phoneNumbers)
                }
            }

            setCurrentUser(phoneCom)

            setIsLoading(false)
        })()
    }, [])

    return (
        <EsimOperationsContext.Provider value={{
            isLoading,
            data,
            setData,
            selectedUser,
            setSelectedUser,
            selectedPhone,
            setSelectedPhone,
            selectedUserData,
            setSelectedUserData,
            isSimAssigned,
            setIsSimAssigned,
            openCompabilityDialog,
            openAssigneeDialog,
            handleOpenCompabilityDialog,
            handleCloseCompabilityDialog,
            handleOpenAssigneeDialog,
            handleCloseAssigneeDialog,
            phones,
            setPhones,
            teammates,
            availableUsers,
            myEsims,
            currentUser
        }}>
            {children}
        </EsimOperationsContext.Provider>
    )
}

export default EsimOperationsProvider
