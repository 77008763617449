<template>
    <svg width="40" height="40" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5Z" fill="#197F9F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.667 18.1666C11.667 18.4427 11.8908 18.6666 12.167 18.6666H13.667C13.9431 18.6666 14.167 18.4427 14.167 18.1666V9.83325C14.167 9.55711 13.9431 9.33325 13.667 9.33325L12.167 9.33325C11.8908 9.33325 11.667 9.55711 11.667 9.83325V18.1666ZM15.8337 18.1666C15.8337 18.4427 16.0575 18.6666 16.3337 18.6666H17.8337C18.1098 18.6666 18.3337 18.4427 18.3337 18.1666V9.83325C18.3337 9.55711 18.1098 9.33325 17.8337 9.33325L16.3337 9.33325C16.0575 9.33325 15.8337 9.55711 15.8337 9.83325V18.1666Z" fill="white"/>
    </svg>
</template>

<script>
  export default {};
</script>
