import React, { useContext } from 'react'
import { useValue } from 'firebase-utils'
import Spinner from 'spinner'
import NavigationProvider from './NavigationProvider'
import { BrowserRouter } from 'react-router-dom'

// Possible user interfaces:
import NavUIV1 from './NavUIV1/NavUIV1'
import NavUIV2 from './NavUIV2/NavUIV2'
import { ScreenSizeContext } from 'providers'
/**
 *
 */
export { NavigationContext } from './NavigationContext'

/**
 * The navigation configuration object typically sourced from remote config.
 */
export interface NavConfig {
    ui: string;
    navItems: NavItem[];

    // Upon entering the app, on "/" or the set basePath, the user will be
    // navigated to this nav item. Defaults to the first nav item in navItems[]
    initialNavItemID?: string;

    // if set the user is redirected to this nav item on invalid paths,
    // overrides the default behavior of showing the 404 / Page not found page.
    invalidPathNavItemID?: string;
}

/**
 *
 */
export interface NavItemLinkProps {
    href: string;
    target?: string;
    sendToken?: boolean;
}
/**
 * Represents an individual navigation item, a page that can be navigated to by
 * visiting URLs matching its route.
 */
export interface NavItem {
    id: string;
    title: string;
    route: {
        path: string;
    };
    link?: NavItemLinkProps // Alternatively the nav item can be just a link to some place outside of the app
    componentProps?; // additional data that will be sent to the component on render
    componentName?: string; // Only required for nav items without children (leaf nodes in the nav tree)
    badgeComponentName?: string;
    enabled?: boolean;
    userRoles?: string[]; // eg. ["account", "extension"], when omitted shows the nav item to all user roles
    userTypes?: string[]; // eg. ["nxt", "classic"], when omitted shows the nav item to all user types
    iconHTML?: string;
    pinned?: boolean;
    children?: NavItem[];
}

/**
 * Object mapping componentNames to the corresponding React component class/function
 */
export interface NavComponentMap {
    [componentName: string]: React.ComponentType;
}

const NavigationUIs = {
    NavUIV1,
    NavUIV2
}

interface NavigationProps {
    basePath: string;
    remoteConfigKey: string;
    componentMap: NavComponentMap;
    children: React.ReactChildren;
}

/**
 * Top-level navigation functional-component, that read navigation config and renders the
 * correct navigation UI.
 */
const Navigation = ({ basePath, remoteConfigKey, componentMap, children }: NavigationProps) => {
    const navConfigValue: string = useValue(remoteConfigKey, { defaultRequired: false })

    if (!navConfigValue) {
        return (
            <Spinner size="sm" />
        )
    }

    const navConfig: NavConfig = JSON.parse(navConfigValue)

    if (!navConfig.ui) {
        // A fatal error, with no viable fallback, so throw an error (would cause an error page)
        throw new Error('NavConfig: no UI selected, see navConfig.ui')
    }

    if (!(navConfig.ui in NavigationUIs)) {
        // This is also a fatal error, with no viable fallback, so throw an error (would cause an error page)
        throw new Error(`NavConfig invalid value for ui field: ${navConfig.ui}, valid options: ${Object.keys(NavigationUIs)}`)
    }

    let NavigationUI = NavigationUIs[navConfig.ui]

    // Temporary code: (delete once NavUIV2 fully supports all sizes of screens)
    const screenSize = useContext(ScreenSizeContext)
    const isSmallView = screenSize.mobile || screenSize.tablet
    if (NavigationUI === NavUIV2 && isSmallView) {
        NavigationUI = NavUIV1
    }
    // ^ End of temporary code

    return (
        <BrowserRouter>
            <NavigationProvider
                basePath={basePath}
                navConfig={navConfig}
                componentMap={componentMap}
            >
                {children}
                <NavigationUI />
            </NavigationProvider>
        </BrowserRouter>
    )
}

export default Navigation
