import React, { useState, useEffect } from 'react'
import CommonInput from '../CommonInput/CommonInput.tsx'
import CommonInputType from '../../helpers/types/CommonInput.interface.ts'
import Icon from '../Icon/Icon.tsx'
import style from './style.ts'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '../Menu/Menu.tsx'

const useStyles = makeStyles(style)

type HtmlInputProps = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'onChange' | 'size'>
type Option = { value?: any; text?: string, title?: boolean, selected?: boolean, icon?: string, disabled?: boolean, renderComponent?: JSX.Element }
interface PropsInterface extends HtmlInputProps, CommonInputType {
    onClear?(): any
    onChange?(v: any): any
    options: Option[]
    icon?: React.ReactNode
    disabled?: boolean,
    selectMultiple?: boolean,
    withIcon?: boolean;
    required?: boolean;
    returnObject?: boolean;
    onOpen?: () => void
}

const getOptions = (initialOptions: Option[]) => initialOptions.map(initialOption => {
    const newOption: Record<string, any> = { ...initialOption }
    if (newOption.renderComponent) newOption.text = newOption.renderComponent
    return newOption
})

/**
 *
 */
const Select = ({
    size = 'large',
    rules,
    isValid,
    helperText,
    onChange,
    options: initialOptions = [{ disabled: true, text: 'No data', value: null }],
    disabled = false,
    value,
    selectMultiple = false,
    returnObject = false,
    required = false,
    withIcon,
    title = 'Select an option',
    fullWidth = false,
    onOpen,
    className,
    'data-testid': dataTestId = 'select',
    ...props
}: PropsInterface): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState(value || '')
    const [selectedItemIcon, setSelectedItemIcon] = useState('')
    const [options, setOptions] = useState(getOptions(initialOptions))
    const originalOptions = initialOptions.map(initialOption => ({ value: initialOption.value, text: initialOption.text }))

    const classes = useStyles({ isOpen, selectedValue, selectedItemIcon, disabled })

    const onSelect = (val: any) => {
        const item = options.find(opt => opt.value === val)
        if (item) {
            if (item.icon && !selectMultiple) setSelectedItemIcon(item.value === selectedValue && !required ? '' : item.icon)
            if (selectMultiple) {
                const updatedOptions = options.map(opt =>
                    opt.value === item.value ? { ...opt, selected: !opt.selected } : opt
                )
                setOptions(updatedOptions)
                const selected = updatedOptions.filter(opt => opt.selected)
                setSelectedValue(selected)
                const value = returnObject ? selected : selected.map(x => x.value)
                if (onChange) onChange(value)
            } else {
                setSelectedValue(item.value === selectedValue && !required ? '' : item.value)
                setIsOpen(false)
                const value = returnObject ? item : item.value
                if (onChange) onChange(value)
            }
        }
    }

    const toggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen)
            if (!isOpen && onOpen) onOpen()
        }
    }

    useEffect(() => {
        setOptions(getOptions(initialOptions))
    }, [JSON.stringify(originalOptions)])

    useEffect(() => {
        const item = options.find(opt => opt.value === value)
        setSelectedValue(item?.value || '')
    }, [value])

    return (
        <CommonInput
            value={(options && (options.find(x => x.value === selectedValue)?.text || null)) || null}
            key={'SelectInput'}
            rules={rules}
            isValid={isValid}
            helperText={helperText}
            size={size}
            fullWidth={fullWidth}
            className={className}
            label={props.label}
            data-testid={dataTestId}
            leadingIcon={props.leadingIcon}
            onClickLeadingIcon={props.onClickLeadingIcon}
            trailingIcon={props.trailingIcon}
            onClickTrailingIcon={props.onClickTrailingIcon}
            disabled={disabled}
        >
            <div
                className={`${classes.container} select ${isOpen && 'input--focused'}`}
                data-testid={dataTestId}
            >
                <div onClick={toggleDropdown} className={classes.selectConatiner} data-testid={`${dataTestId}-select-toggle`}>
                    {selectedItemIcon && !selectMultiple && (
                        <div className={classes.icon}>
                            <Icon name={selectedItemIcon.toString()} color={'primary'} width={24} height={24} />
                        </div>
                    )}

                    <div className={classes.select}>
                        {
                            !selectMultiple
                                ? (options.find(x => x.value === selectedValue)?.text || title)
                                : (options.filter(x => x.selected).length > 0
                                    ? `${options.filter(x => x.selected).length} selected`
                                    : title
                                )
                        }
                    </div>

                    <span className={classes.chevron} data-testid={`${dataTestId}-chevron`}>
                        {isOpen
                            ? <Icon name='menu-expanded' color={disabled ? 'neutral-300' : 'neutral-900'} size={24} />
                            : <Icon name='menu-collapsed' color={disabled ? 'neutral-300' : 'neutral-900'} size={24} />}
                    </span>
                </div>

                <Menu
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    options={options}
                    withIcon={withIcon}
                    value={selectedValue}
                    selectMultiple={selectMultiple}
                    onChange={onSelect}
                    disabled={disabled}
                    fullWidth={true}
                    className={`${disabled ? 'select-menu-disabled' : ''} select-menu`}
                    data-testid={`${dataTestId}-menu`}
                />
            </div>
        </CommonInput>
    )
}

/**
 *
 */
export type SelectType = PropsInterface
/**
 *
 */
export type SelectOptionType = Option

export default Select
