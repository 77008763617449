import Resource from './Resource'
import setup from '../libs/resources-setups/media'

/**
 *
 */
export default class Media extends Resource {
    /**
     *
     * @param {object} session
     * @param {string} type
     * @param {object} component
     */
    constructor (session, type, component) {
        super(session, component, '/media')
        this.selector_mode = ['hold_music', 'greetings']
        this.setup = setup
        this.type = type
        this.playing = null
        this.filters = {
            name: null,
            exetnsion: null,
            include_system_media: false
        }
        this.item = {
            name: '',
            type: 'greeting',
            notes: null,
            origin: 'tts',
            randomized: false,
            tts: {
                voice: 'Joanna / English (US)',
                text: ''
            }
        }
        this.excluded_ids_filter = '&filters[ownership]=user'
        this.uri = `/media?filters[type]=${this.type}${this.excluded_ids_filter}`
    }

    /**
     *
     */
    checkAll () {
        super.checkAll()
        if (!this.items.length) return null

        for (const item of this.items) {
            if (item.id < 24) item.selected = false
        }

        return true
    }

    /**
     * @param {object} params
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)

            let { uri } = this
            if (!this.filters.include_system_media && !this.uri.includes(this.excluded_ids_filter)) {
                uri = `${uri}${this.excluded_ids_filter}`
            }
            const items = await this.session.get_list(`${uri}`, params.limit, offset)

            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) {
                return this.component.routerPush('media.create')
            }
            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     */
    clear_filters () {
        this.filters = { ...this.empty_filters }
        this.filters_applied = false
        this.keep_filters_open = true
        this.uri = `/media?filters[type]=${this.type}${this.excluded_ids_filter}`
    }

    /**
     * @param {object} media
     */
    async create (media) {
        this.loading = true
        const initialType = media.origin
        try {
            let uri = '/media'
            if (media.extension) {
                uri = `/extensions/${media.extension}${uri}`
            }
            if (media.origin === 'recording') {
                media.origin = 'file'
            }
            const newMedia = await this.session.create_item(uri, media)
            if (newMedia.type === 'greeting') {
                this.addToCache(newMedia, 'greetings')
            } else {
                this.addToCache(newMedia, newMedia.type)
            }
            this.successfulCreation(newMedia.type === 'greeting' ? 'media.index' : 'media.hold-music', null, null, newMedia)
        } catch (err) {
            this.validation_error(err)
            if (initialType === 'recording') {
                media.origin = 'recording'
            }
        }
        this.loading = false
    }

    /**
     * @param {number} id
     */
    async getItem (id) {
        this.loading = true

        try {
            let media = await this.session.get_item(`/media/${id}`)
            media = this.constructor.checkTts(media)

            this.item = media
        } catch (err) {
            this.validation_error(err, true)
        }
        this.loading = false
    }

    /**
     * @param {object} data
     */
    static checkTts (data) {
        const media = data
        if (media.tts) {
            if (
                media.tts.voice &&
                !setup.available_voices.includes(media.tts.voice) &&
                media.tts.voice.length === 20
            ) {
                const voice = setup.available_voices.find((x) => x.startsWith(media.tts.voice))
                if (voice) {
                    console.log(3)
                    media.tts_voice = voice
                }
            } else {
                media.tts_voice = media.tts.voice
            }
            media.tts_text = media.tts.text
        } else {
            media.tts_text = null
            media.tts_voice = null
        }
        media.tts = undefined

        return media
    }

    /**
     * @param {object} media
     */
    async update (media) {
        this.loading = true
        const initialType = media.origin
        try {
            let uri = `/media/${this.item.id}`
            if (media.extension) {
                uri = `/extensions/${media.extension}${uri}`
            }
            if (media.origin === 'recording') {
                media.origin = 'file'
            }
            const item = await this.session.replace_item(uri, media)
            this.item = this.constructor.checkTts(item)
            if (this.item.type === 'greeting') {
                this.updateCache(this.item, 'greetings')
            } else {
                this.updateCache(this.item, this.item.type)
            }
            const { type } = this.item
            this.successfulUpdate(type === 'greeting' ? 'media.index' : 'media.hold-music')
            this.item = null
        } catch (err) {
            this.validation_error(err)
            if (initialType === 'recording') {
                media.origin = 'recording'
            }
        }
        this.loading = false
    }

    /**
     *
     */
    apply_filters () {
        this.filters_applied = true
        let uri = ''
        if (this.filters.extension) {
            uri = `/extensions/${this.filters.extension}`
        }
        uri = `${uri}${this.baseUri}?filters[type]=${this.type}`
        if (this.filters.name) {
            uri = `${uri}&filters[name]=contains:${this.filters.name}`
        }
        uri = `${uri}${this.filters.include_system_media ? '' : this.excluded_ids_filter}`
        this.uri = uri
        this.hide_filters = false
    }
}
