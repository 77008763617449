import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from 'button'
import Typography from 'typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { appIconPlaceholder as inlineAppIcon } from '../utils/inlineIcons'
import useProgressiveImg from '../hooks/useProgressiveImg'
import { App as AppObjInterface, AppType } from '../utils/objects'
import { getBasePath, openExternalApp, processLink } from '../utils/utils'
import { AppDialog } from './AppDialog'
import { arrowIcon } from '../utils/inlineIcons'

const useStyles = makeStyles(theme => ({
    appContainer: {
        padding: '30px 20px 30px 30px',
        position: 'relative',
        borderRadius: '1.25rem',
        boxShadow: '0px 0px 2px -17px rgba(0,0,0,0.2), 0px 0px 4px 0px rgba(0,0,0,0.14), 0px 6px 10px 0px rgba(0,0,0,0.12)',
        height: '100%',
        '&:hover': {
            backgroundColor: theme.palette.grey[50]
        }
    },
    installedTitle: {
        color: '#fff!important',
        padding: '0.4rem 1rem',
        borderRadius: '1rem',
        backgroundColor: theme.palette.grey[400],
        position: 'absolute',
        top: '.5rem',
        right: '.5rem'
    },
    appIconWrapper: {
        display: 'inline-block',
        position: 'relative',
        '&::before': {
            content: ({ imgLoading }: { imgLoading: boolean }) => imgLoading ? '""' : 'none',
            position: 'absolute',
            inset: 0,
            animation: '$pulse 2s infinite',
            zIndex: 1
        }
    },
    '@keyframes pulse': {
        '0%': {
            backgroundColor: 'rgba(255, 255, 255, 0)'
        },
        '50%': {
            backgroundColor: 'rgba(255, 255, 255, .2)'
        },
        '100%': {
            backgroundColor: 'rgba(255, 255, 255, 0)'
        }
    },
    appIcon: {
        width: '100px',
        height: '100px',
        transition: 'filter .5s ease-out',
        filter: ({ imgLoading }: { imgLoading: boolean }) => imgLoading ? 'blur(1px)' : 'none'
    },
    titlesContainer: {
        margin: '1.25rem 0'
    },
    appTitle: {
        fontSize: '16px!important',
        fontWeight: '600!important',
        cursor: 'pointer'
    },
    appCompanyTitle: {
        color: '#8C8C8C'
    },
    appDescription: {
        color: '#404040',
        fontWeight: '500',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 4,
        overflow: 'hidden',
        margin: '1.25rem 0 calc(1.25rem + 22px) 0'
    },
    learnMoreBtn: {
        position: 'absolute',
        bottom: '30px',
        border: 0,
        padding: 0,
        minWidth: 'auto',
        color: '#30991C!important',
        "& > [class*='MuiTypography-root']": {
            fontSize: '14px!important',
            fontWeight: '600!important'
        },
        '& > svg': {
            marginLeft: '4px',
            fontSize: '18px'
        },
        '&:hover': {
            backgroundColor: 'transparent!important',
            '&::before': {
                content: "''",
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '10%',
                backgroundColor: '#30991C!important'
            }
        }
    },
    learnMoreIcon: {
        fontSize: '1rem'
    },
    installedBadge: {
        position: 'absolute'
    },
    arrowIcon: {
        marginLeft: '5px'
    }
}))

interface AppInterface {
    app: AppObjInterface
}

/**
 *
 */
const App = ({ app }: AppInterface): JSX.Element => {
    const [src, { imgLoading }] = useProgressiveImg(app.inlineIcon || inlineAppIcon, app.icon)
    const classes = useStyles({ imgLoading })
    const [modalState, setModalState] = useState(false)

    const history = useHistory()
    const routeMatch = useRouteMatch()

    const handleCloseModal = () => {
        setModalState(false)
    }

    const goToDetailsPage = () => {
        const basePath = getBasePath(routeMatch)
        const appURI = encodeURI(app.title.toLowerCase())
        history.push(`${basePath}/${appURI}`)
    }

    const openApp = () => {
        switch (app.appType) {
                case AppType.Modal:
                    setModalState(true)
                    break
                case AppType.Integrated:
                    goToDetailsPage()
                    break
                default:
                    openExternalApp(app, history)
                    break
        }
    }

    const learnMoreHandler = (ev: React.MouseEvent<HTMLElement>) => {
        ev.stopPropagation()
        processLink(app.learnMoreLink, history)
    }

    const description = useMemo(() => {
        if (app.details.description) return app.details.description

        if (typeof app?.details?.overview?.general === 'string') {
            return app.details.overview.general
        }

        return ''
    }, [app])

    return (
        <>
            <Box style={{ cursor: 'pointer' }} className={classes.appContainer} onClick={openApp}>
                {/* <AppIcon icon={app.icon} size='medium' /> */}
                <Box className={classes.appIconWrapper}>
                    <img src={src} alt={`${app.title} icon`} className={classes.appIcon} />
                </Box>
                <Box className={classes.titlesContainer}>
                    <Typography variant='h6' className={classes.appTitle}>{app.title}</Typography>
                    <Typography variant='body2' className={classes.appCompanyTitle}>{app.company}</Typography>
                </Box>
                <Typography variant='body2' className={classes.appDescription}>
                    {description}
                </Typography>
                {app.learnMoreLink &&
                    <Button color='primary' variant='outlined' className={classes.learnMoreBtn} onClick={learnMoreHandler}>
                        <Typography variant='body1'>{app.learnMoreText}</Typography>
                        <img src={arrowIcon} alt='arrow icon' className={classes.arrowIcon} />
                    </Button>
                }
            </Box>
            {/* <AppModal isOpen={modalState}>
                <ModalChild app={app} onClose={handleCloseModal} />
            </AppModal> */}
            <AppDialog app={app} onClose={handleCloseModal} open={modalState} />
        </>
    )
}

// eslint-disable-next-line no-unused-vars
const ModalChild = ({ app, onClose }: { app: AppInterface, onClose: () => void }): JSX.Element => {
    const iconBtnStyle = {
        position: 'absolute',
        top: '8px',
        right: '8px',
        color: '#9e9e9e',
        padding: '8px'
    }

    return (
        <Box>
            <IconButton aria-label="close" style={iconBtnStyle} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <DialogTitle>App Modal</DialogTitle>
        </Box>
    )
}

export default App
