<template>
  <svg width="40" height="40" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5Z" fill="#197F9F"/>
    <rect x="10" y="10" width="9" height="9" rx="1" fill="white"/>
  </svg>
</template>

<script>
  export default {};
</script>
