import App from './components/App'
import Features from './components/Features'
import Item, { ItemType } from './components/Item'

/**
 *
 */
export type { ItemType as FeatureType }

/**
 *
 */
export {
    Features,
    Item as Feature
}

export default App
