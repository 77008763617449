import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MuiDialog from '@material-ui/core/Dialog'

const useStyles = makeStyles(theme => ({
    videoDialog: {
        '& [role=dialog]': {
            padding: 0,
            '& video': {
                borderRadius: '20px'
            }
        }
    },
    videosRoot: {
        maxWidth: '100%'
    }
}))

/**
 *
 */
export const Video = ({ open, onClose, sources, attributes, autoPlay, width, height }: { open: boolean, onClose: (ev) => void, sources: React.SourceHTMLAttributes<HTMLSourceElement>[], attributes?: React.VideoHTMLAttributes<HTMLVideoElement>, autoPlay: boolean | undefined, width: number | string | undefined, height: number | string | undefined }) => {
    const classes = useStyles()
    let isYoutubeSource = false
    if (sources && sources.length !== 0 && sources[0]?.src) {
        const src = sources[0].src
        isYoutubeSource = src.includes('youtube.com') || false
        if (isYoutubeSource && autoPlay) {
            const hasQuery = src.includes('?')
            // Append autoplay and mute parameters accordingly
            const autoplayParams = (hasQuery ? '&' : '?') + 'autoplay=1'
            sources[0].src = src + autoplayParams
        }
    }

    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            aria-labelledby="video-dialog"
            data-testid='video-popup'
            className={classes.videoDialog}
        >
            {
                sources && sources.length !== 0
                    ? (isYoutubeSource)
                        ? <iframe className={classes.videosRoot} width={width} height={height || (typeof width === 'number') ? (width / 1.778) : 360} src={sources[0].src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}>

                        </iframe>
                        : <video className={classes.videosRoot} width={width} height={height} controls preload='metadata' {...attributes} autoPlay={autoPlay || false}>
                            {sources.map((source, index) => <source key={index} {...source} src={source.src}></source>)}
                            Your browser does not support the video tag.
                        </video>
                    : <p>No video sources found</p>
            }
        </MuiDialog>
    )
}
