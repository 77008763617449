import React, { useContext, useMemo } from 'react'
import { Typography, Icon, Button } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core/styles'
import { getVerbalColors } from 'helpers'
import { EsimContext, ScreenSizeContext, ThemeContext } from 'providers'
import { details } from './../../../utils/objects'

const useStyles = makeStyles(theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: ({ screenSize }) => screenSize.mobile ? 'column' : 'row',
            alignItems: 'center',
            padding: '10px 10px 10px 20px',
            gap: '20px',
            borderRadius: '0px 0px 12px 12px',
            background: 'linear-gradient(90deg, rgba(42, 173, 147, 0.80) 1.31%, rgba(160, 219, 207, 0.80) 91.28%)'
        },
        QRPoints: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            '& .qr-points': { position: 'relative', left: '20px', margin: 0 }
        },
        QRSubtitleContainer: {
            display: 'flex',
            columnGap: '10px',
            alignItems: 'center',
            '& .notification-alert': {
                alignSelf: 'start'
            },
            '& .notification-alert path': {
                // fill: colors.neutral[900]
            }
        },
        QRScanContainer: {
            backgroundColor: '#fff',
            display: 'flex',
            padding: '16px 16px 12px 16px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            rowGap: '14px',
            borderRadius: '10px',
            border: `1px solid ${verbalColors['neutral-100']}`,
            '& .qr-image': {
                transition: 'filter 1s ease-out'
            },
            '& .refresh-qr-code-btn': {
                '& svg': {
                    animationName: '$rotate',
                    animationDuration: ({ isQRCodeLoading }: { isQRCodeLoading: boolean }) => isQRCodeLoading ? '1s' : '0s',
                    animationTimingFunction: 'linear',
                    animationIterationCount: 'infinite'
                }
            }
        },
        '@keyframes rotate': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(360deg)' }
        }
    }
})

/**
 *
 */
const QRInstallation = ({ data }: { data: Record<string, any> }) => {
    const screenSize = useContext(ScreenSizeContext)
    const themeContext = useContext(ThemeContext)
    const { fetchEsims, refreshQRCode, refreshQRCodeHandler } = useContext(EsimContext)
    const classes = useStyles({ isQRCodeLoading: refreshQRCode.isLoading, screenSize })
    const { installation } = details
    const handleRefreshQRCode = async () => {
        const response = await refreshQRCodeHandler(data!.service_id)
        if (response) {
            fetchEsims(false, true)
        } else console.error('Error refreshing QR code')
    }

    const verbalColors = useMemo(() => {
        const { colors } = themeContext?.['foundations-library'] ?? {}
        return getVerbalColors(colors)
    }, [])

    return (
        <div className={classes.root}>
            <div className={classes.QRPoints}>
                <ul className='qr-points'>
                    {
                        installation.QRCode.points.map(({ point, info }) => (
                            <li key={point} data-testid='QRPoint'>
                                <Typography variant='subtitle1' color='neutral-900'>{`${point}: ${info}`}</Typography>
                            </li>
                        ))
                    }
                </ul>
                <div className={classes.QRSubtitleContainer}>
                    <Icon className='notification-alert' name='notification-alert' size='medium' colors={[verbalColors['neutral-900'], verbalColors['neutral-500']]} />
                    <Typography variant='body3' color='neutral-900'>{installation.QRCode.subtitle}</Typography>
                </div>
            </div>
            <div className={classes.QRScanContainer}>
                <img src={refreshQRCode.data?.esim?.activation_qr_code || data.esim.activation_qr_code} alt='QR Code' className='qr-image' style={{ filter: refreshQRCode.isLoading ? 'blur(2px)' : 'none' }} />
                <Button className='refresh-qr-code-btn' size='small' variant='text' color='primary' icon='refresh' iconLeft={true} onClick={handleRefreshQRCode} disabled={refreshQRCode.isLoading}>{!refreshQRCode.isLoading ? installation.QRCode.refresh : installation.QRCode.refreshing}</Button>
            </div>
        </div>
    )
}

export default QRInstallation
