import React, { useContext } from 'react'
import { DefaultTooltip } from 'tooltips'
import IconButton from 'icon-button-mui'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core'
import { SoftphoneContext } from 'softphone'
import { FeatureEventsContext, UserContext } from 'providers'
import { DialpadIcon } from './TopbarIcons'

const useStyles = makeStyles(theme => ({
    tooltipPopper: {
        width: 211
    },
    tooltip: {
        padding: '8px 12px',
        textAlign: 'center'
    },
    iconButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        padding: '8px 7px 6px 7px',
        backgroundColor: '#AADBA0',
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
        '&.Mui-disabled': {
            backgroundColor: theme.palette.secondary.background
        }
    }
}))

/**
 * Dialpad button used to open the softphone.
 */
const DialpadButton = (): JSX.Element => {
    const userInfo = useContext(UserContext)
    const isVirtualExtension = !!userInfo.extension?.is_virtual

    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const softphoneContext = useContext(SoftphoneContext)

    const ButtonWrapper = isVirtualExtension ? 'div' : React.Fragment

    return (
        <DefaultTooltip
            classes = {{
                popper: classes.tooltipPopper,
                tooltip: classes.tooltip
            }}
            title = {
                isVirtualExtension
                    ? <Typography
                        variant='tooltip'
                        remoteConfigID='disabled_call_buttons_tooltip_text_virtual_extension'
                    />
                    : ''
            }
            placement = 'bottom'
        >
            <ButtonWrapper>
                <IconButton
                    data-testid="dialpad-button"
                    className={classes.iconButton}
                    disabled={isVirtualExtension}
                    onClick={() => {
                        featureEventsContext.pushEvent({
                            appName: 'softphone',
                            key: 'top-bar-dialpad-button-click',
                            value: !isVirtualExtension
                        })
                        softphoneContext.ToggleSoftphone()
                    }}
                >
                    <DialpadIcon />
                </IconButton>
            </ButtonWrapper>
        </DefaultTooltip>
    )
}

export default DialpadButton
