import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button } from 'foundations-library/components'
import { NavigationContext } from 'navigation'
import { getValue } from 'remote-config-value'
import OrderPlaced from './../../../../../icons/OrderPlaced.jpg'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '25px',
        padding: '20px 0'
    }
}))

/**
 *
 */
const Index = () => {
    const classes = useStyles()
    const { goToNavItem } = useContext(NavigationContext)

    return (
        <div className={classes.root} data-testid='order-placed'>
            <Typography align='center' variant='h4' color='neutral-900'>Congratulations!</Typography>
            <Button variant='contained' size='large' iconLeft={false} icon='go' onClick={() => goToNavItem(getValue('nav_esims_id'))}>Get Started</Button>
            <img src={OrderPlaced} alt='order-placed' />
        </div>
    )
}

export default Index
