import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NavItem } from '../Navigation'
import SidebarV1NavItem from './SidebarV1NavItem'
import ProfileMenu from '../../../apps/communicator/src/nav/ProfileMenu'
import { LeaveContext, ScreenSizeContext, UserContext } from 'providers'
import { logout } from 'phonecom'

const useStyles = makeStyles(theme => ({
    sidebar: {
        backgroundColor: theme.palette.paper.gray,
        flexGrow: 0,
        minWidth: '250px',
        maxWidth: '250px',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        overflow: 'auto'
    }
}))

interface props {
    topLevelNavItems: NavItem[];
}

/**
 * Sidebar that renders nested menus of nav items.
 */
const SidebarV1 = ({ topLevelNavItems }: props): JSX.Element | null => {
    const classes = useStyles()

    const userInfo = useContext(UserContext)
    const screenSize = useContext(ScreenSizeContext)

    const leaveContext = useContext(LeaveContext)

    return (
        <div className={classes.sidebar}>
            <ProfileMenu
                userInfo={userInfo}
                currentUser={userInfo.extension}
                inboxesData={{}} // Optional data like unread counts - can be sourced from Communicator or left out intentionally
                extensionSelectHandler={ext => leaveContext.leaveCurrentExtension(ext)}
                screenViewType={{ isMobileView: screenSize.mobile, isTabletView: screenSize.tablet }}
                logout={logout}
            />

            {topLevelNavItems.map(navItem => (
                <SidebarV1NavItem
                    key={navItem.id}
                    navItem={navItem}
                />
            ))}
        </div>
    )
}

export default SidebarV1
