import React, { useContext } from 'react'
import { NavigationContext } from './NavigationContext'
import { NavComponentMap, NavConfig, NavItem, NavItemLinkProps } from './Navigation'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { UserContext } from 'providers'

interface props {
    basePath: string;
    navConfig: NavConfig;
    componentMap: NavComponentMap;
    children: React.ReactNode;
}

/**
 * Provides a navigation context that is used to provide useful high-level helper
 * functions for working with the navigation system.
 */
const NavigationProvider = ({ basePath, navConfig, componentMap, children }: props) => {
    useRouteMatch() // re-render on URL changes

    const history = useHistory()

    const userInfo = useContext(UserContext)
    const userType = userInfo.user_tiered ? 'nxt' : 'classic'

    const isNavItemAccessible = (navItem: NavItem): boolean => {
        if (navItem.userRoles && !navItem.userRoles.includes(userInfo.role)) {
            return false
        }

        if (navItem.userTypes && !navItem.userTypes.includes(userType)) {
            return false
        }

        return true
    }

    const getAccessibleNavItemsRecursive = (navItems: NavItem[]): NavItem[] => {
        const result: NavItem[] = []
        navItems.forEach(navItem => {
            let children
            if (isNavItemAccessible(navItem)) {
                if (navItem.children?.length) {
                    children = getAccessibleNavItemsRecursive(navItem.children)
                    if (children.length) {
                        const updatedNavItem = Object.assign({}, navItem, { children })
                        result.push(updatedNavItem)
                    } else {
                        result.push(navItem)
                    }
                } else {
                    result.push(navItem)
                }
            }
        })
        return result
    }

    const getNavItems = (): NavItem[] => {
        const navItemsClone = JSON.parse(JSON.stringify(navConfig.navItems || []))

        return getAccessibleNavItemsRecursive(navItemsClone)
    }

    const raiseNavConfigError = (errorMessage: string) => {
        // Note on error handling: console.error() is being used over throwing exceptions to minimize
        // the impact on the UX in the event of nav config errors. Ultimately these console.error() messages
        // are still reported to error analytics tools.
        console.error(`NavConfig Error: ${errorMessage}`)
    }

    const getNavItemRecursive = (navItemId: string, startingNavItem: null | NavItem = null, pathSoFar: string | null = basePath || '/'): null | [NavItem, string | null] => {
        let children: NavItem[] = []
        if (startingNavItem) {
            if (startingNavItem.id === navItemId) {
                return [startingNavItem, pathSoFar]
            } else {
                children = startingNavItem.children || []
            }
        } else {
            children = navConfig.navItems || []
        }

        for (let i = 0; i < children.length; i++) {
            const currentNavItem = children[i]

            if (!currentNavItem.route?.path && !currentNavItem.link?.href) {
                raiseNavConfigError(`Nav Item with id: ${currentNavItem.id} must have either a configured route.path or a link.href value`)
                return null
            }

            const result = getNavItemRecursive(navItemId, currentNavItem, currentNavItem.route ? `${pathSoFar}/${currentNavItem.route.path}` : null)
            if (result) {
                return result
            }
        }
        return null
    }

    const findNavItemByID = (navItemId: string): NavItem | null => {
        const result = getNavItemRecursive(navItemId)
        if (!result) {
            raiseNavConfigError(`Could not find nav item with ID: ${navItemId} in the configured nav item hierarchy`)
            return null
        } else {
            return result[0]
        }
    }

    const getInitialNavItem = (): NavItem | null => {
        if (navConfig.initialNavItemID) {
            const result = getNavItemRecursive(navConfig.initialNavItemID)
            if (!result) {
                raiseNavConfigError(`navConfig.initialNavItemID: ${navConfig.initialNavItemID} is set, but does not refer to a valid nav item in the nav item hierarchy`)
                return null
            }
            return result[0] || null
        } else if (!(navConfig.navItems || []).length) {
            raiseNavConfigError('NavConfig must have at least one nav item')
            return null
        } else {
            return navConfig.navItems[0] || null
        }
    }

    const getPathToNavItem = (navItemId: string): string | null => {
        const result = getNavItemRecursive(navItemId)
        if (!result) {
            raiseNavConfigError(`Unable to find nav item with id: ${navItemId} in nav item hierarchy`)
            return null
        }
        return result[1]
    }

    const getNavItemLinkProps = (navItemId: string): NavItemLinkProps | null => {
        const result = getNavItemRecursive(navItemId)

        if (!result) {
            raiseNavConfigError(`Unable to find nav item with id: ${navItemId} in nav item hierarchy`)
            return null
        }

        return result[0].link || null
    }

    const getInvalidPathNavItem = (): (NavItem | null) => {
        if (!navConfig.invalidPathNavItemID) {
            return null
        }
        return findNavItemByID(navConfig.invalidPathNavItemID)
    }

    const isOnBasePath = (): boolean => {
        const currentPath = window.location.pathname
        return currentPath.replace(/\/+$/g, '') === basePath
    }

    /**
     * Traverses the nav item hierachy from top to bottom using the given path,
     * returning the sequence of nav items matching to the path.
     *
     *  Results are in order:
     *  [top-most nav item, 2nd-level nav item, 3rd-level nav item, ...]
     */
    const getNavItemsByPath = (path: string, currentNavItem: (NavItem | null) = null): NavItem[] => {
        const activeNavItems: NavItem[] = []

        let currentPath = basePath
        const currentChildren = currentNavItem ? (currentNavItem.children || []) : (navConfig.navItems || [])

        let accessibleCurrentChildren = getAccessibleNavItemsRecursive(currentChildren)

        let matchingChild: NavItem | undefined
        do {
            matchingChild = accessibleCurrentChildren.find(currentChild => {
                if (!currentChild.route) {
                    return false
                }
                const currentChildPath = `${currentPath}/${currentChild.route.path}`
                return path.startsWith(currentChildPath)
            })

            if (matchingChild) {
                activeNavItems.push(matchingChild)
                currentPath = `${currentPath}/${matchingChild.route.path}`
                accessibleCurrentChildren = getAccessibleNavItemsRecursive(matchingChild.children || [])
            }
        } while (matchingChild)

        return activeNavItems
    }

    /**
     * Gets the array of active nav items (from the top-most level of nav items,
     * to the bottom-most) with the nav item hierarchy, matching the current window.location.
     */
    const getActiveNavItems = (): NavItem[] => {
        return getNavItemsByPath(window.location.pathname)
    }

    const getNavItemComponentType = (navItem: NavItem): React.ComponentType | null => {
        // Check to see if nav item needs to have a componentName:
        // leaf nodes in the nav tree must have a configured componentName, parent nav items (may optional have a component associated with them)
        if (!navItem.children && !navItem.componentName && !navItem.link) {
            raiseNavConfigError('NavItems (excluding parent nav items) must have a configured navItem.componentName')
            return null
        }

        // If NavItem specifies a componentName it must exist in the componentMap
        if (navItem.componentName) {
            if (!(navItem.componentName in componentMap)) {
                raiseNavConfigError(`NavItem componentName: ${navItem.componentName} does not refer to an existing component inside the given componentMap`)
                return null
            }
        } else {
            return null
        }

        return componentMap[navItem.componentName]
    }

    const getBadgeComponentType = (badgeComponentName: string): React.ComponentType | null => {
        if (!(badgeComponentName in componentMap)) {
            raiseNavConfigError(`Navigation: badge component with name: ${badgeComponentName} is not defined in componentMap`)
            return null
        }
        return componentMap[badgeComponentName]
    }

    const goToNavItem = (navItemID: string) => {
        const path = getPathToNavItem(navItemID)
        history.push(path)
    }

    return (
        <NavigationContext.Provider
            value={{
                basePath,
                goToNavItem,
                getInitialNavItem,
                isOnBasePath,
                getNavItems,
                getPathToNavItem,
                getNavItemLinkProps,
                getNavItemsByPath,
                getActiveNavItems,
                getInvalidPathNavItem,
                getNavItemComponentType,
                getBadgeComponentType
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}

export default NavigationProvider
