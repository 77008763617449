import React from 'react'
import ListItems from './ListItems'
import { useHistory } from 'react-router-dom'
import { processLink } from '../helpers'
import { Typography, Button } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import { useWidgetContext } from 'providers'

const jss = () => ({
    widget: {
        rowGap: '.5rem',
        maxWidth: ({ maxWidth }) => maxWidth || '100%',
        margin: 'auto',
        padding: '0 48px'
    },
    titleAndButtonContainer: {
        marginBottom: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})

const useStyles = makeStyles(jss)

/**
 *
 */
const Features = (): JSX.Element => {
    const history = useHistory()
    const { pageBasedData } = useWidgetContext()
    const styles = useStyles({ maxWidth: pageBasedData.maxWidth })

    if (!pageBasedData.articles.length) return <></>
    const articlesTitle = pageBasedData.articlesTitle || pageBasedData.sectionName
    const goToArticle = (articleSlug) => {
        return processLink(`/support-center/${pageBasedData.slug}/${articleSlug}`, history)
    }
    return <div className={`${styles.widget} bottom-widget`}>
        {(articlesTitle || pageBasedData.slug) && <div className={styles.titleAndButtonContainer} data-testid='widget-features'>
            {articlesTitle && <Typography variant='subtitle1' color='neutral-900' data-testid='widget-features-title'>{articlesTitle}</Typography>}
            {pageBasedData.slug && <Button
                color='primary'
                iconLeft={false}
                icon='go'
                variant='text'
                onClick={() => processLink(`/support-center/${pageBasedData.slug}`, history, true)}
                data-testid='widget-features-view-all-btn'
            >{pageBasedData.viewAllButtonText}</Button>}
        </div>}
        <ListItems items={pageBasedData.articles.slice(0, 3)} onRedirect={goToArticle} icon={pageBasedData.icon} />
    </div>
}

export default Features
