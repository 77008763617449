import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { ThemeContext } from 'providers'
import { NavigationContext } from '../../NavigationContext'
import NavLink from 'nav-link'

const useStyles = makeStyles(theme => ({
    logo: {
        width: '40px',
        height: '40px',
        cursor: 'pointer'
    }
}))

/**
 * Clickable BrandLogo that brings up the IdentityPopup
 */
const BrandLogo = (): React.ReactNode => {
    const classes = useStyles()
    const theme = useContext(ThemeContext)

    const { getInitialNavItem } = useContext(NavigationContext)

    const renderInLink = (children: React.ReactNode): React.ReactNode => {
        const initialNavItem = getInitialNavItem()
        return initialNavItem
            ? (
                <NavLink navItemId={initialNavItem.id}>
                    {children}
                </NavLink>
            )
            : (
                children
            )
    }

    return renderInLink(
        <img
            data-testid="brand-logo"
            className={classes.logo}
            src={theme?.icons?.identityAreaLogo}
            alt='brand logo'
        />
    )
}

export default BrandLogo
