<template>
  <div v-bind="$attrs" :class="['audio-player', {'mobile-audio-player': $attrs.hasOwnProperty('mobile-view')}]">
    <div :class="['play-container', {'mobile-play': $attrs.hasOwnProperty('mobile-view')}]">
      <span @click="togglePlay" class="cursor-pointer">
        <v-icon size="40">{{ playingIcon }}</v-icon>
      </span>
    </div>
    <div v-if="!compact" :class="['controls', {'no-spacing': $attrs.hasOwnProperty('no-spacing'), 'mobile-controls': $attrs.hasOwnProperty('mobile-view')}]">
      <div v-if="filename" data-test-id="file-name" class="file-name ellipsis-text">{{ filename }}</div>
      <div>
        <div class="timeline" @click="handleTimelineClick" ref="timeline">
          <div class="progress" :style="{'width': progressBarWidth}"></div>
        </div>
      </div>
      <div class="time">
        <div class="current">{{ currentTime }}</div>
        <div class="length" :key="audioLength">{{ audioLength }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['src', 'compact', 'filename', 'force_toggle'],
  data() {
    return {
      audio: null,
      srcValue: this.src || null,
      audioLength: '—:—',
      currentTime: '0:00',
      progressBarWidth: '0%',
      isPlaying: false,
      interval: null,
    };
  },
  watch: {
    force_toggle: "togglePlay"
  },
  mounted() {
    this.$data.audio = new Audio(this.$data.srcValue);
    this.initializeAudioPlayer();
    if (this.$data.force_toggle !== undefined) this.togglePlay();
  },
  beforeDestroy() {
    this.$data.isPlaying = false;
    if (this.$data.audio) {
      this.$data.audio.pause();
      this.$data.audio = null;
    }
    clearInterval(this.interval);
  },
  computed: {
    playingIcon() {
      return (!this.$data.isPlaying || this.$data.audio.currentTime === this.$data.audio.duration) ? '$vuetify.icons.play_circle' : '$vuetify.icons.pause_circle';
    }
  },
  methods: {
    initializeAudioPlayer() {
      this.$data.audio.addEventListener("loadeddata", () => {
        this.$data.audioLength = this.getTimeCodeFromNum(this.$data.audio.duration);
        this.$data.audio.volume = 0.8;
      });
      this.$data.audio.addEventListener("durationchange", () => {
        this.$data.audioLength = this.getTimeCodeFromNum(this.$data.audio.duration);
      });
    },
    updateProgress() {
      this.$data.progressBarWidth = (this.$data.audio.currentTime / this.$data.audio.duration) * 100 + "%";
      this.$data.currentTime = this.getTimeCodeFromNum(this.$data.audio.currentTime);
      if (this.$data.audio.paused) {
        this.$data.isPlaying = false;
        clearInterval(this.interval);
        this.$emit('playing', false);
      }
    },
    handleTimelineClick(e) {
      const timelineWidth = window.getComputedStyle(this.$refs.timeline).width;
      const timeToSeek = (e.offsetX / parseInt(timelineWidth)) * this.$data.audio.duration;
      this.$data.audio.currentTime = timeToSeek;
      if (!this.$data.isPlaying) {
        this.updateProgress();
      }
    },
    async togglePlay() {
      if (this.$data.audio.paused) {
        this.$data.isPlaying = true;
        this.$data.audio.play();
        this.interval = setInterval(this.updateProgress, 100);
        this.$emit('playing', true);
      } else {
        this.$data.isPlaying = false;
        this.$data.audio.pause();
        clearInterval(this.interval);
        this.$emit('playing', false);
      }
    },
    getTimeCodeFromNum(num) {
      if (num === Infinity || !num) return '—:—';
      let seconds = parseInt(num);
      let minutes = parseInt(seconds / 60);
      seconds -= minutes * 60;
      const hours = parseInt(minutes / 60);
      minutes -= hours * 60;
      if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, '0')}`;
      return `${String(hours).padStart(2, '0')}:${minutes}:${String(seconds % 60).padStart(2, '0')}`;
    }
  }
};
</script>

<style scoped lang="scss">
.mobile-audio-player {
  height: auto !important;
  .play-container {
    height: 29px;
    margin-top: 25px;
  }
  .controls {
    margin-top: 0px !important;
    margin-left: 0px;
    /* margin-right: 38px; */
    padding-left: 0px !important;
    justify-content: unset !important;
  }
}
.no-spacing {
  padding-left: 3px !important;
  margin-top: 18px !important;
}
.file-name {
  font-size: 11px;
  position: absolute;
  margin-top: -45px;
}
svg {
  height: 100%;
}
.audio-player {
  height: 40px;
  width: 100%;
  font-family: arial;
  color: black;
  font-size: 0.75em;
  display: flex;
  overflow: hidden;
  .timeline {
    background: #6E7A82;
    height: 7px;
    border-radius: 7px;
    width: 100%;
    position: relative;
    cursor: pointer;
    .progress {
      background: #3fae29;
      width: 0%;
      height: 100%;
      transition: 0.25s;
    }
  }
  .controls {
    margin-top: 17px;
    width: 100%;
    display: flex;
    /* padding: 0 20px; */
    padding-left: 20px;
    flex-direction: column;
    justify-content: center;
    > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .time {
    display: flex;
    display: flex;
    justify-content: space-between;
    > * {
      padding: 2px;
    }
  }
}
</style>