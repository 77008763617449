import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, Route, useHistory } from 'react-router-dom'
import { getValue } from 'remote-config-value'
import { EsimProvider } from 'providers'
import ProcessEsim from './components/processPlans/Index'
import Article from './components/howTo/articles/Article'
import AppContextProvider, { useAppContext } from '../../contexts/AppContext'
import { RouterProps } from '../../utils/objects'
import GoBack from '../../components/GoBack'
import Header from './components/Header'
import Content from './components/Content'
import Videos from './components/howTo/videos/Index'
import Articles from './components/howTo/articles/Index'

const useStyles = makeStyles(theme => ({
    root: {
        overflowY: 'auto',
        overflowX: 'hidden',
        '& *': {
            fontFamily: 'Inter, Montserrat, sans-serif !important'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1050px',
            margin: 'auto'
        }
    },
    detailsContainer: {
        padding: '3rem',
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            padding: '1.5rem 3rem'
        }
    }
}))

interface DetailsInterface {
    routeProps: RouterProps
}

const AppDetails = ({ routeURL: url }: { routeURL: string }) => {
    const { app } = useAppContext()

    const renderRoutes = (routes: JSX.Element) => {
        let Provider: React.ComponentType<{ children: JSX.Element }> | undefined
        switch (app.identifier?.toLowerCase()) {
                case getValue('esim_app_identifier').toLowerCase():
                    Provider = EsimProvider
                    break
                default:
                    break
        }

        if (Provider) {
            return (
                <Provider>
                    {routes}
                </Provider>
            )
        }

        return routes
    }

    return renderRoutes(
        <Switch>
            <Route path={`${url}/stepper`} render={() => {
                return <ProcessEsim />
            }} />
            <Route path={`${url}/how-to-articles/:articleTitle`} render={() => {
                return <Article />
            }} />
            <Route path={`${url}/how-to-articles`} render={() => {
                return <Articles />
            }} />
            <Route path={`${url}/how-to-videos`} render={() => {
                return <Videos />
            }} />
            <Route path={'/'} render={() => {
                return (
                    <>
                        <Header />
                        <Content />
                    </>
                )
            }} />
        </Switch>

    )
}

/**
 *
 */
const Index = (props: DetailsInterface) => {
    const classes = useStyles()
    const url = props.routeProps.match.url.replace(/\/$/, '')
    const appURI = props.routeProps?.match.params.appName || ''
    const history = useHistory()

    useEffect(() => {
        const basePath = window.location.pathname
        const urlParams = new URLSearchParams(window.location.search)
        let redirectTo = urlParams.get('redirectTo')
        const item = urlParams.get('item')
        if (item) redirectTo = `${redirectTo}/${item}`
        if (redirectTo) history.replace(`${basePath}/${redirectTo}`)
    }, [])

    return (
        <AppContextProvider appURI={appURI}>
            <>
                <GoBack />
                <Box className={classes.root}>
                    <Box className={classes.detailsContainer}>
                        <AppDetails routeURL={url} />
                    </Box>
                </Box>
            </>
        </AppContextProvider>
    )
}

export default Index
