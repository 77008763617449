import Add from '../icons/add.vue'
import Call from '../icons/call.vue'
import AVO from '../icons/avo.vue'
import Cart from '../icons/cart.vue'
import Menu from '../icons/menu.vue'
import Gear from '../icons/gear.vue'
import Dots from '../icons/dots.vue'
import Edit from '../icons/edit.vue'
import Play from '../icons/play.vue'
import Info from '../icons/info.vue'
import Stop from '../icons/stop.vue'
import Globe from '../icons/globe.vue'
import Arrow from '../icons/arrow.vue'
import Clock from '../icons/clock.vue'
import Alert from '../icons/alert.vue'
import Group from '../icons/group.vue'
import Block from '../icons/block.vue'
import Inbox from '../icons/inbox.vue'
import Check from '../icons/check.vue'
import Close from '../icons/close.vue'
import Hours from '../icons/hours.vue'
import Tailor from '../icons/tailor.vue'
import Idseal from '../icons/idseal.vue'
import Filter from '../icons/filter.vue'
import Reload from '../icons/reload.vue'
import Search from '../icons/search.vue'
import Avatar from '../icons/avatar.vue'
import Loader from '../icons/loader.vue'
import Hangup from '../icons/hangup.vue'
import Person from '../icons/person.vue'
import Screen from '../icons/screen.vue'
import OnCall from '../icons/on-call.vue'
import Company from '../icons/company.vue'
import Chevron from '../icons/chevron.vue'
import Unblock from '../icons/unblock.vue'
import Persons from '../icons/persons.vue'
import Enabled from '../icons/enabled.vue'
import TwoBars from '../icons/two-bars.vue'
import Headset from '../icons/head-set.vue'
import Disabled from '../icons/disabled.vue'
import Question from '../icons/question.vue'
import Download from '../icons/download.vue'
import Location from '../icons/location.vue'
import Triangle from '../icons/triangle.vue'
import TrashBin from '../icons/trashbin.vue'
import Calendar from '../icons/calendar.vue'
import Phonecom from '../icons/phone-com.vue'
import MoreVert from '../icons/more-vert.vue'
import TwoUsers from '../icons/two-users.vue'
import Headphone from '../icons/headphone.vue'
import Voicemail from '../icons/voicemail.vue'
import Transcript from '../icons/transcript.vue'
import Paperfile from '../icons/paper-file.vue'
import OpenedBox from '../icons/opened-box.vue'
import Microphone from '../icons/microphone.vue'
import OpenInNew from '../icons/open-in-new.vue'
import AddCircled from '../icons/add-circle.vue'
import Attachment from '../icons/attachment.vue'
import HotInfotip from '../icons/infotip-hot.vue'
import PlayCircle from '../icons/play-circle.vue'
import StopCircle from '../icons/stop-circle.vue'
import Loudspeaker from '../icons/loudspeaker.vue'
import Landline from '../icons/landline-phone.vue'
import Exclamation from '../icons/exclamation.vue'
import PauseCircle from '../icons/pause-circle.vue'
import PhoneRinging from '../icons/phone-ringing.vue'
import CallsHandling from '../icons/calls-handling.vue'
import DownloadCloud from '../icons/download-cloud.vue'
import InfoTipStylized from '../icons/infotip-stylized.vue'
import DropdownArrowDown from '../icons/dropdown-arrow-down.vue'
import ExclamationCircled from '../icons/exclamation-circled.vue'
import DownloadCloudInverted from '../icons/download-cloud-inverted.vue'

export default {
    avo: { component: AVO },
    add: { component: Add },
    add_circle: { component: AddCircled },
    microphone: { component: Microphone },
    alert: { component: Alert },
    arrow_forward: { component: Arrow },
    attachment: { component: Attachment },
    avatar: { component: Avatar },
    block: { component: Block },
    calendar: { component: Calendar },
    call: { component: Call },
    calls_handling: { component: CallsHandling },
    cart: { component: Cart },
    check: { component: Check },
    chevron_forward: { component: Chevron },
    clock: { component: Clock },
    close: { component: Close },
    company: { component: Company },
    disabled: { component: Disabled },
    download_cloud: { component: DownloadCloud },
    dots: { component: Dots },
    download: { component: Download },
    download_cloud_inverted: { component: DownloadCloudInverted },
    dropdown_arrow: { component: DropdownArrowDown },
    edit: { component: Edit },
    enabled: { component: Enabled },
    exclamation: { component: Exclamation },
    exclamation_circled: { component: ExclamationCircled },
    filter: { component: Filter },
    gear: { component: Gear },
    hangup: { component: Hangup },
    headphone: { component: Headphone },
    headset: { component: Headset },
    globe: { component: Globe },
    group: { component: Group },
    hours: { component: Hours },
    idseal: { component: Idseal },
    inbox: { component: Inbox },
    info: { component: Info },
    infotip_hot: { component: HotInfotip },
    infotip_stylized: { component: InfoTipStylized },
    landline: { component: Landline },
    loader: { component: Loader },
    location: { component: Location },
    loudspeaker: { component: Loudspeaker },
    menu: { component: Menu },
    more_vert: { component: MoreVert },
    on_call: { component: OnCall },
    opened_box: { component: OpenedBox },
    open_in_new: { component: OpenInNew },
    paper_file: { component: Paperfile },
    person: { component: Person },
    persons: { component: Persons },
    phone_com: { component: Phonecom },
    phone_ringing: { component: PhoneRinging },
    play: { component: Play },
    question: { component: Question },
    reload: { component: Reload },
    screen: { component: Screen },
    search: { component: Search },
    stop: { component: Stop },
    tailor: { component: Tailor },
    transcript: { component: Transcript },
    trash: { component: TrashBin },
    triangle: { component: Triangle },
    two_bars: { component: TwoBars },
    two_users: { component: TwoUsers },
    unblock: { component: Unblock },
    voicemail: { component: Voicemail },
    play_circle: { component: PlayCircle },
    stop_circle: { component: StopCircle },
    pause_circle: { component: PauseCircle },
    arrow_backward: {
        component: Arrow,
        props: {
            backward: true
        }
    },
    chevron_backward: {
        component: Chevron,
        props: {
            backward: true
        }
    },
    download_circled: {
        component: Download,
        props: {
            circle: true
        }
    },
    dropdown_arrow_black: {
        component: DropdownArrowDown,
        props: {
            black: true
        }
    },
    edit_circled: {
        component: Edit,
        props: {
            circle: true
        }
    },
    spinning_loader: {
        component: Loader,
        props: {
            sppinning: true
        }
    },
    trash_circled: {
        component: TrashBin,
        props: {
            circle: true
        }
    }
}
