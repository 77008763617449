import React, { useContext } from 'react'
import {
    NavLink as ReactRouterNavLink
} from 'react-router-dom'
import { NavigationContext } from 'navigation/NavigationContext'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
    createStyles({
        link: {
            fontSize: '12px',
            lineHeight: '19px',
            color: '#29921B',
            fontWeight: 'normal',
            '&:hover': {
                color: theme.palette.primary.textDark
            }
        }
    })
)

interface NavLinkProps {
    navItemId: string;
    className?: string;
    children: React.ReactNode
}

/**
 * Renders a link to a given nav item.
 */
const NavLink = ({ navItemId, className, children }: NavLinkProps) => {
    const classes = useStyles()

    const navContext = useContext(NavigationContext)

    if (!navContext) {
        throw new Error('NavLink must be rendered inside a navigation-poc functional component (no navigation context is available)')
    }

    const navItemLinkProps = navContext.getNavItemLinkProps(navItemId)

    if (navItemLinkProps) {
        return (
            <a href={navItemLinkProps.href} target={navItemLinkProps.target} className={className || classes.link}>
                {children}
            </a>
        )
    }

    const path = navContext.getPathToNavItem(navItemId)

    if (!path) {
        return null
    }

    return (
        <ReactRouterNavLink
            className={className || classes.link}
            to={path}
        >
            {children}
        </ReactRouterNavLink>
    )
}

export default NavLink
