/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Button } from 'foundations-library/components'
import { EsimContext } from 'providers'
import { NavigationContext } from 'navigation'
import { getValue } from 'remote-config-value'
import { listing as listTexts } from './../../../utils/objects'

const useStyles = makeStyles({
    root: {}
})

/**
 *
 */
const AddButton = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { app } = useContext(EsimContext)
    const { getPathToNavItem } = useContext(NavigationContext)

    const routeToEsim = () => {
        if (!app) return

        const appURI = encodeURI(app.title.toLowerCase())
        const path = getPathToNavItem(getValue('nav_apps_marketplace_id'))
        const link = `${path}?app=${appURI}&redirectTo=stepper`
        history.push(link)
    }

    return (
        <div className={classes.root}>
            <Button size={props.size || 'medium'} color='primary' variant={props.variant || 'outlined'} icon={props.icon || 'add'} onClick={routeToEsim} >{props.children || listTexts.addButton}</Button>
        </div>
    )
}

export default AddButton
