import React, { useContext, useEffect, useMemo, useState } from 'react'
import { EsimContext, useBillingContext } from 'providers'
import { getValue } from 'firebase-utils'
import { makeStyles } from '@material-ui/core'
import { Dialog, FormButtons } from 'functional-foundations'
import { Typography, Checkbox, Textarea } from 'foundations-library/components'
import CommonDialog from './CommonDialog'
import UserNameAndNumber from '../UserNameAndNumber'
import { useHistory, useParams } from 'react-router-dom'
import { getPhoneCom } from 'phonecom'
import DialogLoader from '../DialogLoader'
import ErrorDialog from '../ErrorDialog'
import { useEsimOperationsContext } from '../../../contexts/EsimOperationsContext'
import { formatPhoneNumber } from 'phone-numbers'
import { formatDate } from 'formatters'

const jss = () => {
    return {
        finalActionWarning: {
            marginTop: '20px',
            marginBottom: '10px'
        },
        '.input-wrapper': {
            width: '100%'
        },
        mb20: {
            marginBottom: '20px'
        },
        mb15: {
            marginBottom: '15px'
        },
        mb10: {
            marginBottom: '10px'
        },
        '.full-width, .full-width .input-wrapper': {
            width: '100% !important'
        },
        textCenter: {
            textAlign: 'center'
        },
        userNameAndNumber: {
            marginBottom: '20px',
            marginTop: '10px'
        }
    }
}
const useStyles = makeStyles(jss)

const getValues = (keys: string[]) => {
    const defaultValues = {
        confirm_button_text: 'Proceed',
        cancel_button_text: 'Cancel'
    }
    const data: Record<string, any>[] = []
    let values = getValue('terminate_esim')

    if (typeof values === 'string') {
        values = JSON.parse(values)
    }

    for (const key of keys) {
        data.push({
            ...defaultValues,
            ...values[key]
        })
    }
    return data
}

const BoldDynamicRemoteConfigText = ({
    text,
    nidle,
    bold,
    'data-testid': dataTestId,
    ...props
}: {
    text: string,
    nidle: string,
    bold: string,
    'data-testid'?: string
}) => {
    const parts = text.split(nidle)
    if (parts.length > 1) {
        const restOfTheText = parts.slice(1).join('')
        return <Typography {...props} data-testid={dataTestId}>
            {parts[0]}<Typography variant='body2heavy' tag='span'>{bold}</Typography>{restOfTheText}
        </Typography>
    }

    return <Typography {...props} data-testid={dataTestId}>{text}</Typography>
}

interface PropsInterface {
    start: boolean
    forceQuit: boolean,
    onClose: () => any
}
type ReasonType = { text: string, checked: string }
type DataType = { reasons: ReasonType[], improvementsNote: string, confirmed: boolean, undoTermination: boolean }

/**
 *
 */
const TermianteSimProcess = ({
    start = false,
    forceQuit = false,
    onClose
}: PropsInterface): JSX.Element => {
    const styles = useStyles()

    const { fetchEsims, terminateEsimHandler, terminateEsim, undoTerminateEsimHandler, undoTerminateEsim, reset } = useContext(EsimContext)
    const { esimId } = useParams<{ esimId: string }>()
    const history = useHistory()
    const { invoiceDetails } = useBillingContext()

    const [reasonsDialogIsOpen, setReasonsDialogIsOpen] = useState(false)
    const [improvementsDialogIsOpen, setImprovementsDialogIsOpen] = useState(false)
    const [finalActionDialogIsOpen, setFinalActionDialogIsOpen] = useState(false)
    const [submittedDialogIsOpen, setSubmittedDialogIsOpen] = useState(false)
    const [undoTerminationDialogIsOpen, setUndoTerminationDialogIsOpen] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [data, setData] = useState<DataType>({
        reasons: [],
        improvementsNote: '',
        confirmed: false,
        undoTermination: false
    })

    const { data: esimData } = useEsimOperationsContext()
    const { name, number } = useMemo(() => {
        let name
        const username = [esimData?.user?.first_name, esimData?.user?.last_name].filter((x) => x).join(' ')
        if (esimData?.user?.extension?.number) {
            name = `Ext ${esimData.user.extension.number}: ${username}`
        } else if (esimData?.user) {
            name = username
        }

        return {
            name,
            number: formatPhoneNumber(esimData?.user?.did)
        }
    }, [esimData])

    const closeAllDialogs = () => {
        setReasonsDialogIsOpen(false)
        setImprovementsDialogIsOpen(false)
        setFinalActionDialogIsOpen(false)
        setSubmittedDialogIsOpen(false)
        setUndoTerminationDialogIsOpen(false)
        onClose && onClose()
    }

    useEffect(() => {
        getPhoneCom().then((res) => setUserEmail(res.email))
    }, [])

    useEffect(() => {
        if (terminateEsim.error || undoTerminateEsim.error) closeAllDialogs()
    }, [terminateEsim.error, undoTerminateEsim.error])

    useEffect(() => {
        if (start && !esimData?.deletion_requested) setReasonsDialogIsOpen(true)
        else if (start && esimData?.deletion_requested) setUndoTerminationDialogIsOpen(true)
        setData({
            ...data,
            confirmed: false
        })
    }, [start])

    useEffect(() => {
        if (forceQuit) closeAllDialogs()
    }, [forceQuit])

    const reasonsDialogOnConfirm = () => {
        setReasonsDialogIsOpen(false)
        setImprovementsDialogIsOpen(true)
    }

    const improvementsDialogOnConfirm = () => {
        setImprovementsDialogIsOpen(false)
        setFinalActionDialogIsOpen(true)
    }

    const finalActionDialogOnConfirm = async () => {
        const reasons = data.reasons.filter((x) => x.checked).map((x) => x.text)
        const res = await terminateEsimHandler(esimId, reasons, data.improvementsNote)
        if (res) {
            fetchEsims(false, true)
            setSubmittedDialogIsOpen(true)
        }
        setFinalActionDialogIsOpen(false)
    }

    const undoTerminationDialogOnConfirm = async () => {
        const res = await undoTerminateEsimHandler(esimId)
        if (res) {
            fetchEsims(false, true)
            setSubmittedDialogIsOpen(true)
        }
        setUndoTerminationDialogIsOpen(false)
    }

    const lastDayOfTheBillingCycle = useMemo(() => {
        return formatDate(
            invoiceDetails?.billing_exp_date,
            { day: '2-digit', month: '2-digit' }
        )
    }, [invoiceDetails])

    const [reasonsValues, improvementsValues, finalActionValues, submittedDialog, undoTerminationValues, submittedTerminationDialog] = useMemo(() => {
        const values = getValues([
            'reasons_dialog',
            'improvements_dialog',
            'final_action_dialog',
            'submitted_dialog',
            'undo_termination_dialog',
            'submitted_termination_dialog'
        ])
        const defaultReasons = [...values[0].reasons]
        setData({
            ...data,
            reasons: defaultReasons
        })
        return values
    }, []
    )
    const actualSubmittedDialog = !esimData?.deletion_requested ? submittedDialog : submittedTerminationDialog

    const toggleReason = (text) => {
        setData({
            ...data,
            reasons: data.reasons.map((x) => {
                if (x.text === text) {
                    x.checked = !x.checked
                }

                return x
            })
        })
    }

    const closeAndGoBack = () => {
        closeAllDialogs()
        setTimeout(() => {
            history.goBack()
        }, 500)
    }

    const handleOnErrorDialogClose = () => {
        reset('TERMINATE_ESIM')
        reset('UNDO_TERMINATE_ESIM')
        onClose && onClose()
    }

    return <>
        {/* reasons */}
        <CommonDialog
            values={reasonsValues}
            isOpen={reasonsDialogIsOpen}
            onConfirm={reasonsDialogOnConfirm}
            onClose={closeAllDialogs}
            data-testid='reasons-dialog'
        >
            <Typography variant='body2' className={styles.mb15} data-testid='reasons-text'>
                {reasonsValues.text}
            </Typography>
            <Typography variant='subtitle2' color='neutral-700' className={styles.mb10} data-testid='reasons-checkboxes-title'>
                {reasonsValues.checkboxesTitle}
            </Typography>
            {
                Array.isArray(reasonsValues.reasons) &&
                reasonsValues.reasons.map(
                    ({ text, checked }, i) => (
                        <Checkbox
                            value={checked}
                            onChange={() => toggleReason(text)}
                            className={styles.mb10}
                            checked={checked}
                            label={text}
                            key={`${text}-${checked}`}
                            data-testid={`reason-${i}`}
                        />
                    )
                )
            }
        </CommonDialog>

        {/* improvements */}
        <CommonDialog
            values={improvementsValues}
            isOpen={improvementsDialogIsOpen}
            onConfirm={improvementsDialogOnConfirm}
            onClose={closeAllDialogs}
            data-testid='improvements-dialog'
        >
            <Typography variant='body2' color='neutral-700' className={styles.mb15} data-testid='improvements-text'>
                {improvementsValues.text}
            </Typography>
            <Textarea
                fullWidth
                className='full-width'
                rows={4}
                maxLength={200}
                placeholder={improvementsValues.placeholder}
                value={data.improvementsNote}
                onChange={(v) => setData({ ...data, improvementsNote: v })}
                data-testid='improvements-textarea'
            />
        </CommonDialog>

        {/* final action */}
        <CommonDialog
            values={terminateEsim.isLoading ? undefined : finalActionValues}
            isOpen={finalActionDialogIsOpen}
            onConfirm={terminateEsim.isLoading ? undefined : finalActionDialogOnConfirm}
            onClose={terminateEsim.isLoading ? undefined : closeAllDialogs}
            confirmProps={{ color: 'destructive', loading: terminateEsim.isLoading }}
            cancelProps={{ disabled: terminateEsim.isLoading }}
            disableConfirm={!data.confirmed || terminateEsim.isLoading}
            centerTitle
            key={`${terminateEsim.isLoading}-${data.confirmed}`}
            data-testid='final-action-dialog'
        >
            {terminateEsim.isLoading && <DialogLoader title={finalActionValues.loading_text} subtitle={finalActionValues.loading_small_text} />}
            {!terminateEsim.isLoading && <div>
                <BoldDynamicRemoteConfigText text={finalActionValues.text} nidle='__TIME__' bold={lastDayOfTheBillingCycle} data-testid='final-text' />
                <Typography variant='body2' color='destructive-500' align='center' className={styles.finalActionWarning} data-testid='warning-text'>
                    {finalActionValues.warning_text}
                </Typography>
                <UserNameAndNumber className={styles.userNameAndNumber} name={name} number={number} />
                <Checkbox
                    checked={data.confirmed}
                    onChange={(v) => setData({ ...data, confirmed: v })}
                    className={styles.mb20}
                    disabled={terminateEsim.isLoading}
                    data-testid='final-confirm'
                >
                    <BoldDynamicRemoteConfigText
                        text={finalActionValues.confirm_checkbox_text}
                        bold={finalActionValues.confirm_checkbox_text_bold}
                        nidle={finalActionValues.confirm_checkbox_text_bold}
                        data-testid='final-confirm-text'
                    /></Checkbox>
            </div>
            }
        </CommonDialog>

        {/* confirmed */}
        <Dialog
            isOpen={submittedDialogIsOpen}
            title={actualSubmittedDialog.title}
            titleAlign='center'
            closeOnClickOutside={!terminateEsim.isLoading}
            data-testid='submitted-successfully-dialog'
        >
            <div className={styles.textCenter}>
                {actualSubmittedDialog.text && <BoldDynamicRemoteConfigText
                    text={actualSubmittedDialog.text}
                    bold={lastDayOfTheBillingCycle}
                    nidle={'__TIME__'}
                />}
                <UserNameAndNumber className={styles.userNameAndNumber} name={name} number={number} />
                <Typography>{actualSubmittedDialog.confirmation_mail_sent_to_text}</Typography>
                <Typography
                    variant='subtitle2'
                    color='secondary-500'
                    className={styles.mb20}
                >
                    {userEmail}
                </Typography>
                <FormButtons
                    onCancel={closeAndGoBack}
                    cancelText={actualSubmittedDialog.cancel_button_text}
                    disableConfirm={terminateEsim.isLoading}
                />
            </div>
        </Dialog>

        {/* Undo */}
        <CommonDialog
            values={undoTerminateEsim.isLoading ? undefined : undoTerminationValues}
            isOpen={undoTerminationDialogIsOpen}
            onConfirm={undoTerminateEsim.isLoading ? undefined : undoTerminationDialogOnConfirm}
            onClose={undoTerminateEsim.isLoading ? undefined : closeAllDialogs}
            confirmProps={{ color: 'primary', loading: undoTerminateEsim.isLoading }}
            cancelProps={{ disabled: undoTerminateEsim.isLoading }}
            disableConfirm={!data.undoTermination || undoTerminateEsim.isLoading}
            centerTitle
            key='undo-termination'
            data-testid='undo-termination-dialog'
        >
            {undoTerminateEsim.isLoading && <DialogLoader title={undoTerminationValues.loading_text} subtitle={undoTerminationValues.loading_small_text} />}
            {!undoTerminateEsim.isLoading && <div>
                <Typography variant='body2' align='center'>{undoTerminationValues.text}</Typography>
                <UserNameAndNumber className={styles.userNameAndNumber} name={name} number={number} />
                <Checkbox
                    checked={data.confirmed}
                    onChange={(v) => setData({ ...data, confirmed: v, undoTermination: v })}
                    className={styles.mb20}
                    disabled={undoTerminateEsim.isLoading}
                    data-testid='undo-termination-confirm'
                >
                    <BoldDynamicRemoteConfigText
                        text={undoTerminationValues.confirm_checkbox_text}
                        bold={undoTerminationValues.confirm_checkbox_text_bold}
                        nidle={undoTerminationValues.confirm_checkbox_text_bold}
                        data-testid='final-confirm-text'
                    /></Checkbox>
            </div>
            }
        </CommonDialog>

        <ErrorDialog isOpen={Boolean(terminateEsim.error || undoTerminateEsim.error)} onClose={handleOnErrorDialogClose}>{terminateEsim.error || undoTerminateEsim.error}</ErrorDialog>
    </>
}

export default TermianteSimProcess
