<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20Z" fill="#3FAE29" fill-opacity="0.25"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2593 19.733C23.2593 21.4268 21.8097 22.7941 20.0004 22.7941C18.1912 22.7941 16.7307 21.4268 16.7307 19.733V13.5611C16.7307 11.8673 18.1912 10.5 20.0004 10.5C21.8097 10.5 23.2702 11.8673 23.2702 13.5611L23.2593 19.733ZM24.8979 20.233C24.9577 19.7985 25.3071 19.4409 25.7456 19.4409C26.1842 19.4409 26.5443 19.7979 26.4963 20.2338C26.149 23.3848 23.7649 25.8931 20.9344 26.3489V28.5655C20.9344 29.0815 20.5161 29.4997 20.0002 29.4997C19.4842 29.4997 19.066 29.0815 19.066 28.5655V26.3489C16.2361 25.8837 13.8524 23.3757 13.5044 20.2338C13.4561 19.7979 13.8162 19.4409 14.2547 19.4409C14.6933 19.4409 15.0427 19.7985 15.1025 20.233C15.4682 22.8882 17.6487 24.6836 20.0002 24.6836C22.3517 24.6836 24.5322 22.8882 24.8979 20.233Z" fill="#186A82"/>
    </svg>
</template>

<script>
  export default {};
</script>
