import React from 'react'
import { WidgetProvider } from 'providers'
import NavRoutes from './NavRoutes'
import { Features } from 'widgets'

/**
 * Displays the content of the currently active nav item.
 */
const ContentArea = (): JSX.Element => {
    return (
        <WidgetProvider>
            <NavRoutes />
            <Features />
        </WidgetProvider>
    )
}

export default ContentArea
