<template>
    <svg width="40" height="40" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="audio-base prevent-toggle" d="M29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5Z" fill="#BCE2B4"/>
        <path class="audio-secondary prevent-toggle" d="M12.8534 9.55774C12.5206 9.34383 12.083 9.58275 12.083 9.97833V19.0217C12.083 19.4172 12.5206 19.6562 12.8534 19.4423L19.8871 14.9206C20.1933 14.7238 20.1933 14.2762 19.8871 14.0794L12.8534 9.55774Z" fill="#197F9F"/>
    </svg>
</template>

<script>
  export default {};
</script>
