/* eslint-disable react/no-danger-with-children */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Typography, Icon, Button, Loader } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import { Dialog } from 'functional-foundations'
import RemoteConfigValue from 'remote-config-value'
import { DialogSelector } from 'payment-methods'
import { ScreenSizeContext } from 'providers'

import CardDetails from './CardDetails'
import BillingLoader from './BillingLoader'
import { submitPayment } from '../src/api/v5'
import { formatDate } from 'formatters'
/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            margin: 'auto',
            width: '440px',
            height: '308px',
            borderRadius: '6px',
            padding: '20px',
            border: `1px solid ${colors.neutral[100]}`,
            '& .title': {
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '22px',
                letterSpacing: '-0.2px',
                color: colors.neutral[900]
            },
            '& .alertContainer': {
                width: '400px',
                height: '54px',
                border: `1px solid ${colors.destructive[200]}`,
                backgroundColor: colors.destructive[50],
                color: colors.destructive[500],
                borderRadius: '28px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 10px 8px 20px',
                marginTop: '16px',
                '@media (max-width: 600px)': {
                    width: '315px'
                }
            },
            '& .payButton': {
                display: 'flex',
                justifyContent: 'end'
            },
            '& .loader': {
                height: '378px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '& .dialog': {
                width: '396px',
                height: '54px',
                border: `1px solid ${colors.destructive[200]}`,
                backgroundColor: colors.destructive[50],
                color: colors.destructive[500],
                borderRadius: '28px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 10px 8px 20px'
            },
            '& .paymentDate': {
                height: '43px',
                borderBottom: `1px solid ${colors.neutral[100]}`,
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '20px',
                marginBottom: '20px',
                marginTop: '10px'
            },
            '& .paymentMethod': {
                margin: 'auto',
                marginBottom: '30px',
                height: '119px',
                '& .info': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '56px',
                    marginBottom: '4px',
                    '& .creditCard': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '82px',
                        height: '46px',
                        borderRadius: '5px',
                        padding: '10px',
                        border: `1px solid ${colors.neutral[50]}`
                    },
                    '& .creditCardInfo': {
                        width: '256px'
                    }
                }
            },
            '& .actionButtons': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            },
            '& .paymentPrice': {
                margin: 'auto',
                height: '220px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            },
            '& .successDialogBtn': {
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '30px',
                width: '100%'
            },
            '& .biggerHeight': {
                height: '300px !important'
            },
            '& .phoneNumber': {
                color: colors.primary[400]
            },
            '@media (max-width: 600px)': {
                width: '100%'
            }
        }
    }
}

/**
 *
 */
export { style }

const useStyles = makeStyles(style)
interface PropsInterface {
    invoiceDetails?: any
    value?: any
    primaryCard?: any
    allCards: any
    onSuccessfullPaymentCb(): any
}

/**
 *
 */
const CurrentBalance = ({
    invoiceDetails,
    primaryCard,
    allCards,
    onSuccessfullPaymentCb
}: PropsInterface): JSX.Element => {
    const [isOpenPayNow, setIsOpenPayNow] = useState(false)
    const [isOpenSelector, setIsOpenSelector] = useState(false)
    const [selectedCard, setSelectedCard] = useState(primaryCard || {})
    const [status, setStatus] = useState('')
    const [paymentId, setPaymentId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [submittingPayment, setSubmittingPayment] = useState(false)
    const classes = useStyles()
    const screenSize = useContext(ScreenSizeContext)

    const handleOnChange = (val) => {
        if (val) {
            const found = allCards.find(x => x.id === val)
            setSelectedCard(found)
            setIsOpenPayNow(true)
            setIsOpenSelector(false)
        }
    }

    const handleOnConfirm = async () => {
        try {
            const id = selectedCard?.id

            if (!invoiceDetails?.cb_current_balance || !id) {
                setStatus('error')
                return
            }

            setSubmittingPayment(true)

            const payload = {
                amount: invoiceDetails?.cb_current_balance,
                payment_info_id: id
            }

            const response = await submitPayment(payload)
            if (response.data.payment_id) {
                setPaymentId(response.data.payment_id)
            }
            if (response?.data?.status) {
                setStatus(response.data.status)
            } else if (response?.http_code === 500) {
                setStatus('error')
            }
        } catch (error) {
            setStatus('error')
        } finally {
            setSubmittingPayment(false)
        }
    }

    const closeModal = () => {
        setIsOpenPayNow(false)
        if (status === 'success') {
            onSuccessfullPaymentCb && onSuccessfullPaymentCb()
        }
        setStatus('')
        setPaymentId(null)
        setLoading(false)
    }

    const changePM = () => {
        setLoading(true)
        setIsOpenSelector(true)
        setIsOpenPayNow(false)
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const closeDialogSelector = () => {
        setIsOpenSelector(false)
        setStatus('')
    }

    const getBillingCycle = () => {
        const payment_period = invoiceDetails.payment_period
        let cycleLabel = (typeof payment_period === 'number') ? `Billed every ${payment_period} months` : 'Custom' // default for non number values and outside of 1,3,12

        switch (payment_period) {
                case 1:
                    cycleLabel = 'Monthly'
                    break
                case 3:
                    cycleLabel = 'Quarterly'
                    break
                case 12:
                    cycleLabel = 'Annually'
                    break
                default:
                    break
        }

        return cycleLabel
    }

    const displayLastPayment = useMemo(() => {
        const amount = invoiceDetails?.cb_last_payment_amount
        return !(amount === 0 || amount?.includes('$0.00'))
    }, [invoiceDetails])

    const disablePayNow = useMemo(() => {
        return invoiceDetails?.cb_current_balance.includes('$0.00') || invoiceDetails?.cb_current_balance.includes('CR')
    }, [invoiceDetails])

    useEffect(() => {
        setSelectedCard(primaryCard || {})
    }, [primaryCard])
    const showCancel = !screenSize.mobile

    return (
        <div className={classes.container}>
            <div className={'title'}>Current balance</div>
            <Typography data-testid='current-balance-value' variant='h2' style={{ marginBottom: '20px' }}>{invoiceDetails?.cb_current_balance || '-'}</Typography>
            {displayLastPayment && <Typography data-testid='current-balance-last-payment' variant='captionLight' color={'neutral-500'} style={{ marginBottom: '10px' }}>
                {`Your last payment of ${invoiceDetails?.cb_last_payment_amount} was received on ${invoiceDetails?.cb_last_payment_date || '-'}.`}
            </Typography>}
            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <Typography data-testid='billing-cycle' variant='subtitle3' style={{ marginRight: '4px' }}>Billing cycle:</Typography>
                <Typography data-testid='billing-cycle-date-range' variant='body3' color={'neutral-600'}>{getBillingCycle() || '-'}</Typography>
            </div>

            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <Typography data-testid='billing-exp-date-label' variant='subtitle3' style={{ marginRight: '4px' }}>Next Due Date:</Typography>
                <Typography data-testid='billing-exp-date-value' variant='body3' color={'neutral-600'}>
                    {formatDate(invoiceDetails?.billing_exp_date)}
                </Typography>
            </div>

            {!!invoiceDetails?.cb_autopay && <Typography data-testid='enrolled-autopay' variant='body4' color={'neutral-600'}>You&apos;re enrolled in autopay</Typography>}
            {!!invoiceDetails?.cb_is_past_due &&
            <div className={'alertContainer'}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon name='alert' color={'destructive-500'} size={24}/>
                    <div style={{ marginLeft: '6px' }}>
                        <Typography data-testid='payment-overdue' variant='subtitle3' color={'destructive-500'}>Your payment is overdue</Typography>
                        <Typography data-testid='payment-due-upon-receipt' variant='subtitle3' color={'destructive-500'}>Payment due upon receipt</Typography>
                    </div>
                </div>
                <Button color={'primary'} disabled={disablePayNow} size={'large'} onClick={() => setIsOpenPayNow(true)}>Pay now</Button>
            </div>}
            {!invoiceDetails?.cb_is_past_due &&
            <div className={'payButton'}>
                <Button color={'primary'} disabled={disablePayNow} size={'large'} onClick={() => setIsOpenPayNow(true)}>Pay now</Button>
            </div>}
            <Dialog
                title={(loading || status || submittingPayment) ? '' : 'Make a payment'}
                isOpen={isOpenPayNow}
                onClose={submittingPayment ? undefined : closeModal}
                closeOnClickOutside={!submittingPayment}
            >
                <>
                    {loading && !submittingPayment && <BillingLoader/>}
                    {submittingPayment && <Loader className="loader" size={80}>
                        <>
                            <Typography data-testid='processing-payment' variant='h6' style={{ marginBottom: '10px', marginTop: '40px' }}>Processing your payment...</Typography>
                            <Typography data-testid='please-wait' variant='body1'>Please wait!</Typography>
                        </>
                    </Loader>}
                    {!loading && !submittingPayment && !status && <>
                        <Typography data-testid='total-balance' variant='subtitle1' style={{ marginBottom: '20px' }}>Total balance</Typography>
                        <Typography data-testid='total-balance-value' variant='h4'>{invoiceDetails?.cb_current_balance || '-'}</Typography>
                        <div className={'paymentDate'}>
                            <Typography data-testid='payment-date-label' variant='subtitle1' color={'secondary-500'}>Payment date</Typography>
                            <Typography data-testid='payment-date' variant='subtitle2' color={'neutral-600'} style={{ marginLeft: '12px' }}>{`${invoiceDetails?.cb_billing_cycle || '-'}`}</Typography>
                        </div>
                        <div className={'paymentMethod'}>
                            {selectedCard && <CardDetails primaryCard={selectedCard} changePM={changePM}/>}
                        </div>
                        <div className='actionButtons'>
                            {showCancel && <Button color={'neutral'} size={'large'} onClick={closeModal} style={{ marginRight: '20px' }}>Cancel</Button>}
                            <Button color={'primary'} size={'large'} disabled={!selectedCard?.id} onClick={handleOnConfirm}>{`Submit payment for ${invoiceDetails?.cb_current_balance || '-'}`}</Button>
                        </div>
                    </>}
                    {status === 'error' &&
                    <div className='paymentPrice biggerHeight'>
                        <Icon name='cancel' size={60} color='destructive-600' />
                        <Typography
                            data-testid='error-message'
                            variant='h6'
                            color='destructive-600'
                            style={{ marginTop: '20px' }}
                        >
                        Oops, something went wrong!
                        </Typography>
                        <Typography data-testid='error-description' variant='body1' color='neutral-700'> There was an issue processing your request.</Typography>
                        <Typography
                            data-testid='error-payment-price'
                            variant='h4'
                            style={{ marginTop: '20px' }}
                        >
                            {invoiceDetails?.cb_current_balance || '-'}
                        </Typography>
                        {paymentId && <Typography
                            data-testid='error-transaction-id'
                            variant='body1'
                            color='neutral-700'
                            style={{ marginTop: '10px' }}
                        >
                        Transaction ID: <span>{paymentId}</span>
                        </Typography>}
                        <Typography
                            data-testid='error-contact-info'
                            variant='subtitle1'
                            style={{ marginTop: '55px' }}
                        >
                        Please call on this number <span className='phoneNumber'><RemoteConfigValue valueId='pay_now_failed_contact_phone_number'/></span>
                        </Typography>
                    </div>
                    }
                    {status === 'success' &&
                    <>
                        <div className='paymentPrice'>
                            <Icon name='check-alt' size={60} color='secondary-600' />
                            <Typography
                                data-testid='success-message'
                                variant='h6'
                                style={{ margin: '20px 0px' }}
                            >
                                Thank you for your payment!
                            </Typography>
                            <Typography
                                data-testid='success-payment-price'
                                variant='h4'
                                style={{ marginBottom: '10px' }}
                            >
                                {invoiceDetails?.cb_current_balance || '-'}
                            </Typography>
                            {paymentId && <Typography data-testid='success-payment-id' variant='body1' color='secondary-500'>
                                Payment ID: <span>{paymentId}</span>
                            </Typography>}
                        </div>
                        <div className='successDialogBtn'>
                            <Button
                                data-testid='success-ok-button'
                                color='primary'
                                size='large'
                                onClick={closeModal}
                            >
                            Okay
                            </Button>
                        </div>
                    </>
                    }
                </>
            </Dialog>
            <div key={`${isOpenSelector}`}>
                <DialogSelector isOpen={isOpenSelector} value={selectedCard?.id} onConfirm={handleOnChange} onClose={closeDialogSelector}/>
            </div>
        </div>
    )
}

export default CurrentBalance
