import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Item, { ItemType } from './Item'
import { Button } from 'foundations-library/components'

interface PropsInterface {
    items: ItemType[],
    onRedirect(v): any,
}

/**
 *
 */
const ListItems = ({ items = [], onRedirect }: PropsInterface) => {
    const [visibleCount, setVisibleCount] = useState(9)
    const [visibleItems, setVisibleItems] = useState<ItemType[]>([])

    useEffect(() => {
        setVisibleCount(9)
    }, [items])

    useEffect(() => {
        setVisibleItems(items.slice(0, visibleCount))
    }, [items, visibleCount])

    const handleViewMore = () => {
        setVisibleCount(prev => prev + 9)
    }

    if (!items.length) return null

    return (
        <>
            <Grid container xs={12} direction='column' data-testid='widget-list'>
                <Grid container spacing={3}>
                    {visibleItems.map((item, i) => (
                        <Item
                            title={item.title}
                            onRedirect={() => onRedirect && onRedirect(item.slug)}
                            icon={item.icon}
                            key={`${i}-feature`}
                            data-testid={`widget-item-${i}`}
                        />
                    ))}
                </Grid>
            </Grid>
            {visibleItems.length < items.length && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                    <Button variant="outlined" onClick={handleViewMore}>
                        View more
                    </Button>
                </div>
            )}
        </>
    )
}

export default ListItems
