import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ContentArea from '../CommonUI/ContentArea'
import TopBar from './TopBarV1/TopBar'
import SidebarV1 from './SidebarV1'
import { NavigationContext } from '../NavigationContext'
import { ScreenSizeContext } from 'providers'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        minHeight: 0
    },
    sidebarNavUI: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        overflow: 'auto'
    },
    sidebarOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0, 0.5)',
        zIndex: 1300,
        '& > div': {
            maxWidth: '250px',
            height: '100%'
        }
    },
    contentArea: {
        flexGrow: 1,
        overflow: 'auto'
    }
}))

/**
 * UI component used when navConfig.ui == "NavUIV1".
 * This type of UI renders a sidebar to the left and the content area to the right.
 */
const NavUIV1 = (): JSX.Element => {
    const classes = useStyles()

    const { getNavItems } = useContext(NavigationContext)

    // Only used on mobile: (where the sidebar is either fully open or closed)
    const [sideBarOpen, setSideBarOpen] = useState<boolean>(false)

    const topLevelNavItems = getNavItems()

    const screenSize = useContext(ScreenSizeContext)
    const isSmallScreen = screenSize.mobile || screenSize.tablet

    return (
        <div data-testid="nav-ui-v1" className={classes.root}>
            <TopBar
                isSmallScreen={isSmallScreen}
                sideBarOpen={sideBarOpen}
                setSideBarOpen={setSideBarOpen}
            />
            <div className={classes.sidebarNavUI}>
                {!isSmallScreen && (
                    <SidebarV1
                        topLevelNavItems={topLevelNavItems}
                        sideBarOpen={sideBarOpen}
                        setSideBarOpen={setSideBarOpen}
                    />
                )}

                <div className={classes.contentArea}>
                    <ContentArea />
                </div>
            </div>

            {(isSmallScreen && sideBarOpen) &&
                <div className={classes.sidebarOverlay} onClick={() => setSideBarOpen(false)}>
                    <SidebarV1
                        topLevelNavItems={topLevelNavItems}
                        sideBarOpen={sideBarOpen}
                        setSideBarOpen={setSideBarOpen}
                        showHeader={true}
                    />
                </div>
            }
        </div>
    )
}

export default NavUIV1
