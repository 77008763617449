import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LoaderFull from 'loader-full'
import { formatPhoneNumber } from 'phone-numbers'
import { DefaultArrowTooltip } from 'tooltips'
import SelectionControl from 'selection-control'
import ConfirmModal from 'confirm-modal'
import Menu from 'pdc-menu'
import api from '../../util/api_v5'

import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import TextField from 'pdc-text-field'

import CallIcon from '@material-ui/icons/PhoneCallback'
// import PhoneIcon from '@material-ui/icons/PhoneAndroid'
// import ComputerIcon from '@material-ui/icons/Computer'
import CloseIcon from '@material-ui/icons/Close'
import Button from 'button'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import SettingsWrapper from '../SettingsWrapper'

/**
 * @param {...any} args
 */
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const GTM_APP_NAME = 'personal-settings;incoming-calls'
const GTM_MAP = { TOGGLE_ON: 1, TOGGLE_OFF: 0, INVALID_NUMBER: 0, SAVE_NUMBER: 1 }

class IncomingCalls extends Component {
  state = {
      loading: true,
      forwardNumberSaving: false,
      computerDeviceState: false,
      phoneDeviceState: false,
      callScreening: false,
      newForwardNumber: '',
      cellForward: {},
      screenCallsModal: null
  }

  componentDidMount () {
      this.getForwardNumbers()
  }

  getForwardNumbers = async () => {
      const extension = await api.getExtension()
      try {
          const route = extension.route
          if (!route) throw Error()
          const rules = route.rules
          if (!rules || rules.length === 0) throw Error()
          const actions = rules[0].actions
          if (!actions || actions.length === 0) throw Error()
          if (actions[0].action !== 'forward') throw Error()
          const forwardItems = actions[0].items
          if (!forwardItems || forwardItems.length === 0) throw Error()
          let phoneNumberForwardAction = null
          forwardItems.forEach(item => {
              if (phoneNumberForwardAction) return
              if (item.type !== 'phone_number') return
              phoneNumberForwardAction = item
          })
          if (!phoneNumberForwardAction) throw Error()
          const number = phoneNumberForwardAction.number
          const screening = phoneNumberForwardAction.screening
          this.setState({ cellForward: { number, enabled: true, screening } })
          this.setState({ loading: false })
      } catch (e) {
          this.setState({ loading: false })
      }
  }

  renderPhoneNumbersSection = () => {
      const { classes } = this.props
      const cellForward = this.state.cellForward
      const phoneNumber = cellForward ? formatPhoneNumber(cellForward.number) : null
      const onRemove = this.removeForwardNumber // previously had confirmation pop up
      const onChange = () => {
          cellForward.enabled = !cellForward.enabled
          this.setState({ cellForward })
          gtmDataPush({
              PDC_Action: GTM_APP_NAME,
              PDC_Label: 'forward-number-toggle-change',
              PDC_Value: cellForward ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
          })
      }
      const tooltipText = 'You can only forward to 1 external number. Either remove the number first, or if you want to add another user like your business partner, add a user instead.'
      return (
          <div className={classes.section}>
              <div className={classes.question}>Should we ring a cell number on new calls?</div>
              {phoneNumber ? this.renderItem(CallIcon, phoneNumber, undefined, onChange, onRemove) : null}
              <DefaultArrowTooltip
                  title = {phoneNumber ? tooltipText : ''}
                  placement = 'right'
              >
                  <div className={classes.addButtonWrapper}>
                      <Button
                          variant = 'filled'
                          icon = 'add'
                          disabled = {Boolean(phoneNumber)}
                          onClick = {() => {
                              gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'add-number-button-click' })
                              this.setState({ isAddForwardNumberModalShown: true })
                          }
                          }
                          className = {classes.addButton}
                      >Add number</Button>
                  </div>
              </DefaultArrowTooltip>
              {this.renderRemoveForwardNumberModal()}
              {this.renderAddForwardNumberModal()}
          </div>
      )
  }

  // todo remove this once it is 100% confirmed this is no longer wanted, currently this should never show as per Oksana's request
  renderRemoveForwardNumberModal = () => {
      const isShown = Boolean(this.state.isRemoveForwardNumberModalShown)
      return (
          <ConfirmModal
              isShown = {isShown}
              title = {'Are you sure you want to stop remove this number?'}
              noButtonText = 'Cancel'
              yesButtonText = 'Remove'
              yesButtonColor = 'danger'
              onReject = {() => this.setState({ isRemoveForwardNumberModalShown: false })}
              onConfirm = {this.removeForwardNumber}
              size = 'size2' // size440
          />
      )
  }

  removeForwardNumber = async () => {
      const removeNumber = this.state.cellForward.number
      this.setState({ cellForward: {}, isRemoveForwardNumberModalShown: false, loading: true })
      await api.removeNumberForwarding(removeNumber)
      this.setState({ loading: false })
      gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'remove-forward-number' })
  }

  renderAddForwardNumberModal = () => {
      const isShown = Boolean(this.state.isAddForwardNumberModalShown)

      const onReject = () => {
          this.setState({ isAddForwardNumberModalShown: false })
          gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'forward-number-cancel' })
      }
      const onConfirm = () => this.saveForwardNumber()

      return (
          <ConfirmModal
              isShown = {isShown}
              title = 'Enter a phone number'
              content = {
                  <TextField
                      fullWidth = {true}
                      error = {Boolean(this.state.newForwardNumberError)}
                      content = {this.state.newForwardNumber}
                      onInputChange = {newForwardNumber => { this.setState({ newForwardNumber, newForwardNumberError: false }) }}
                      type = 'phone-number-us'
                  />
              }
              noButtonText = 'Cancel'
              yesButtonText = 'Confirm'
              onReject = {onReject}
              onConfirm = {onConfirm}
              size = 'size2' // size440
          />
      )
  }

  saveForwardNumber = async (screening = true) => {
      let newForwardNumber = this.state.newForwardNumber
      const numberSplit = (newForwardNumber.match(/\d|\+/g) || [])
      newForwardNumber = numberSplit.join('')
      if (newForwardNumber[0] !== '+') {
          if (newForwardNumber[0] !== '1') newForwardNumber = `1${newForwardNumber}`
          newForwardNumber = `+${newForwardNumber}`
      }
      if (!isValidNumber(newForwardNumber)) {
          gtmDataPush({
              PDC_Action: GTM_APP_NAME,
              PDC_Label: 'forward-number-confirm',
              PDC_Value: GTM_MAP.INVALID_NUMBER
          })
          return this.setState({ newForwardNumberError: true })
      }
      this.setState({ isAddForwardNumberModalShown: false, newForwardNumberError: false, loading: true })
      await api.addExtensionForward(newForwardNumber, screening)
      this.setState({ cellForward: { number: newForwardNumber, enabled: true, screening }, loading: false })
      gtmDataPush({
          PDC_Action: GTM_APP_NAME,
          PDC_Label: 'forward-number-confirm',
          PDC_Value: GTM_MAP.SAVE_NUMBER
      })
  }

  renderItem = (Icon, text, toggleValue, onChange, onRemove) => {
      const { classes } = this.props
      return (
          <div key={text} className={classes.optionItem}>
              <div className='left-part'><Icon/> <span>{text}</span></div>
              <div className='right-part'>
                  {/* TODO: deprecate, use PdcSwitch */}
                  {toggleValue !== undefined
                      ? <SelectionControl
                          variant = 'switch'
                          checked = {toggleValue}
                          onClick = {onChange}
                          name = 'checked-toggle'
                          value = 'checked-toggle'
                      />
                      : null}
                  <DefaultArrowTooltip
                      title = 'Remove'
                      placement = 'right'
                  >
                      <div className={classes.removeButton} onClick={onRemove}><CloseIcon/></div>
                  </DefaultArrowTooltip>
              </div>
          </div>
      )
  }

  renderDevicesSection = () => {
      //   const { classes } = this.props
      return null // todo finish this section, was put on hold
      //   return (
      //       <div className={classes.section}>
      //           <div className={classes.question}>Which devices should we ring when you get a call?</div>
      //           {this.renderItem(ComputerIcon, 'Chrome, Mac - Web App', undefined, () => {}, () => {})}
      //           {this.renderItem(PhoneIcon, 'iPhone 11 - Mobile App', undefined, () => {}, () => {})}
      //       </div>
      //   )
  }

  onCallScreeningChange = screenCallsMenuSelection => {
      if (this.state.cellForward.screening === (screenCallsMenuSelection.value === 'yes')) return
      this.setState({ screenCallsModal: screenCallsMenuSelection })
  }

  toggleCallScreening = () => {
      const screenCallsModal = this.state.screenCallsModal
      const screenCalls = screenCallsModal.value === 'yes'
      this.setState({ newForwardNumber: this.state.cellForward.number }, async () => {
          const cellForward = this.state.cellForward
          cellForward.screening = screenCalls
          this.setState({ screenCallsModal: null, loading: true })
          await this.saveForwardNumber(screenCallsModal.value === 'yes')
          this.setState({ loading: false })
      })
  }

  renderCallScreeningConfirmModal = () => {
      const screenCallsModal = this.state.screenCallsModal
      const isShown = Boolean(screenCallsModal)
      const title = `Are you sure you want to turn call screening ${screenCallsModal ? 'on' : 'off'}?`
      return (
          <ConfirmModal
              isShown = {isShown}
              title = {title}
              noButtonText = 'Cancel'
              yesButtonText = 'Yes'
              onReject = {() => this.setState({ screenCallsModal: null })}
              onConfirm = {this.toggleCallScreening}
              size = 'size2' // size440
          />
      )
  }

  renderCallScreeningSection = () => {
      const { classes } = this.props
      if (!this.state.cellForward.number) return null
      const items = [
          { value: 'yes', content: 'Yes' },
          { value: 'no', content: 'No' }
      ]
      return (
          <div className={classes.section}>
              <div className={classes.question}>Would you like Phone.com to screen calls?</div>
              <Menu
                  label = ''
                  variant = 'single'
                  items = {items}
                  default = {this.state.cellForward.screening ? 'yes' : 'no'}
                  controlable = {true}
                  onChange = {this.onCallScreeningChange}
                  className = {{ wrapper: classes.menuOptionSelect }}
              />
              {this.renderCallScreeningConfirmModal()}
          </div>
      )
  }

  render () {
      const { classes } = this.props
      return (
          <SettingsWrapper>
              {this.state.loading ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> : null}
              {this.renderPhoneNumbersSection()}
              {this.renderDevicesSection()}
              {/* this.renderCallScreeningSection() */}
          </SettingsWrapper>
      )
  }
}

IncomingCalls.propTypes = {
    classes: PropTypes.object,
    smallView: PropTypes.bool
}

export default withStyles(styles)(IncomingCalls)
