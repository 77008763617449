import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import { ArrowForwardIosIcon } from 'svg-icons'
import NavLink from 'nav-link'
import { NavItem } from '../../Navigation'
import { NavigationContext } from '../../NavigationContext'
import NavItemBadge from '../../CommonUI/NavItemBadge'

const useStyles = makeStyles(theme => ({
    subMenu: {
        width: '300px',
        minWidth: '300px', // necessary despite width set above
        overflow: 'auto',
        borderRight: `1px solid ${theme.palette.grey[300]}`
    },
    navItemTitleContainer: {
        backgroundColor: theme.palette.grey[100],
        padding: '15px',
        borderWidth: '0 0 1px 0',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid'
    },
    h8Variant: {
        fontFamily: 'Inter, Montserrat, sans-serif !important',
        fontSize: '18px !important',
        color: theme.palette.grey[900],
        fontStyle: 'normal !important',
        fontWeight: '400 !important',
        lineHeight: '24px !important',
        letterSpacing: '-0.2px !important'
    },
    subNavItems: {
        display: 'flex',
        flexDirection: 'column'
    },
    subNavItemRoot: {
    },
    subNavItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 10px 10px 15px',
        borderWidth: '0 0 1px 0',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        '& .badge': {
            marginLeft: 'auto'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .02)'
        },
        '&.active': {
            backgroundColor: 'rgba(215, 235, 211, 0.80)',
            borderLeftWidth: '5px',
            borderLeftColor: '#3FAD2A',
            '& $forwardArrow': {
                color: '#3FAD2A'
            },
            '& $body2Variant': {
                color: '#30991C'
            }
        }
    },
    body2Variant: {
        fontFamily: 'Inter, Montserrat, sans-serif !important',
        fontSize: '13px !important',
        color: theme.palette.grey[700],
        fontStyle: 'normal !important',
        fontWeight: '400 !important',
        lineHeight: '21px !important',
        letterSpacing: '-0.1px !important'
    },
    forwardArrow: {
        fontSize: '18px',
        color: theme.palette.grey[400]
    }
}))

/**
 * Displays a menu of nav items that are children of the currently selected parent nav item.
 */
const SidebarV2SubMenu = () => {
    const classes = useStyles()

    const { getActiveNavItems } = useContext(NavigationContext)

    const activeNavItems = getActiveNavItems()

    const activeTopLevelNavItem: NavItem | null = activeNavItems.length ? activeNavItems[0] : null

    // Return null if no top-level nav item is selected or if it has no child nav items to be displayed
    if (!activeTopLevelNavItem || !activeTopLevelNavItem?.children?.length) return null

    return (
        <div className={classes.subMenu}>
            <div className={classes.navItemTitleContainer}>
                <Typography
                    className={classes.h8Variant}
                    data-testid={activeTopLevelNavItem.title}
                >
                    {activeTopLevelNavItem.title}
                </Typography>
            </div>
            <div className={classes.subNavItems}>
                {(activeTopLevelNavItem.children || []).map(subNavItem => (
                    <div className={classes.subNavItemRoot} key={subNavItem.id} data-testid='subNavItem'>
                        <NavLink navItemId={subNavItem.id!} className={classes.subNavItem}>
                            {subNavItem.title &&
                                <Typography className={classes.body2Variant}>
                                    {subNavItem.title}
                                </Typography>
                            }
                            <NavItemBadge navItem={subNavItem} />
                            <ArrowForwardIosIcon className={classes.forwardArrow} />
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SidebarV2SubMenu
