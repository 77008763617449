import React, { useMemo, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Select, Alert, TextField } from 'foundations-library/components'
import api from 'api'
import Spinner from 'spinner'
import { users as propUsers } from './../../../utils/objects'
import { useEsimOperationsContext } from '../../contexts/EsimOperationsContext'
import { formatPhoneNumber } from 'phone-numbers'
import { makePromise, getStatus } from './../../../utils/index'
import { details } from '../../../utils/objects'
import { required, email } from 'validation-rules'
import { EsimContext } from 'providers'

const useStyles = makeStyles(theme => {
    return {
        inputContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        nameContainer: {
            display: 'flex',
            columnGap: '10px'
        },
        phoneNumberContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        phoneNumberInputContainer: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center'
        }
    }
})

/**
 *
 */
const UserForm = () => {
    const classes = useStyles()
    const [isLoadingExtensions, setIsLoadingExtensions] = useState(false)
    const {
        data,
        selectedUser,
        setSelectedUser,
        selectedPhone,
        setSelectedPhone,
        selectedUserData,
        setSelectedUserData,
        phones,
        setPhones,
        availableUsers,
        currentUser
    } = useEsimOperationsContext()
    const { plans, reset } = useContext(EsimContext)

    const [users, setUsers] = useState<Record<string, any>[]>(propUsers)
    const [hasMoreUsers, setHasMoreUsers] = useState(false) // kept false for now as we are not loading the users

    const isNxtUser = currentUser.user_tiered as boolean
    const [statusText] = getStatus(data)
    const isActivated = statusText === 'Installed'

    let lastUserObserver: IntersectionObserver

    const loadUsers = async () => {
        const limit = 20
        const offset = users.length
        const newUsers = await api.loadUsers(limit, offset)
        setUsers([...users, ...newUsers])
        setHasMoreUsers(newUsers.length === limit)
    }

    const userDropdownOpened = () => {
        if (hasMoreUsers) {
            lastUserObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
                const lastUser = entries[0]
                if (!lastUser.isIntersecting) return
                loadUsers()
            }, {
                root: document.querySelector('.select-menu'),
                rootMargin: '150px'
            })
            makePromise<string>('.select-menu > div:last-child').then(selector => {
                const lastUser = document.querySelector(selector as string)
                if (lastUser) lastUserObserver.observe(lastUser)
            })
        }
    }

    const loadPhoneNumbers = async (extension_id) => {
        setIsLoadingExtensions(true)
        const { esim: phoneNumbers } = await api.getUserPhoneNumbersForEsim(extension_id)
        setPhones(phoneNumbers)

        if (phoneNumbers?.length) {
            setSelectedPhone(phoneNumbers[0]?.number)
        }

        setIsLoadingExtensions(false)
    }

    const getUser = (user) => {
        if (!isNxtUser && data.user && ((data.user.extension?.id || data.user.voip_phone_id) === user?.extension?.id)) {
            return data.user
        }
        return user
    }

    const handleSelectUser = async (user: Record<string, any>) => {
        user = getUser(user)
        setSelectedUser(user)
        setSelectedPhone('')
        // handleOpenCompabilityDialog()
        setSelectedUserData({
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email
        })

        if (data.user) reset('UPDATE_ESIM')

        await loadPhoneNumbers(user.extension?.id || user.voip_phone_id)
        if (lastUserObserver) lastUserObserver.disconnect()
    }

    const renderSelectOption = (ext: Record<string, any>) => (
        <div>
            <Typography variant='body2' className='ext-name'>{ext.name}</Typography>
            <Typography variant='body2' className='ext-number'>Ext {ext.number}</Typography>
        </div>
    )

    const handleSelectPhone = (phone: string) => setSelectedPhone(phone)

    const handleSelectedUserDataChange = (prop) => (v) => setSelectedUserData({ ...(selectedUserData || {}), [prop]: v })

    const selectUserOptions = useMemo(() => {
        return availableUsers.map(
            user => {
                let name = user.extension.name
                if (!isNxtUser && data?.user && ((data.user.extension?.id || data.user.voip_phone_id) === user?.extension?.id)) {
                    name = `${selectedUserData?.first_name} ${selectedUserData?.last_name}`
                }
                const ext = {
                    id: user.extension.id,
                    name,
                    number: user.extension.number
                }

                return ({
                    ...user,
                    value: ext.id,
                    text: `Name: ${name} Ext: ${ext.number}`,
                    renderComponent: renderSelectOption(ext)
                })
            }
        )
    }, [availableUsers, selectedUserData, data])

    const disabledNonExtensionFields = useMemo(() => !selectedUser, [selectedUser])

    return (
        <div className={classes.inputContainer}>
            <Select
                title='Select user'
                options={selectUserOptions}
                returnObject={true}
                className='user-dropdown'
                onOpen={userDropdownOpened}
                onChange={handleSelectUser}
                value={selectedUser?.extension?.id || selectedUser?.voip_phone_id}
                fullWidth
                disabled={data?.user}
            />
            <div className={classes.nameContainer}>
                <TextField
                    value={selectedUserData?.first_name}
                    onChange={handleSelectedUserDataChange('first_name')}
                    placeholder='First name'
                    disabled={disabledNonExtensionFields || isNxtUser || isActivated}
                    rules={[required()]}
                    fullWidth
                />
                <TextField
                    value={selectedUserData?.last_name}
                    onChange={handleSelectedUserDataChange('last_name')}
                    placeholder='Last name'
                    disabled={disabledNonExtensionFields || isNxtUser || isActivated}
                    rules={[required()]}
                    fullWidth
                />
            </div>
            <div className={classes.phoneNumberContainer}>
                <div className={classes.phoneNumberInputContainer}>
                    <Select
                        disabled={
                            disabledNonExtensionFields ||
                            (selectedUser && !(phones.filter((extension) => extension.assigned !== null).length)) ||
                            isLoadingExtensions ||
                            isActivated
                        }
                        value={selectedPhone}
                        onChange={handleSelectPhone}
                        options={phones.filter((extension: Record<string, any>) => extension.assigned !== null).map((extension: Record<string, any>) => ({ value: extension.number, text: formatPhoneNumber(extension.number) }))}
                        title={details.selectPhone}
                        fullWidth
                    />
                    {isLoadingExtensions && <Spinner color='#8C8C8C' />}
                </div>
                {
                    selectedUser &&
                    !isLoadingExtensions &&
                    !phones.length &&
                    <Alert level='error' icon={true}>No Phone Numbers are available for selected extension</Alert>
                }
            </div>
            <TextField
                type='email'
                value={selectedUserData?.email}
                onChange={handleSelectedUserDataChange('email')}
                disabled={disabledNonExtensionFields || isNxtUser || isActivated}
                rules={[required(), email()]}
                fullWidth
                placeholder='Email address'
            />
            <Select disabled={true} value={data?.billing_code} options={plans.plans.map((plan: Record<string, any>) => ({ value: plan.billing_code, text: plan.name }))} title='Select a plan' />
        </div>
    )
}

// Assign a display name to the component
UserForm.displayName = 'UserForm'

export default UserForm
