import { FeatureEventsContext } from 'providers'
import React, { useContext } from 'react'
import { DoNotDisturbAllIcon } from 'svg-icons'
import RemoteConfigValue from 'remote-config-value'
import { PdcCallContext } from 'pdc-calls'
import { DefaultArrowTooltip } from 'tooltips'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    doNotDisturbWrapper: {
        cursor: 'pointer',
        '& svg': {
            color: theme.palette.secondary.light,
            fontSize: 40
        },
        '&.on svg': {
            color: theme.palette.info.dark
        }
    }
}))

/**
 * Toggle switch that activates/deactivates "do not disturb" mode,
 * using the PDC Call context.
 */
const DoNotDisturbSwitch = (): JSX.Element => {
    const classes = useStyles()

    const featureEventsContext = useContext(FeatureEventsContext)
    const callContext: any = useContext(PdcCallContext)

    const onSwitchClicked = () => {
        const newState = !callContext.doNotDisturb
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-do-not-disturb-click', value: newState })
        callContext.toggleDoNotDisturb(newState)
    }

    return (
        <DefaultArrowTooltip
            title={
                <RemoteConfigValue
                    valueId={callContext.doNotDisturb ? 'top_bar_do_not_disturb_enabled_tooltip_text' : 'top_bar_do_not_disturb_disabled_tooltip_text'}
                />
            }
        >
            <div
                data-testid="do-not-disturb-switch"
                className={`${classes.doNotDisturbWrapper} ${callContext.doNotDisturb ? 'on' : 'off'}`}
                onClick={onSwitchClicked}
            >
                <DoNotDisturbAllIcon/>
            </div>
        </DefaultArrowTooltip>
    )
}

export default DoNotDisturbSwitch
