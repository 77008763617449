/* eslint-disable jsdoc/require-description */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'
import { makeStyles } from '@material-ui/core/styles'
import { VideoPopup } from 'popups'
import { JsonEditor } from 'json-edit-react'
import { useHistory } from 'react-router-dom'
import { Button } from 'foundations-library/components'

const useStyles = makeStyles(theme => ({
    root: {
        '& a': {
            color: theme.palette.primary['+100']

        },
        '& td': {
            padding: '5px'
        },
        '& ul': {
            paddingLeft: '20px'
        },
        '& details': {
            borderBottom: '1px solid #e0e0e0',
            padding: '10px 0',
            overflow: 'hidden',
            transition: 'max-height 1.0s ease-in-out'
        },
        '& details > *:not(summary)': {
            maxHeight: 20,
            overflow: 'hidden',
            opacity: 0,
            transition: '1.0s ease-in-out, opacity 1.0s ease-in-out'
        },
        '& details[open] > *:not(summary)': {
            maxHeight: 200, /* Adjust as needed */
            opacity: 1
        },
        '& summary': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            cursor: 'pointer',
            listStyle: 'none', // Remove default marker
            fontSize: '16px',
            fontWeight: 'bold',
            padding: '12px 0'
        },
        '& summary::-webkit-details-marker': {
            display: 'none' // Hides the default dropdown marker
        },
        '& summary::after': {
            content: '"\\25BC"', // ▼ (down arrow)
            fontSize: '15px',
            color: '#444',
            transition: 'transform 0.3s ease-in-out'

        },
        '& details[open] summary::after': {
            transform: 'rotate(180deg)' // Rotates arrow when opened
        },
        '& video': {
            maxWidth: '100%'
        },
        '& img': {
            maxWidth: '100%'
        }
    },
    markdownstyleEditContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '.75rem',
        margin: '.5rem 0 1rem 0',
        '& button': {
            alignSelf: 'flex-start'
        }
    },
    markdownEditContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '.75rem',
        margin: '.5rem 0 1rem 0',
        '& textarea': {
            width: '100%',
            backgroundColor: 'rgb(246, 246, 246)',
            resize: 'both',
            fontSize: '16px',
            borderRadius: '.5em',
            padding: '1em'
        },
        '& button': {
            alignSelf: 'flex-start'
        }
    }
}))

// Extend the sanitization schema
const sanitizeOptions = {
    ...defaultSchema,
    tagNames: [...defaultSchema.tagNames, 'video', 'source', 'tab'],
    attributes: {
        ...defaultSchema.attributes,
        video: ['controls', 'width', 'height', 'poster', 'posterwidth', 'posterheight'],
        source: ['src', 'type'],
        tab: ['*'], // example to pass all attributes
        a: ['href', 'target']
    }
}

interface MarkdownInterface {
    children: string | JSX.Element
    styleOverrides?: Record<string, any>
}

/**
 *
 */
export default function PDCMarkdown (props: MarkdownInterface) {
    const classes = useStyles()
    const [text, setText] = useState('')
    const [styles, setStyles] = useState(props.styleOverrides || {})

    const overrideClass = makeStyles(theme => ({
        root: {
            ...styles
        }
    }))()

    useEffect(() => {
        setText(`${props.children}`)
    }, [props.children])

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(JSON.stringify(data))
        alert('Copied data as stingified to clipboard')
        console.log('Clipboard data:', data)
    }

    const renderMarkdownStyleMode = () => {
        return (
            window?.PDCMarkdownEditMode &&
            <div className={classes.markdownstyleEditContainer}>
                <JsonEditor data={styles} onUpdate={({ newData }) => setStyles(newData)} />
                <Button variant='contained' onClick={copyToClipboard.bind(this, styles)}>Copy Styles</Button>
            </div>
        )
    }

    const renderMarkdownEditMode = () => {
        return (
            window?.PDCMarkdownEditMode &&
            <div className={classes.markdownEditContainer}>
                <textarea
                    rows={12}
                    cols={100}
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
                <Button variant='contained' onClick={copyToClipboard.bind(this, text)}>Copy Content</Button>
            </div>
        )
    }

    return (
        <>
            {renderMarkdownStyleMode()}
            {renderMarkdownEditMode()}
            <Markdown
                className={`${classes.root} ${overrideClass.root} markdown-content`}
                components={{ video: CustomVideo, a: CustomAnchor }}
                rehypePlugins={[rehypeRaw, [rehypeSanitize, sanitizeOptions]]}
            >
                {text}
            </Markdown>
        </>
    )
}

const CustomVideo = ({ node, poster, ...props }) => {
    const [videoOpen, setVideoOpen] = useState(false)
    // Filter out children that are <source> elements with a src prop.
    const sources = React.Children.toArray(props.children).filter(
        (child) => child && child.props && child.props.src
    )
    const videSources = sources.map((source, index) => (
        {
            src: source.props.src,
            type: source.props.type || 'video/mp4'
        }
    ))

    const handleVideoClick = () => {
        setVideoOpen(!videoOpen)
    }
    const posterWidth = props.posterwidth || 359
    const posterHeight = props.posterheight
    return (
        <>
            <img src={poster} width={posterWidth} height={posterHeight} onClick={handleVideoClick} />
            <VideoPopup
                onClose={handleVideoClick}
                open={videoOpen}
                sources={videSources}
                autoPlay={true}
                {...props}
            />
        </>

    )
}

const CustomAnchor = ({ node, href, ...props }) => {
    const history = useHistory()
    const onClick = (event) => {
        event.preventDefault()
        if (!href) return null

        // #id path
        if (href.startsWith('#')) {
            const id = href.split('#')[1]
            const el = document.getElementById(id)
            if (el) el.scrollIntoView({ top: 0, behavior: 'smooth' })
        } else if (href.startsWith('/')) { // local path
            const extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
            const redirectPath = `/e${extensionId}${href}`
            if (redirectPath) history.replace(redirectPath)
        } else if (href.startsWith('http')) { // external link
            window.open(href, '_blank')
        }
    }
    return <a {...props} onClick={onClick} href={href} data-testid={href}/>
}
