import React, { Component } from 'react'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import LoaderFull from 'loader-full'
import AudioRecorder from 'audio-recorder'
import AudioUploader from 'audio-uploader'
import { Button } from 'button-mui'
import Api from 'api'

import TextFieldsIcon from '@material-ui/icons/TextFields'
import PublishIcon from '@material-ui/icons/Publish'
import MicIcon from '@material-ui/icons/Mic'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import PropTypes from 'prop-types'

const GTM_APP_NAME = 'personal-settings;voicemail'
const GTM_MAP = { SAVE_ENABLED: 1, SAVE_NOT_ENABLED: 0 }

const voices = ['Joanna', 'Salli', 'Joey', 'Matthew']
const languages = ['English']

class VoicemailEditor extends Component {
    state = {
        selectedTab: 'tts',
        ttsText: '',
        ttsVoice: voices[0],
        language: languages[0],
        uploadedAudio: null,
        readyToPlay: false,
        loading: true,
        recording: { isRecording: false },
        removeBtnHover: false
    }

    componentDidMount = async () => {
        this.init()
    }

    componentDidUpdate = () => {
        const hasChange = this.checkHasChange()
        if (this.hasChange !== hasChange) {
            this.hasChange = hasChange
            this.props.setBusy(hasChange)
        }
    }

    init = async () => {
        this.setState({
            selectedTab: 'tts',
            ttsText: '',
            ttsVoice: voices[0],
            language: languages[0],
            uploadedAudio: null,
            readyToPlay: false,
            loading: true,
            recording: { isRecording: false },
            removeBtnHover: false
        })

        const textArea = document.getElementById('voicemail-textarea')
        if (textArea) textArea.focus()
        let voicemailConfig = this.props.voicemail
        if (!voicemailConfig) voicemailConfig = await Api.getVoicemailConfig()
        const voipRecording = voicemailConfig.voip_recording
        if (!voipRecording) return this.setState({ loading: false })
        const voipRecordingId = voipRecording.voip_recording_id
        let selectedTab = 'tts'
        let voicemailLink = voicemailConfig.download_link
        if (!voicemailLink) voicemailLink = (await Api.getMusicOnHoldLink(voipRecordingId)).download_link
        const updatedVoicemail = {
            download_link: voicemailLink,
            voip_recording_id: voipRecordingId,
            type: 'file',
            filename: voipRecording.name,
            voip_recording: voipRecording
        }
        if (voipRecording.tts_text) {
            updatedVoicemail.type = 'tts'
            this.setState({ ttsText: voipRecording.tts_text, ttsVoice: voipRecording.tts_voice })
        } else if (this.isRecordedAudio(voipRecording)) {
            selectedTab = 'record'
            const recording = this.state.recording
            recording.recordedAudio = JSON.parse(JSON.stringify(updatedVoicemail))
            this.setState({ recording })
        } else {
            selectedTab = 'upload'
            this.setState({ uploadedAudio: JSON.parse(JSON.stringify(updatedVoicemail)) })
        }
        this.props.updateVoicemail(updatedVoicemail)
        this.setState({ selectedTab, loading: false })
    }

    isRecordedAudio = voipRecording => {
        const name = voipRecording.name || voipRecording.filename
        if (!name) return false
        const nameSplit = name.split(' ')
        if (name.length !== 23 || nameSplit.length !== 2) return false
        if (nameSplit[0] !== 'recording') return false
        const timestamp = nameSplit[1]
        for (let c = 0; c < timestamp.length; c++) {
            if (isNaN(parseInt(timestamp[c]))) return false
        }
        return true
    }

    onVoicemailMessageChange = e => this.setState({ ttsText: e.target.value })

    checkHasChange = () => {
        let hasChange = false
        const ttsText = this.state.ttsText
        const language = this.state.language
        const ttsVoice = this.state.ttsVoice
        const voicemail = this.props.voicemail
        const selectedTab = this.state.selectedTab
        const uploadedAudio = this.state.uploadedAudio
        const recording = this.state.recording
        if (selectedTab === 'tts') {
            hasChange = Boolean(language && ttsText && ttsVoice && (
                !voicemail ||
                voicemail.type !== 'tts' ||
                ttsText !== voicemail.voip_recording.tts_text ||
                ttsVoice !== voicemail.voip_recording.tts_voice
            ))
        }
        if (selectedTab === 'upload') {
            hasChange = Boolean(uploadedAudio && (
                !voicemail ||
                voicemail.type !== 'file' ||
                voicemail.voip_recording_id !== uploadedAudio.voip_recording_id ||
                voicemail.download_link !== uploadedAudio.download_link
            ))
        }
        if (selectedTab === 'record') {
            hasChange = Boolean(recording.recordedAudio && recording.start && recording.end)
        }
        return hasChange
    }

    onSave = async () => {
        const hasChange = this.checkHasChange()
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'save',
            PDC_Value: hasChange ? GTM_MAP.SAVE_ENABLED : GTM_MAP.SAVE_NOT_ENABLED
        })
        this.setState({ loading: true })
        let voip_recording_id = null
        let newVoicemail = null
        if (this.state.selectedTab === 'tts') {
            const { ttsText, ttsVoice, name } = this.state
            const response = await Api.createTTSGreeting({ text: ttsText, voice: ttsVoice, name })
            voip_recording_id = response.voip_recording_id
        } else if (this.state.selectedTab === 'upload') {
            const uploadedAudio = this.state.uploadedAudio
            const response = await Api.createFileGreeting({ name: uploadedAudio.filename, file: uploadedAudio.base64Data })
            delete uploadedAudio.base64Data
            uploadedAudio.voip_recording_id = response.voip_recording_id
            this.setState({ uploadedAudio })
            voip_recording_id = uploadedAudio.voip_recording_id
            newVoicemail = {
                download_link: uploadedAudio.download_link,
                type: 'file',
                voip_recording: { voip_recording_id, filename: uploadedAudio.filename },
                voip_recording_id
            }
        } else {
            const recordedAudio = this.state.recording.recordedAudio
            const downloadLink = recordedAudio.downloadLink
            const filename = recordedAudio.filename
            console.log(recordedAudio)
            const base64Data = downloadLink.split(';')[1].split(',')[1]
            const response = await Api.createFileGreeting({ name: filename, file: base64Data })
            voip_recording_id = response.voip_recording_id
            this.removeRecordedAudio()
            newVoicemail = {
                downloadLink,
                type: 'file',
                voip_recording: { voip_recording_id, filename },
                voip_recording_id
            }
        }
        this.props.updateVoicemail(newVoicemail)
        await Api.configureVoicemail(voip_recording_id)
        this.init()
    }

    renderTtsContent = () => {
        const { classes } = this.props
        return (
            <div className={classes.ttsContent}>
                <textarea
                    id = 'voicemail-textarea'
                    value = {this.state.ttsText}
                    onChange = {this.onVoicemailMessageChange}
                />
                <hr className={classes.separator}/>
                <div className={classes.languageOptions}>
                    <div>{this.state.language}</div>
                    <div>{this.state.ttsVoice}</div>
                </div>
                {this.props.voicemail && this.props.voicemail.type === 'tts' ? this.renderAudioPlayer(this.props.voicemail) : null}
                <div className={classes.infoSection}>
                    <InfoOutlinedIcon classes={{ root: classes.infoIcon }}/>At this time we only support English TTS voices
                </div>
            </div>
        )
    }

    renderAudioPlayer = voicemail => {
        const { classes } = this.props
        return <div className={classes.audioPlayerContainer}>
            <div style={{ display: this.state.readyToPlay ? 'block' : 'none' }}>
                <AudioPlayer
                    key = {voicemail.voip_recording_id}
                    url = {voicemail.download_link}
                    onPlay = {() => { /**/ }}
                    onReadyToPlay = {() => this.setState({ readyToPlay: true })}
                />
            </div>
            {!this.state.readyToPlay ? <LoadingBar/> : null}
        </div>
    }

    removeRecordedAudio = () => this.setState({ recording: { isRecording: false } })

    renderRecordAMessage = () => {
        const { classes } = this.props
        return (
            <div className={classes.recordContainer}>
                <AudioRecorder
                    onRecorded = {recording => this.setState({ recording })}
                    recording = {this.state.recording}
                    onDelete = {this.removeRecordedAudio}
                    showIconOnly={true}
                />
            </div>
        )
    }

    renderUploadAudioFile = () => {
        return (
            <AudioUploader
                id = ''
                onUploaded = {uploadedAudio => this.setState({ uploadedAudio })}
                uploadedAudio = {this.state.uploadedAudio}
                onDelete = {() => this.setState({ uploadedAudio: null })}
                maxSize = {1024 * 1024 * 4} // 4MB
            />
        )
    }

    render () {
        const { classes } = this.props
        return (
            <>
                <div>
                    {this.state.loading
                        ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div>
                        : <div className={`${classes.container} ${this.props.smallView ? 'small-view' : ''}`}>
                            <div className={`${classes.voicemailHeader}`}>
                                <div
                                    className = {`tab ${this.state.selectedTab === 'tts' ? 'selected' : ''}`}
                                    onClick = {() => this.setState({ selectedTab: 'tts' })}
                                >
                                    {this.props.smallView && this.state.selectedTab !== 'tts' ? <TextFieldsIcon/> : 'Text To Speech'}
                                </div>
                                <div
                                    className = {`tab ${this.state.selectedTab === 'upload' ? 'selected' : ''}`}
                                    onClick = {() => this.setState({ selectedTab: 'upload' })}
                                >
                                    {this.props.smallView && this.state.selectedTab !== 'upload' ? <PublishIcon/> : 'Custom File'}
                                </div>
                                <div
                                    className = {`tab ${this.state.selectedTab === 'record' ? 'selected' : ''}`}
                                    onClick = {() => this.setState({ selectedTab: 'record' })}
                                >
                                    {this.props.smallView && this.state.selectedTab !== 'record' ? <MicIcon/> : 'Record audio'}
                                </div>
                            </div>
                            {this.state.selectedTab === 'tts'
                                ? this.renderTtsContent()
                                : this.state.selectedTab === 'upload'
                                    ? this.renderUploadAudioFile()
                                    : this.state.selectedTab === 'record'
                                        ? this.renderRecordAMessage()
                                        : null}
                            <Button size='large' variant='contained'
                                disabled={!this.checkHasChange()}
                                onClick={this.onSave}
                                className={classes.saveButton}
                                color='primary'>Save</Button>
                        </div>
                    }
                </div>
            </>
        )
    }
}

VoicemailEditor.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.any,
    /**
     * Voicemail info
     */
    voicemail: PropTypes.object,
    /**
     * Update the voicemail
     */
    updateVoicemail: PropTypes.func,
    /**
     * Call it with `true` in case there is an unsaved change
     * or with `false` in case of no changes. It will prevent going away from the app
     * in case the user clicks somewhere.
     */
    setBusy: PropTypes.func,
    /**
     * Is it small / mobile view
     */
    smallView: PropTypes.bool,
    /**
     * Show over everything
     */
    showAlone: PropTypes.bool
}

export default withStyles(styles)(VoicemailEditor)
